import axios from 'axios'

export default {
  namespaced: true,
  state: {
    sections: []
	},
	mutations: {
    setSections(state, value) {
			state.sections = value
		},
	},
	actions: {
    getFooterChapterList({commit}) {
      axios({
        method: 'get',
        url: `/api/profile/footerChapterList`
      })
        .then(res => {
          commit('setSections', res.data.data)
        })
    }
	}
}

