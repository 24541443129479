import axios from 'axios'
import store from '../../../store'

export default {
    namespaced: true,
    state: {
        currentOrder: {}
    },
    mutations: {
        setCurrentOrder(state, order) {
            state.currentOrder = order
        }
    },
    actions: {
        getOrder({commit}, {id}) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: `/api/profile/order/${id}`
                })
                    .then(res => {
                        resolve(res)
                        commit('setCurrentOrder', res.data.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        cancelOrder({}, orderId) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: `/api/profile/cancel/${orderId}`,
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(error => {
                        store.dispatch('popup/openAlert', error.response.data.message)
                        reject(error)
                    })
            })
            // return axios({
            //     method: 'post',
            //     url: `/api/profile/cancel/${orderId}`,
            // })
        },
    }
}
