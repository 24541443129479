import axios from 'axios'

export default {
	namespaced: true,
	state: {
		allowSms: false,
		allowEmail: false,
		mailingCategories: []
	},
	mutations: {
		setAllowSms(state, value) {
			state.allowSms = value
		},
		setAllowEmail(state, value) {
			state.allowEmail = value
		},
		setMailingCategories(state, value) {
			state.mailingCategories = value
		}
	},
	actions: {
		updateNotifications({state, commit}, {allowSms, allowEmail, mailingCategories}) {
			return new Promise((resolve, reject) => {
				const checkedCategories = mailingCategories
					.filter(category => category.value)

				const cats = checkedCategories.map(item => item.name)

				axios({
					method: 'post',
					url: `/api/profile/updateContact`,
					data: {
						allowEmail: allowEmail,
						allowSms: allowSms,
						mailingCategories: cats
					}
				})
					.then( res => {
						resolve(res)

						commit('setAllowSms', res.data.data.allowSms)
						commit('setAllowEmail', res.data.data.allowEmail)
						commit('setMailingCategories', res.data.data.mailingCategories)
					})
					.catch( error => {
						reject(error)
					})
			})
		}
	}

}
