<template>
    <div class="loyalty-special">
        <Heading
            class="heading_loyalty"
            :title="$translation.loyalty.special.title"
        />
        <div class="loyalty-special__items loyalty-swiper relative">
            <swiper :slidesPerView="'auto'" :spaceBetween="20">
                <template v-if="specialOffers.length">
                    <swiper-slide v-for="offer in specialOffers" :key="offer.id">
                        <SpecialCard :offer="offer" @open-popup="onOpenPopup" />
                    </swiper-slide>
                </template>
                
                <template v-else>
                    <swiper-slide v-for="offer in partners.slice(0,3)" :key="offer.id">
                        <SpecialCard 
                            :offer="offer" 
                            @open-popup="onOpenPopup"
                        />
                    </swiper-slide>
                </template>

                <swiper-slide v-if="isSpacialOffersUploaded">
                    <SpecialCard last />
                </swiper-slide>
            </swiper>
        </div>
    </div>

    <Popup 
        v-if="popupName === 'loyaltyPartners'"
        :items="specialOffersPopups"
        :initial-item="initialItem"
        :is-partner="true"
    />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import SpecialCard from "@web/components/loyalty/special/components/SpecialCard";
import Heading from "@web/components/heading/Heading";
import Popup from '@web/components/loyalty/popup/Popup.vue'
import popupMixin from '@com/mixins/popup'

import "swiper/swiper-bundle.css";

export default {
    mixins: [popupMixin],
    components: {
        Swiper,
        SwiperSlide,
        SpecialCard,
        Heading,
        Popup,
    },
    data() {
        return {
            isStockUploaded: false,
            isRaffleUploaded: false,
            isPartnersUploaded: false,
            initialItem: {},
        };
    },
    computed: {
        ...mapState({
            stock: (state) => state.loyaltyStock.stock,
            raffles: (state) => state.loyaltyRaffles.raffles,
            partners: (state) => state.loyaltyPartners.partners,
        }),
        specialOffers() {
            return [...this.stock, ...this.raffles, ...this.partners]
                .filter((item) => item.is_special_offer)
                .filter((item) => new Date(item.deadline) > new Date())
                .filter((item) => !item.deleted_at)
                .sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
                .slice(0, 3);
        },
        specialOffersPopups() {
            return this.specialOffers.filter(offer => offer.popup_btn_text)
        },
        isSpacialOffersUploaded() {
            if (
                this.isStockUploaded &&
                this.isRaffleUploaded &&
                this.isPartnersUploaded
            ) {
                return true;
            }
        },
    },
    methods: {
        ...mapMutations({
            setStock: "loyaltyStock/setStock",
            setRaffles: "loyaltyRaffles/setRaffles",
            setPartners: "loyaltyPartners/setPartners",
        }),
        ...mapActions({
            getStock: "loyaltyStock/getStock",
            getRaffles: "loyaltyRaffles/getRaffles",
            getPartners: "loyaltyPartners/getPartners",
        }),

        onOpenPopup(item) {
            console.log('ITEM:', item);

            this.initialItem = item

            this.openPopup({
                name: 'loyaltyPartners',
                contentClasses: ['popup__content_loyalty'],
                popupClasses: ['popup_grey']
            })
        },
    },
    created() {
        this.getStock({
            page: 1,
            sortRow: "is_active",
            sort: "any",
        }).then((res) => {
            this.setStock(res.data.data.events);
            this.isStockUploaded = true;
        });
        this.getRaffles({
            page: 1,
            sortRow: "is_active",
            sort: "any",
        }).then((res) => {
            this.setRaffles(res.data.data.events);
            this.isRaffleUploaded = true;
        });
        this.getPartners({
            page: 1,
        }).then((res) => {
            this.setPartners(res.data.data.partners);
            this.isPartnersUploaded = true;
        });
    },
};
</script>
