<template>
  <div class="loyalty">
    <Preloader
      v-if="isPending"
      :text="$translation.preloader.loading"
      class="preloader_large"
    />
    <router-view 
      v-else
    />
  </div>
</template>

<script>
import Preloader from '@com/components/preloader/Preloader'
import { mapActions } from 'vuex'

export default {
  components: {
    Preloader
  },
  data() {
    return {
      isPending: true
    }
  },
  methods: {
    ...mapActions({
      getEntities: 'loyalty/getEntities',
      getPromocodes: 'promocodes/getPromocodes',
    }),
  },
  created() {
    this.getPromocodes()
    this.getEntities()
      .finally(() => {
        this.isPending = false
      })
  }
}
</script>
