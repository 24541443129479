<template>
  <div class="loyalty-popup__text-widget">
    <figure v-if="data.img" class="loyalty-popup__text-figure">
      <img :src="data.img" alt="">
      <figcaption v-if="data.texts">
        {{ data.texts }}
      </figcaption>
    </figure>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: false
    }
  }
}
</script>
