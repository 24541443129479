<template>
  <header
    :class="{
      active: isMenuOpen
    }" 
    class="header"
    v-click-outside="closeMenu"
  >
    <div 
      class="header__inner"
    >
      <router-link
        @click="closeMenu"
        :class="{
          active: isMenuOpen
        }" 
        to="/"
        class="header__logo"
        :alt="$translation.header.mainLinkAlt"
      >
        <DynamoLogo />
      </router-link>
      <Burger
        @click="toggleMenu"
        :class="{
          active: isMenuOpen
        }" 
      />
      <div
        :class="{
          active: isMenuOpen
        }"
        class="header__overlay"
      >
        <Nav
          @change-page="closeMenu" 
        />
        <Menu />
      </div>
      <div 
        :class="{
          active: isMenuOpen
        }"
        class="header__lang"
      >
        <a :href="langUrl" class="header__lang-btn">
          {{ langBtn }}
        </a>
      </div>
      <!-- <a href="#" class="header__vtb-logo">
        <VTBLogo />
      </a> -->
    </div>
  </header>
</template>

<script>
import DynamoLogo from '@web/components/header/components/DynamoLogo'
import Burger from '@web/components/header/components/Burger'
// import VTBLogo from '@com/components/header/components/VTBLogo'
import Nav from '@web/components/header/components/Nav'
import Menu from '@web/components/header/components/Menu'

export default {
  components: {
    DynamoLogo,
    Burger,
    Nav,
    Menu,
    // VTBLogo
  },
  data() {
    return {
      isMenuOpen: false
    }
  },
  computed: {
    langBtn() {
      return this.$locale === 'en' ? 'Русский' : 'english'
    },
    langUrl() {
      return this.$locale === 'en' ? '/' : '/en'
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      this.isMenuOpen = false
    }
  }

}
</script>
