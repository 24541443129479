<template>
  <Wrapper>
    <div class="loyalty__common">
      <!-- <Card 
        class="loyalty__common-item"
      /> -->
      <!-- <Resources  
        v-if="common.level_number >= 2"
        class="loyalty__common-item"
      /> -->
      <Promocodes v-if="promocodes.length"
        class="loyalty__common-item loyalty__common-item--wide" 
        :promocodes="promocodes"
      />
      <Special
        class="loyalty__common-item loyalty__common-item--wide" />
      <FAQ 
        class="loyalty__common-item"
        :user-data="common"
        :questions="questions"
      /> 
    </div>
  </Wrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Card from './components/card/Card.vue'
import FAQ from '@web/components/faq/FAQ.vue'
import Resources from './components/Resources.vue'
import Wrapper from '@web/components/loyalty/Wrapper.vue'
import Promocodes from '@web/components/loyalty/promocodes/Promocodes.vue'
import Special from '@web/components/loyalty/special/Special.vue'

export default {
  components: {
    Card,
    Resources,
    Wrapper,
    Promocodes,
    Special,
    FAQ,
  },
  data() {
    return {
      proxyQuestions: {},
    }
  },
  computed: {
    ...mapState({
      common: state => state.loyaltyCommon.common,
      questions: state => state.faq.questions,
      promocodes: state => state.promocodes.promocodes,
    }),
  },
  methods: {
      ...mapActions({
          getQuestions: 'faq/getQuestions',
      }),
  },
  mounted() {
        this.getQuestions()
            .finally(() => {
            this.isPending = false
        })
    } 
}
</script>
