<template>
  <section class="settings__section">
    <h2 class="h2 settings__h2 settings__h2_flex">
      {{ $translation.profile.contact.h2 }}
      <transition name="fade">
        <span 
          v-show="showMessage"
          class="settings__save-message"
          :class="{
            error: status === 'error',
            success: status === 'success'
          }"
        >
          {{ message }}
        </span>
      </transition>
    </h2>
    <div class="settings__row">
      <div class="settings__column">
        <Phone
          @saved="onSaved" 
        />
      </div>
      <div class="settings__column">
        <Email
          @saved="onSaved"  
        />
      </div>
    </div>
  </section>
</template>

<script>
import Phone from '@web/components/profile/contact/components/phone/Phone'
import Email from '@web/components/profile/contact/components/email/Email'

export default {
  components: {
    Phone,
    Email
  },
  data() {
    return {
      status: '',
      message: '',
      showMessage: false
    }
  },
  methods: {
    onSaved(status, message) {
      this.status = status
      this.message = message
      this.showMessage = true
      setTimeout(() => this.showMessage = false, 3000)
    }
  }
}
</script>
