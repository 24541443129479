<template>
    <Preloader
        v-if="isPending"
        :text="$translation.preloader.loading"
        class="preloader_large"
    />
    <div 
        v-else
        class="faq page"
    > 
        <div class="container">
            <h1 class="h1">
                {{ $translation.faq.h1 }}
            </h1>
            <FAQ
                :user-data="common" 
                :questions="questions" />
        </div>
    </div>
</template>
  
<script>
import { mapState, mapActions } from 'vuex';

import FAQ from '../components/faq/FAQ.vue';
import Preloader from '@com/components/preloader/Preloader';

export default {
    components: {
        FAQ,
        Preloader,
    },
    data() {
        return {
            isPending: true
        }
    },
    computed: {
        ...mapState({
            questions: state => state.faq.questions,
        }),
    },
    methods: {
        ...mapActions({
            getQuestions: 'faq/getQuestions',
        }),
    },
    mounted() {
        this.getQuestions()
            .finally(() => {
            this.isPending = false
        })
    } 
}
</script>
