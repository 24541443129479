<template>
  <form
    class="relative"
    @submit.prevent="onSubmit"
    @reset.prevent="onReset"
  >
    <FormItem
      :isError="(!phoneVerified && !isChanged) || (!isValid && validation)"
      :label="label"
    >
      <template v-slot:header>
        <span
          v-if="!phoneVerified && !isChanged"
          class="form-item__message form-item__message_error form-item__message_clickable form-item__message_desk"
          @click="onSendSms"
        >
          {{ $translation.profile.phone.repeatSend }}
        </span>
      </template>
      <PhoneInput
        v-model.trim="phone"
        :disabled="saveInProgress || $store.state.email.isVip"
        :countryCode="countryCode"
        @change-country="onChangeCountry"
        @change-mask="onChangeMask"
      />
      <template v-slot:footer>
        <div
          v-if="!phoneVerified && !isChanged"
          class="form-item__message form-item__message_error form-item__message_clickable form-item__message_mobile"
          @click="onSendSms"
        >
            {{ $translation.profile.phone.repeatSend }}
        </div>
      </template>
    </FormItem> 
    <transition name="fade">
      <div
        v-show="isChanged"
        class="settings__btns settings__btns_narrow"
      >
        <button
          type="submit"
          class="btn btn_blue"
          :disabled="(!isValid && validation) || saveInProgress"
        >
          {{ $translation.btn.save }}
        </button>
        <button
          type="reset"
          class="btn btn_white"
          :disabled="saveInProgress"
        >
          {{ $translation.btn.cancel }}
        </button>
        <div class="settings__btns-text">
          <p>
            {{ $translation.profile.phone.text }}
          </p>
        </div>
      </div>
    </transition>
    <Popup
      v-if="popupName === 'phoneСode'"
      :confirm-failed="confirmСodeFailed"
      :in-progress="confirmСodeInProgress"
      :error-message="confirmCodeErrorMessage"
      :timer="sendAgainTimer"
      :phone="phone"
      @close-popup="closePopup"
      @send-code="onSendCode"
      @send-sms="onSendSms"
    />
    <Preloader
      v-show="saveInProgress"
      :text="$translation.profile.phone.preloaderText"
      class="preloader_form"
    />
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Popup from '@web/components/profile/contact/components/phone/components/Popup'
import PhoneInput from '@com/components/forms/PhoneInput'
import Preloader from '@com/components/preloader/Preloader'
import FormItem from '@com/components/forms/FormItem'
import { checkNumbersEqual, checkStringFilled, checkPhone } from '@com/functions/validation.js'
import popupMixin from '@com/mixins/popup'

export default {
  mixins: [popupMixin],
  components: {
    Popup,
    PhoneInput,
    Preloader,
    FormItem
  },
  emits: ['saved'],
  data() {
    return {
      phone: '',
      countryCode: '',
      mask: '',
      isPopupOpen: false,
      saveInProgress: false,
      validation: false,
      confirmСodeFailed: false,
      confirmСodeInProgress: false,
      confirmCodeErrorMessage: '',
      sendAgainTimer: 120
    }
  },
  computed: {
    ...mapState({
      phoneStore: state => state.phone.phone,
      countryCodeStore: state => state.phone.countryCode,
      phoneVerified: state => state.phone.phoneVerified
    }),
    isChanged() {
      return this.phone.replace(/[\(\)\s]/g,'') !== this.phoneStore.replace(/[\(\)\s]/g,'')
    },
    label() {
      if (!this.isValid && this.validation) {
        return this.$translation.profile.phone.error
      } else if (!this.phoneVerified && !this.isChanged) {
        return this.$translation.profile.phone.unconfirmed
      } else {
        return this.$translation.profile.phone.label
      }
    },
    isValid() {
      if (checkStringFilled(this.mask)) {
        return checkNumbersEqual(this.phone.replace(/[^\d]/g, '').length, this.mask.replace(/[^\\.]/g, '').length)
      } else {
        return checkPhone(this.phone)
      }
    }
  },
  methods: {
    ...mapActions({
      changeMobilePhone: 'phone/changeMobilePhone',
      sendSmsForMobilePhoneVerification: 'phone/sendSmsForMobilePhoneVerification',
      verifyMobilePhone: 'phone/verifyMobilePhone',
    }),
    onSendCode(code) {
      if (!this.confirmСodeInProgress) {
        this.confirmСodeInProgress = true
        this.verifyMobilePhone({
          code: code
        })
          .then(res=> {
              this.confirmСodeFailed = false
              this.closePopup()
              this.$emit('saved', 'success', res.data.message || this.$translation.message.changeSuccess)
          })
          .catch( error => {
            this.confirmCodeErrorMessage = error.response.data.message || this.$translation.message.unknownError
            this.confirmСodeFailed = true
          })
          .finally(()=> {
            this.confirmСodeInProgress = false
          })
      }
    },
    onSendSms() {
      if ((!this.phoneVerified) && (!this.isChanged)) {
        this.sendSmsForMobilePhoneVerification()
          .then( res => {
            if (!this.isPopupOpen) {
              this.openPopup({
                name: 'phoneСode',
                contentClasses: ['popup__content_phone']
              })
              this.confirmСodeFailed = false
            }
            this.runTimer()
          })
          .catch( error => { 
            this.$emit('saved', 'error', error.response.data.message || this.translation.message.unknownError)
          })
      }
    },
    onChangeMask(mask) {
      this.mask = mask
    },
    onChangeCountry(code) {
      this.countryCode = code
      this.phone = ''
    },
    onReset() {
      this.validation = false
      this.reset()
    },
    reset() {
      this.countryCode = this.countryCodeStore
      this.phone = this.phoneStore
    },
    onSubmit() {
      if ((!this.saveInProgress) && this.isChanged) {
        if (this.isValid && (typeof this.phone === 'string')) {
          this.saveInProgress = true

          this.changeMobilePhone({
            phone: this.phone
          })
            .then(res => {
              this.confirmСodeFailed = false
              this.openPopup({
                name: 'phoneСode',
                contentClasses: ['popup__content_phone']
              })
              this.runTimer()
            })
            .catch( error => {
              this.$emit('saved', 'error', error.response.data.message || this.translation.message.unknownError)
            })
            .finally(()=> {
              this.saveInProgress = false
            })

        } else {
          this.validation = true
        }
      }
    },
    runTimer() {
      this.sendAgainTimer = 120
      const timerInterval = setInterval(() => {
        if (this.sendAgainTimer > 0) {
          this.sendAgainTimer--
        } else {
          clearInterval(timerInterval)
        }
      }, 1000);
    }
  },
  mounted() {
    this.reset()
  }
}
</script>
