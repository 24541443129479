<template>
  <div class="input-wrap">
    <input 
      type="email" 
      class="input"
      v-bind="$attrs"
      :value="modelValue" 
      @input="$emit('update:modelValue', $event.target.value)"
      id="emailInput"
    >
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
}
</script>
