<template>
  <div class="poster">
    <Preloader 
      v-if="isPending"
      :text="$translation.preloader.loading"
      class="preloader_absolute preloader_middle"
    />
    <template v-else>
      <picture class="poster__img">
        <template v-if="banner.image">
          <source  media="(min-width: 1280px)" :srcset="banner.image.desktop">
          <source media="(min-width: 720px)" :srcset="banner.image.tablet">
          <img :src="banner.image.mobile" alt="">
        </template>
        <img v-else src="@web/assets/img/banner/default.png" alt="">
      </picture>
      <a 
        v-if="banner.url && banner.nameUrl" 
        :href="banner.url" 
        target="_blank" 
        class="btn btn_white poster__btn"
      >
        {{ banner.nameUrl }}
      </a>
    </template>
  </div> 
</template>

<script>
import { mapActions } from 'vuex'
import Preloader from '@com/components/preloader/Preloader'

export default {
  components: {
    Preloader
  },
  props: {
    bannerName: { 
      type: String,
      required: true
    }
  },
  data() {
    return {
      isPending: true,
      banner: {}
    }
  },
  methods: {
    ...mapActions({
      getBanner: 'banner/getBanner',
    })
  },
  created() {
    this.getBanner({
      position: this.bannerName
    })
      .then(res => {
        this.banner = res.data.data
      })
      .finally(() => {
        this.isPending = false
      })
  }
}
</script>

