import axios from 'axios'

export default {
	namespaced: true,
  state: {
    stock: [],
    pageCount: 1
  },
  mutations: {
		setStock(state, value) {
      state.stock = value
    },
    addStock(state, value) {
      state.stock = state.stock.concat(value)
    },
    setPageCount(state, value) {
      state.pageCount = value
    }
	},
  actions: {
    getStock({commit}, {page, sortRow, sort}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/loyalty/events/stock?page=${page}&sortRow=${sortRow}&sort=${sort}`
        })
          .then(res => {
            commit('setPageCount', res.data.data.pageCount)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  }
}
