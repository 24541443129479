<template>
  <div 
    v-if="tickets.length"
    class="active-tickets"
  >
    <div class="container">
      <Heading
        class="heading_tickets"
        :title="$translation.tickets.ticketsDomDynamo.title"
        :amount="orderList"
      />
      <Event
        v-for="ticket in tickets" 
        :key="ticket.id"
        :event="ticket"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Heading from '@web/components/heading/Heading'
import Event from '@web/components/tickets/ticketsDomDynamo/components/Event'
import orderList from '@web/mixins/orderList'

export default {
  mixins: [orderList],
  components: {
    Heading,
    Event,
  },
  computed: {
    ...mapGetters({
      tickets: 'tickets/ticketsDomDynamo'
    }),
    events() { // используется для миксина orderList
      return JSON.parse(JSON.stringify(this.tickets))
    }
  }
}
</script>
