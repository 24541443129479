import axios from 'axios'

export default {
	namespaced: true,
	state: {
        questions: [],

    },
	mutations: {
		setQuestions(state, value) {
            state.questions = value
        },
        setUserData(state, value) {
            state.userData = value
        }
	},
    actions: {
        getQuestions({commit}) {
            return new Promise((resolve, reject) => {
                axios({
                method: 'get',
                url: '/api/loyalty/faq',
                })
                .then(res => {
                    resolve(res)
                    commit('setQuestions', res.data.data)
                    // TODO BACK на бэке в объекте прокидывать данные пользователя
                    // commit('setUserData', res.data.data.user)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
    }
}
