import store from "../store";

import { createRouter, createWebHistory } from "vue-router";

import Tickets from "../pages/Tickets.vue";
import Orders from "../pages/Orders.vue";
import Loyalty from "../pages/loyalty/Loyalty.vue";
import LoyaltyCommon from "../pages/loyalty/includes/LoyaltyCommon.vue";
import LoyaltyStock from "../pages/loyalty/includes/LoyaltyStock.vue";
import LoyaltyRaffle from "../pages/loyalty/includes/LoyaltyRaffle.vue";
import LoyaltyPartners from "../pages/loyalty/includes/LoyaltyPartners.vue";
import Profile from "../pages/Profile.vue";
import Login from "../pages/Login.vue";
import Web from "../pages/Web.vue";
import ConfirmEmail from "../pages/ConfirmEmail.vue";
import StaticPage from "../pages/StaticPage.vue";
import FAQ from "../pages/FAQ.vue";

import { YANDEX_METRICA_ID } from "@web/constants/yandexMetric.js";

const ifAuthenticated = (to, from, next) => {
    next(); // пропускаем дальше. До тех пор пока не будет установлен статус авторизации, вместо контента будет прелоадер

    // срабатывает, если пользователь подтвердил свой код на странице /oauth/redirect/ и тем самым авторизовался,
    // после чего его перекинуло на защищённую часть (чтобы повторно не делать проверку)
    if (store.state.token.status === "success") {
        return;
    }

    const token = localStorage.getItem("dynamo_elk_token");

    // если токена в локальном хранилище нет
    if (!token) {
        store.dispatch("token/webAuth", "")
            .then((res) => {
                store.commit("token/setToken", res.data.data.token)
                window.location.href = "/loyalty"
            })
            .catch((error) => {
                store.dispatch("token/authorizeRedirect");
            })
        return;
    }

    // если токен в локальном хранилище есть
    store
        .dispatch("token/getUser", { token: token }) // выполняется проверка подлинности токена
        .then(() => {
            // если токен прошёл проверку
            store.dispatch("token/webLogin", { token: token }); // токен записывается в store и устанавливается статус авторизации из loading в success, чтобы чтобы выключить прелоадер и разрешить отправку запросов
        })
        .catch(() => {
            // если токен не прошёл проверку
            store.commit("token/setToken", ""); // удаляет токен из локального хранилища
            store.dispatch("token/authorizeRedirect"); // редирект на форму авторизации
        });
};

const routes = [
    {
        path: "/oauth/redirect/",
        name: "login",
        component: Login,
    },
    {
        path: "/profile/confirmEmailAddress",
        name: "confirmEmail",
        component: ConfirmEmail,
    },
    {
        path: "/",
        name: "web",
        component: Web,
        beforeEnter: ifAuthenticated,
        children: [
            {
                path: "/",
                name: "home",
                redirect: "/loyalty",
            },
            {
                path: "/faq",
                name: "FAQ",
                component: FAQ,
            },
            {
                path: "/tickets",
                name: "tickets",
                component: Tickets,
            },
            {
                path: "/orders",
                name: "orders",
                component: Orders,
                alias: ["/orders/pay/success", "/orders/pay/error"],
            },
            {
                path: "/loyalty",
                name: "loyalty",
                component: Loyalty,
                children: [
                    {
                        path: "",
                        name: "loyaltyCommon",
                        component: LoyaltyCommon,
                    },
                    {
                        path: "stock",
                        name: "loyaltyStock",
                        component: LoyaltyStock,
                    },
                    {
                        path: "raffle",
                        name: "loyaltyRaffle",
                        component: LoyaltyRaffle,
                    },
                    {
                        path: "partners",
                        name: "loyaltyPartners",
                        component: LoyaltyPartners,
                    },
                ],
            },
            {
                path: "/profile",
                name: "profile",
                component: Profile,
            },
            {
                path: "/:pathMatch(.*)*",
                name: "staticPage",
                component: StaticPage,
            },
        ],
    },
];

const base = document.body.dataset.locale === "en" ? "/en/" : "";

const router = createRouter({
    history: createWebHistory(base),
    routes,
});

router.beforeEach((to, from, next) => {
    if (typeof ym !== "undefined") {
        ym(YANDEX_METRICA_ID, "hit", to.path, {
            referer: from.path,
        });
    }

    if (typeof gtag !== "undefined") {
        gtag("set", "page_path", to.path);
        gtag("event", "page_view");
    }

    next();
});

router.afterEach(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
});

export default router;
