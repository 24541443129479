<template>
    <div class="active-tickets__column active-tickets__column_large">
        <div class="ticket ticket_ticket">
            <div class="ticket__header ticket__header_ticket">
                <span class="ticket__header-circle">
                    <CircleLeft fill="#ffffff" />
                </span>
                <span class="ticket__header-circle">
                    <CircleRight fill="#ffffff" />
                </span>
                {{ $translation.tickets.tickets.title }}
            </div>
            <div class="ticket__body ticket__body_ticket">
                <div class="ticket__table ticket__table_mb">
                    <div class="ticket__table-row">
                        <div class="ticket__table-column ticket__table-column--sector">
                            {{ $translation.tickets.sector }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--row">
                            {{ $translation.tickets.row }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--place">
                            {{ $translation.tickets.place }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--actions"></div>
                    </div>
                    <div
                        v-for="ticket in tickets"
                        :key="ticket.id"
                        class="ticket__table-row"
                    >
                        <div class="ticket__table-column ticket__table-column--sector">
                            {{ ticket.value.sector }}
                            <div
                                v-if="ticket.value.restaurant"
                                class="ticket__table-restaurant"
                            >
                                {{ ticket.value.restaurant }}
                            </div>
                        </div>
                        <div class="ticket__table-column ticket__table-column--row">
                            {{ ticket.value.row }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--place">
                            {{ ticket.value.place }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--actions">
                            <div class="ticket__table-actions">
                                <span
                                    v-if="ticket.isChild"
                                    class="ticket__table-children ticket__table-children_ticket"
                                >
                                    {{ $translation.tickets.children }}
                                </span>
                                <a
                                    v-if="ticket.downloadUrl"
                                    :href="ticket.downloadUrl"
                                    target="_blank"
                                    class="ticket__table-download"
                                >
                                    <span
                                        class="ticket__table-download-arrow"
                                    ></span>
                                    <span
                                        class="ticket__table-download-down"
                                    ></span>
                                </a>
                                <a
                                    v-if="ticket.walletUrl"
                                    :href="ticket.walletUrl"
                                    class="ticket__table-folder"
                                >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="fanIdRequired">
                    <a
                        href="https://lk.gosuslugi.ru/profile/fan-id"
                        target="_blank"
                        class="btn btn_white ticket__footer-gosuslugi"
                    >
                        госуслуги
                    </a>
                    <p class="ticket__footer-need-card ticket__footer-need-card_ticket">
                        Необходима Карта болельщика
                    </p>
                </div>
                <a
                    v-else
                    :href="downloadUrl"
                    target="_blank"
                    class="btn btn_white ticket__footer-download ticket__footer-download_ticket"
                >
                    скачать
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import CircleLeft from "@web/components/tickets/circle/CircleLeft.vue";
import CircleRight from "@web/components/tickets/circle/CircleRight.vue";

export default {
    components: {
        CircleLeft,
        CircleRight,
    },
    props: {
        tickets: {
            type: Array,
            required: true,
        },
        fanIdRequired: {
            type: Boolean,
            required: true,
        }
    },
    computed: {
        downloadUrl() {
            return this.tickets.downloadUrl ? this.tickets.downloadUrl : '#'
        }
    }
};
</script>
