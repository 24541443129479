<template>
    <div class="loyalty__header" data-loyalty-header>
        <img
            src="@web/assets/img/loyalty/background.svg"
            class="loyalty__header-img"
        />
        <div class="container">
            <div class="loyalty__inner">
                <div v-if="isNavigationEnabled" class="loyalty__btns-navigation" >
                    <div 
                      class="loyalty__btns-navigation-btn prev" 
                      :class="{
                        'disabled': mainMenuScrollPosition.trim() === 'left'
                      }"
                      @click="scrollToLeft"
                    ></div>
                    <div 
                      class="loyalty__btns-navigation-btn next" 
                      :class="{
                        'disabled': mainMenuScrollPosition.trim() === 'right'
                      }"
                      @click="scrollToRight"
                    ></div>
                </div>
                <ul class="loyalty__btns hide-scrollbar" ref="mainMenu">
                    <li
                        v-for="item in availableMenu"
                        :key="item.name"
                        class="loyalty__btns-item"
                    >
                        <!-- common = logo -->
                        <router-link
                            v-if="item.name === 'common'"
                            :to="item.link"
                            class="loyalty__btn loyalty__btn-logo"
                        >
                            <div class="loyalty__header-logo">
                                <img
                                    src="@web/assets/img/loyalty/logo.png"
                                    alt=""
                                />
                            </div>
                        </router-link>
                        <!-- other -->
                        <router-link
                            v-else
                            :to="item.link"
                            class="loyalty__btn"
                        >
                            {{ item.text }}
                        </router-link>
                    </li>
                </ul>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            menu: [
                {
                    link: "/loyalty",
                    text: this.$translation.loyalty.nav.common,
                    name: "common",
                },
                {
                    link: "/loyalty/stock",
                    text: this.$translation.loyalty.nav.stock,
                    name: "stock",
                },
                {
                    link: "/loyalty/raffle",
                    text: this.$translation.loyalty.nav.raffle,
                    name: "raffle",
                },
                {
                    link: "/loyalty/partners",
                    text: this.$translation.loyalty.nav.partners,
                    name: "partners",
                },
            ],
            mainMenuScrollPosition: 'left',
            isNavigationEnabled: false,
        };
    },
    computed: {
        ...mapState({
            entities: (state) => state.loyalty.entities,
        }),
        availableMenu() {
            return this.menu.filter(
                (item) =>
                    (this.entities[item.name] && this.$locale === "ru") ||
                    item.name === "common"
            );
        },
    },
    methods: {
      scrollToRight() {
        const mainMenu = this.$refs.mainMenu;
        mainMenu.scrollTo({
          top: 0,
          left: mainMenu.scrollWidth - mainMenu.clientWidth,
          behavior: "smooth"
        });
      },
      scrollToLeft() {
        const mainMenu = this.$refs.mainMenu;
        mainMenu.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      },
    },
    mounted() {
      const mainMenu = this.$refs.mainMenu;

      if ( this.availableMenu.length > 3 ) {
        this.isNavigationEnabled = true
      }

      mainMenu.addEventListener('scroll', () => {  
        if (mainMenu.scrollLeft === 0) {
          this.mainMenuScrollPosition = 'left'
        }
        else if (mainMenu.scrollWidth - mainMenu.scrollLeft === mainMenu.clientWidth) {
          this.mainMenuScrollPosition = 'right'
        } else {
          this.mainMenuScrollPosition = 'middle'
        }
      })
    }
};
</script>
