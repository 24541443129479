<template>
  <div class="not-found">
    <div class="container">
      <div class="not-found__row">
        <div class="not-found__column">
          <div class="h1 not-found__title">404</div>
        </div>
        <div class="not-found__column">
          <h1 class="h1 not-found__title not-found__title_margin" v-html="$translation.notFound.h1">
          </h1>
          <div class="not-found__text">
            {{ $translation.notFound.text }}
          </div>
          <router-link to="/tickets" class="not-found__link">
            {{ $translation.notFound.link }}
            <span class="not-found__link-arrow"></span>
          </router-link>
        </div>
      </div>
    </div>
  </div>  
</template>
