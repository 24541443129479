<template> 
  <teleport to="#popup">
    <template v-if="popupName === name">
      <template v-if="isSlot">
        <slot></slot>
      </template>
      <div 
        v-else
        class="popup__title popup__title_small"
      >
        {{ alertText || `${$translation.message.unknownError}. ${$translation.message.tryUpdate}` }}
      </div>
    </template>
  </teleport>
</template>

<script>
import popupMixin from '@com/mixins/popup'
import { mapState } from 'vuex'

export default {
  mixins: [popupMixin],
  props: {
    name: {
      type: String,
      required: false
    },
    isSlot: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState({
      alertText: state => state.popup.alertText
    })
  },
}
</script>
