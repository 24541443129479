<template>
  <section class="settings__section">
    <h2 class="h2 settings__h2 settings__h2_flex">
      {{ $translation.profile.password.h2 }}
      <transition name="fade">
        <span 
          v-show="showMessage"
          class="settings__save-message"
          :class="{
            error: status === 'error',
            success: status === 'success'
          }"
        >
          {{ message }}
        </span>
      </transition>
    </h2>
    <form
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
      class="relative"
    >
      <div class="settings__row settings__row_no-mrg">
        <div class="settings__column">
          <FormItem
            :isError="!isPasswordValid && validation"
            :label="$translation.profile.password.label.password"
            :errorMessage="$translation.profile.password.error.password"
          >
            <PasswordInput
              v-model.trim="password"
              :disabled="saveInProgress || $store.state.email.isVip"
            />
          </FormItem>
        </div>
        <div class="settings__column">
          <FormItem
            :isError="!isRepeatedPasswordValid && validation"
            :label="$translation.profile.password.label.repeatedPassword"
            :errorMessage="$translation.profile.password.error.repeatedPassword"
          >
            <PasswordInput
              v-model.trim="repeatedPassword" 
              :disabled="saveInProgress || $store.state.email.isVip"
            />
          </FormItem> 
        </div>
      </div>
      <transition name="fade">
        <div 
          v-show="isChanged"
          class="settings__btns"
        >
          <button
            type="submit"
            class="btn btn_blue"
            :disabled="(!isValid && validation) || saveInProgress" 
          >
            {{ $translation.btn.save }}
          </button>
          <button
            type="reset"
            class="btn btn_white"
            :disabled="saveInProgress"
          >
            {{ $translation.btn.cancel }}
          </button>
        </div>
      </transition>
      <Preloader
        v-show="saveInProgress"
        :text="$translation.preloader.save"
        class="preloader_form"
      />
    </form>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import PasswordInput from '@com/components/forms/PasswordInput'
import Preloader from '@com/components/preloader/Preloader'
import FormItem from '@com/components/forms/FormItem'

import { checkStringFilled, checkStringsEqual } from '@com/functions/validation.js'

export default {
  components: {
    PasswordInput,
    Preloader,
    FormItem
  },
  data() {
    return {
      password: '',
      repeatedPassword: '',
      saveInProgress: false,
      validation: false,
      status: '',
      message: '',
      showMessage: false
    }
  },
  computed: {
    ...mapState({
      passwordStore: state => state.password.password,
    }),
    isChanged() {
      return this.password !== this.passwordStore ||
             this.repeatedPassword !== this.passwordStore
    },
    isPasswordValid() {
      return checkStringFilled(this.password)
    },
    isRepeatedPasswordValid() {
      return checkStringsEqual(this.password, this.repeatedPassword)
    },
    isValid() {
      return this.isPasswordValid && this.isRepeatedPasswordValid
    }
  },
  methods: {
    ...mapActions({
      updatePassword: 'password/updatePassword',
    }),
    reset() {
      this.password = this.passwordStore
      this.repeatedPassword = this.passwordStore
    },
    onReset() {
      this.validation = false
      this.reset()
    },
    onSubmit() {
      if ((!this.saveInProgress) && this.isChanged) {
        if (this.isValid) {
          this.saveInProgress = true

          this.updatePassword({
            password: this.password,
            repeatedPassword: this.password
          })
            .then( res => {
              this.reset()
              this.message = res.data.message || this.$translation.message.changeSuccess
              this.status = 'success'
            })
            .catch( error => {
              this.message = error.response.data.message || this.$translation.message.unknownError
              this.status = 'error'
            })
            .finally(()=> {
              this.saveInProgress = false
              this.validation = false
              this.showMessage = true
              setTimeout(() => this.showMessage = false, 3000)
            })

        } else {
          this.validation = true
        }
      }
    }
  },
  mounted() {
    this.reset()
  }
}
</script>
