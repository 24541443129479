import axios from 'axios'

export default {
  namespaced: true,
  state: {
    globalOffers: [],
  },
  getters: {
    tickets(state) {
      return state.globalOffers.filter(offer => offer.type === 'ticket')
    },
    seasonTickets(state) {
      return state.globalOffers.filter(offer => offer.type === 'season_ticket')
    },
    museum(state) {
      return state.globalOffers.filter(offer => offer.type === 'museum')
    },
    dynamoHouse(state) {
      return state.globalOffers.filter(offer => offer.type === 'gum')
    },
  },
  mutations: {
    setGlobalOffers(state, globalOffers) {
      state.globalOffers = globalOffers
    },
  },
  actions: {
    getGlobalOffers({commit}) {
        return axios({
          method: 'get',
          url: 'api/ticket/getGlobalOffersList',
        })
        .then(res => {
          commit('setGlobalOffers', res.data.data)
        })
        .catch(error => {
          reject(error)
        })
    },
  }
}
