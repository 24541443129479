import axios from 'axios'

export default {
	namespaced: true,
	state: {
		routes: []
	},
	mutations: {
		setRoutes(state, value) {
			state.routes = value
		}
	},
	actions: {
    getRoutes({commit}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/getStaticPage/routes`
        })
          .then(res => {
            resolve(res)

            commit('setRoutes', res.data.data)
          })   
          .catch(err => {
            reject(err)
          })
      }) 
    },
    getStaticPage({}, {staticPage}) {
      return axios({
        method: 'get',
        url: `/api/getStaticPage`,
        params: {
          uri: staticPage
        }
      })
    }
	}

}
