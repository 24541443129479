<template>
  <div class="loyalty-sort loyalty__sort">
    <Btn
      v-for="item in items"
      :key="item.value"
      :text="item.text"
      name="sort"
      :value="item.value"
      :model-value="sort"
      :disabled="disabled"
      @update:modelValue="value => $emit('changeSort', value)"
    />
  </div>
</template>

<script>
import Btn from '@web/components/loyalty/events/components/sort/components/Btn.vue'

export default {
  components: {
    Btn
  },
  props: {
    sort: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  emits: ['changeSort'],
  data() {
    return {
      items: [
        {
          text: this.$translation.btn.active,
          value: 'desc'
        },
        {
          text: this.$translation.btn.past,
          value: 'asc'
        }
      ]
    }
  },
}
</script>
