<template>
    <div
        class="ticket ticket_subscription active-subscriptions__item"
        :class="{
            'ticket_subscription--parking': isParking,
            'ticket_subscription--gold': subscription.value.restaurant === 'GOLD',
            'ticket_subscription--platinum': subscription.value.restaurant === 'PLATINUM',
            'ticket_subscription--gastrobar': subscription.value.restaurant === 'BetBoom GastroBar',
            'ticket_subscription--vip': subscription.value.seat.slice(0, 3) === 'VIP',
            'ticket_subscription--vvip': subscription.value.seat.slice(0, 4) === 'VVIP',
        }"
    >
        <div
            class="ticket__header ticket__header_subscription"
            :class="{ ticket__header_parking: isParking }"
        >
            <span class="ticket__header-circle">
                <CircleLeft :fill="isParking ? '#0959A1' : '#053566'" />
            </span>
            <span class="ticket__header-circle">
                <CircleRight :fill="circleRightFill" />
            </span>
            <picture class="ticket__header-img" v-if="!isParking">
                <img v-if="logo.url" :src="logo.url" :alt="logo.caption" />
                <img
                    v-else
                    src="@web/assets/img/tickets/ticket-white.png"
                    alt=""
                />
            </picture>
            <div class="ticket__header-text">
                {{ seasonName }}
            </div>
        </div>
        <div class="ticket__body">
            <div class="ticket__text">
                <p v-if="tournament">
                    {{ tournament }}
                </p>
                <p>Абонемент действует до {{ finishDateFormat }}</p>
            </div>
            <div class="ticket__table ticket__table_mb">
                <div class="ticket__table-row">
                    <template v-if="!isParking">
                        <div class="ticket__table-column ticket__table-column--sector">
                            {{ $translation.tickets.sector }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--row">
                            {{ $translation.tickets.row }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--place">
                            {{ $translation.tickets.place }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--actions"></div>
                    </template>
                    <template v-else>
                        <div class="ticket__table-column ticket__table-column--sector">
                            {{ $translation.tickets.parkingTicket.spots }}
                        </div>
                    </template>
                </div>
                <div class="ticket__table-row">
                    <template v-if="!isParking">
                        <div class="ticket__table-column ticket__table-column--sector">
                            {{ subscription.value.sector }}
                            <div
                                v-if="subscription.value.restaurant"
                                class="ticket__table-restaurant"
                            >
                                {{ subscription.value.restaurant }}
                            </div>
                        </div>
                        <div class="ticket__table-column ticket__table-column--row">
                            {{ subscription.value.row }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--place">
                            {{ subscription.value.place }}
                        </div>
                        <div
                            v-if="subscription.isChild"
                            class="ticket__table-column ticket__table-column--actions"
                        >
                            <span
                                class="ticket__table-children ticket__table-children_subscription"
                            >
                                {{ $translation.tickets.children }}
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="ticket__table-column ticket__table-column--sector">
                            {{ subscription.quantity }}
                            <div
                                class="ticket__table-entrance"
                                v-html="
                                    $translation.tickets.parkingTicket.entrance
                                "
                            />
                        </div>
                    </template>
                </div>
                <a
                    v-if="isParking"
                    href="https://yandex.ru/maps/213/moscow/?indoorLevel=1&l=trf%2Ctrfe&ll=37.560155%2C55.792433&mode=routes&rtext=~55.792489%2C37.559100&rtt=auto&ruri=~&z=19"
                    target="_blank"
                    class="ticket__parking-btn ticket__parking-btn_subscription"
                >
                    <span class="ticket__parking-btn-icon">
                        <span class="ticket__parking-btn-icon-square"></span>
                        <span class="ticket__parking-btn-icon-arrow"></span>
                    </span>
                    {{ $translation.tickets.parking.route }}
                </a>
            </div>
            <!-- КАРТОЧКА БОЛЕЛЬЩИКА НУЖНА -->
            <div class="ticket__footer" v-if="fanIdRequired">
                <a
                    href="https://lk.gosuslugi.ru/profile/fan-id"
                    target="_blank"
                    class="btn btn_white ticket__footer-gosuslugi ticket__footer-gosuslugi_mb"
                >
                    госуслуги
                </a>
                <p class="ticket__footer-need-card">
                    Необходима Карта болельщика
                </p>
            </div>
            <!-- КАРТОЧКА БОЛЕЛЬЩИКА НЕ НУЖНА -->
            <div class="ticket__footer" v-else>
                <a
                    v-if="subscription.downloadUrl"
                    :href="subscription.downloadUrl"
                    target="_blank"
                    class="btn btn_white ticket__footer-download"
                >
                    {{ $translation.tickets.subscriptions.download }}
                </a>
                <a
                    v-if="subscription.walletUrl"
                    :href="subscription.walletUrl"
                    class="ticket__footer-wallet"
                >
                    <span>
                        {{ $translation.tickets.wallet }}
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import CircleLeft from "@web/components/tickets/circle/CircleLeft.vue";
import CircleRight from "@web/components/tickets/circle/CircleRight.vue";

export default {
    components: {
        CircleLeft,
        CircleRight,
    },
    props: {
        subscription: {
            type: Object,
            required: true,
        },
        tournament: {
            type: String,
            required: true,
        },
        seasonName: {
            type: String,
            required: true,
        },
        logo: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        fanIdRequired: {
            type: Boolean,
            required: true,
        },
        finishDate: {
            type: String,
            required: true,
        },
    },
    computed: {
        isParking() {
            return this.subscription.type.trim() === "parking";
        },

        circleRightFill() {
            if (this.subscription.value.restaurant === "GOLD") {
                return "#8C7E6D";
            }
            if (this.subscription.value.restaurant === "PLATINUM") {
                return "#7A7A7A";
            }
            if (this.subscription.value.place.slice(0, 3) === "VIP") {
                return "#2F2A23";
            }
            if (this.subscription.value.place.slice(0, 4) === "VVIP") {
                return "#362550";
            } else {
                return "#0959A1";
            }
        },

        finishDateFormat() {
            const YY = this.finishDate.slice(2,4);
            const MM = this.finishDate.slice(5,7);
            const DD = this.finishDate.slice(8,10);
            return `${DD}.${MM}.${YY}`
        },
    },
};
</script>
