import Pagination from '@web/components/pagination/Pagination'
import Preloader from '@com/components/preloader/Preloader'

export default {
  components: {
    Pagination,
    Preloader
  },
  inject: ['windowWidth'],
  data() {
    return {
      stepMobile: 5,
      pageMobile: 1,
      stepDesktop: 10,
      pageDesktop: 0,
      type: '',
      isPending: false,
    }
  },
  computed: {
    isDesktop() {
      return this.windowWidth >= 960
    }
  },
  methods: {
    fetchPage(offset = this.isDesktop ? this.pageDesktop : this.pageMobile, step = this.isDesktop ? this.stepDesktop : this.stepMobile, type = this.type) {
      if (!this.isPending) {
        this.isPending = true

        return new Promise(resolve => {
          this.getItems({
            offset,
            step,
            type
          })
            .then(res => { 
              resolve(res)
            })
            .finally(() => {
              this.isPending = false
            })
        })
      }
    },
    refresh() {
      this.fetchPage()
        .then(res => {
          this.setItems(res)
        })
    },
    changePage(newPage) {
      this.fetchPage(newPage, this.stepDesktop)
        .then(res => {
          this.pageDesktop = newPage
          this.setItems(res)
        })
    },
    showMore() {
      this.fetchPage(this.pageMobile + 1, this.stepMobile)
        .then( res => {
          this.pageMobile++
          this.setItems(this.items.concat(res))
        })
    },
    changeStep(step) {
      this.fetchPage(0, step)
        .then(res => {
          this.pageDesktop = 0
          this.stepDesktop = step
          this.setItems(res)
        })
    },
    changeType(type) {
      this.fetchPage(0, this.isDesktop ? this.stepDesktop : this.stepMobile, type)
        .then(res => {
          this.pageDesktop = 0
          this.pageMobile = 0
          this.type = type
          this.setItems(res)
        })
    }
  }
}
