import axios from 'axios'

export default {
	namespaced: true,
	state: {
		password: ''
	},
	mutations: {
		setPassword(state, value) {
			state.password = value
		}
	},
	actions: {
		updatePassword({commit}, {password, repeatedPassword}) {
			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/api/profile/updatePassword`,
					data: {
						password: password,
						repeatPassword: repeatedPassword
					}
				})
					.then( res => {
						resolve(res)

						commit('setPassword', password)
					})
					.catch( error => {
						reject(error)
					})
			})	
		}
	}

}
