<template>
    <div class="active-subscriptions">
        <div class="container">
            <Heading
                class="heading_subscriptions"
                :title="$translation.tickets.subscriptions.paid"
                :amount="`${subscriptions[0].consist.length} ${declOfNum(
                    subscriptions[0].consist.length,
                    $translation.tickets.subscriptions.titleDeclension
                )}`"
            />
            <div class="active-subscriptions__items">
                <swiper
                    :enabled="false"
                    :breakpoints="{
                        720: {
                            enabled: true,
                            spaceBetween: 20,
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                        },
                        960: {
                            enabled: true,
                            spaceBetween: 20,
                            slidesPerView: 'auto',
                        },
                    }"
                    class="active-subscriptions__swiper"
                >
                    <template
                        v-for="subscriptionGroup in subscriptions"
                        :key="subscriptionGroup.id"
                    >
                        <swiper-slide
                            v-for="subscription in subscriptionGroup.consist"
                            :key="subscription.id"
                            class="active-subscriptions__slide"
                        >
                            <Subscription
                                :subscription="subscription"
                                :fanIdRequired="subscriptionGroup.fanIdRequired"
                                :tournament="subscriptionGroup.tournament"
                                :seasonName="subscriptionGroup.name"
                                :logo="subscriptionGroup.logos[0]"
                                :date="subscriptionGroup.date"
                                :finishDate="subscriptionGroup.finishDate"
                            />
                        </swiper-slide>
                    </template>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import Subscription from "@web/components/tickets/subscriptions/components/Subscription";
import Banner from "@web/components/banner/Banner";
import Heading from "@web/components/heading/Heading";
import { declOfNum } from "@web/functions/functions.js";

export default {
    components: {
        Swiper,
        SwiperSlide,
        Subscription,
        Banner,
        Heading,
    },
    computed: {
        ...mapGetters({
          subscriptions: 'tickets/subscriptions'
        })
    },
    methods: {
        declOfNum,
    },
};
</script>
