<template>
	<div 
		:class="{'banner-wrap--short': shortBanner}"
		class="banner-wrap" 
		ref="bannerWrap"
	>
		<div 
			:id="currentId" 
			class="fox-bn" 
			ref="banner"
		/>
	</div>
</template>

<script>
// Список Ya.adfoxCode.create параметров содержатся в ./bannerList.json
// В props.bannerType укажите ключ который находится в ./bannerList.json
import bannerList from "./bannerList.json";

export default {
	name: "AdFoxBanner",
	props: {
		bannerType: {
			type: String,
			default: "",
			required: true,
		},
		shortBanner:  {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data() {
		return {
			js: "https://yandex.ru/ads/system/context.js",
			scriptEl: document.querySelector(`script[src='${this.js}']`),
			currentId: null,
			bannerSizeIndex: 0,
			reloadInterval: '',
		};
	},
	computed: {
		currentType() {
			return bannerList[this.bannerType];
		},
	},
	created() {
		const mediaQueryBreakpoints = window.matchMedia("(min-width: 960px) and (max-width: 1439px)");

		if (window.outerWidth < 960) {
			this.bannerSizeIndex = 2;
		} else if (window.outerWidth >= 1440) {
			this.bannerSizeIndex = 0;
		} else {
			this.bannerSizeIndex = 1;
		}

		mediaQueryBreakpoints.addEventListener("change", (e) => {
			if (!e.matches && window.outerWidth < 960) {
				this.bannerSizeIndex = 2;
			} else if (!e.matches && window.outerWidth >= 1440) {
				this.bannerSizeIndex = 0;
			} else {
				this.bannerSizeIndex = 1;
			}
			this.initBanner(true);
		});
		this.initBanner();
	},
	methods: {
		created() {
			if (!this.scriptEl) this.createdScript();

			this.scriptEl.onload = () => {
				this.initBanner();
			};
		},

		createdScript() {
			window.yaContextCb = window.yaContextCb || [];

			let adFoxJs = document.createElement("script");
			adFoxJs.setAttribute("async", "");
			adFoxJs.setAttribute("src", this.js);
			document.head.insertAdjacentElement("beforeend", adFoxJs);
			this.scriptEl = adFoxJs;
		},

		initBanner(isResized = false) {
			setTimeout(() => {
				window.yaContextCb.push(() => {
					// если это первый баннер на странице
					if (this.bannerType.includes("1")) {
						// destroy и clearSession происходят только в случае ресайзина, обновляются блоки в контейнерах (другого размера)
						// при подгрузке новых контейнеров (в бесконечных лентах) - destroy не происходит, уже загруженные баннеры не обновляются 
						if (isResized) {
							Ya.adfoxCode.destroy();
						}
						Ya.adfoxCode.clearSession();	
	
						// Удаление комментария
						const comments = Array.from(
							this.$refs.bannerWrap.childNodes,
						).filter(
							(node) =>
								node.nodeType === Node.COMMENT_NODE,
						);
						comments.forEach((comment) =>
							this.$refs.bannerWrap.removeChild(comment),
						);
					}
	
					// создание adfox-контейнера
					Ya.adfoxCode.create({
						ownerId: 388196,
						containerId:
							this.currentType.containerId[this.bannerSizeIndex],
						params: {
							p1: this.currentType.params.p1[this.bannerSizeIndex],
							p2: this.currentType.params.p2[this.bannerSizeIndex],
						},
					});
	
					// добавление комментария перед adfox-контейнером
					const info = this.currentType.info
					const adfoxBeforeComment = `
						<!--AdFox START-->
						<!--yandex_dynamodigital-->
						<!--Площадка: my.fcdm.ru | ${info.langVersion} / ${info.page} | ${info.langVersion} / ${info.position} | ${info.size[this.bannerSizeIndex]} | Версия ${info.version[this.bannerSizeIndex]} | my.fcdm.ru | ${info.langVersion} | ${info.page}-->
						<!--Категория: Версия ${info.version[this.bannerSizeIndex]}-->
						<!--Тип баннера: ${info.size[this.bannerSizeIndex]} - Сквозной /html5,png,jpeg,jpg,gif.video-->
					`;
	
					this.$refs.banner.insertAdjacentHTML(
						"beforebegin",
						adfoxBeforeComment,
					);
				});
				this.currentId = this.currentType.containerId[this.bannerSizeIndex];
	
				// установка 30сек-интервала перезагрузки adfox-контейнеров
				clearInterval(this.reloadInterval);
				this.reloadInterval = setInterval(() => {
					Ya.adfoxCode.reload(null, false);
					Ya.adfoxCode.clearSession();
				}, 30000);
			}, 100);
		},
	},
	mounted() {
		this.created();
	},
	beforeUpdate() {
		Ya.adfoxCode.destroy(this.currentId);
	},
	updated() {
		this.initBanner();
	},
};
</script>
