<template>
  <label class="radio" :class="classProp">
    <input 
      type="radio" 
      class="radio__native"
      v-bind="$attrs"
      :checked="modelValue === $attrs.value" 
      @input="$event => $emit('update:modelValue', $event.target.value)"
    >
    <button type="button" class="radio__custom"></button>
    <span class="radio__text">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number],
      required: false
    },
    classProp: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue']
}
</script>
