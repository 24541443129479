<template>
  <Preloader 
    v-if="isPending"
    :text="$translation.preloader.loading"
    class="preloader_large"
  />
  <div
    v-else 
    class="tickets page"
  >
    <Header
      @open-popup="openPopup"
      :nearestMatchOrderId="nearestMatchOrderId"
      :isFetchError="isFetchTicketsError" 
    />
    <template  v-if="!isFetchTicketsError">
      <Subscribe v-if="isSubscribeBlockVisible" />
      <Subscriptions v-if="subscriptions.length" />
      <Tickets />
      <TicketsDomDynamo />
    </template>
    <section class="banner banner--mt">
      <AdFoxBanner bannerType="other1" />
    </section>
    <Story />
  </div>
  <Popup
    v-if="isPopupOpen"
    @close-popup="closePopup"
    :nearestMatchOrderId="nearestMatchOrderId"
  />
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'

import Header from '@web/components/tickets/header/Header'
import Tickets from '@web/components/tickets/tickets/Tickets'
import TicketsDomDynamo from '@web/components/tickets/ticketsDomDynamo/TicketsDomDynamo'
import Subscriptions from '@web/components/tickets/subscriptions/Subscriptions'
import Story from '@web/components/tickets/story/Story'
import Preloader from '@com/components/preloader/Preloader'
import AdFoxBanner from '@web/components/adfox/AdFoxBanner'
import Subscribe from '@web/components/tickets/subscribe/Subscribe'
import Popup from '@web/components/tickets/popup/Popup'
import popupMixin from '@com/mixins/popup'

export default {
  mixins: [popupMixin],
  components: {
    Header,
    Tickets,
    Story,
    Preloader,
    AdFoxBanner,
    Subscribe,
    Subscriptions,
    TicketsDomDynamo,
    Popup,
  },
  data() {
    return {
      isPopupOpen: false,
      isPending: true,
      isFetchTicketsError: false,
      archieveTicketsList: [],
    }
  },
  computed: {
    ...mapState({
      globalOffers: store => store.globalOffers.globalOffers
    }),
    ...mapGetters({
      tickets: 'tickets/tickets',
      subscriptions: 'tickets/subscriptions',
      nearestMatchOrEvent: "tickets/nearestMatchOrEvent",
    }),
    isSubscribeBlockVisible() {
      return !this.globalOffers && !this.tickets.length && !this.subscriptions.length
    },
    nearestMatchOrderId() {
      return this.archieveTicketsList.find(item => item.events[0]?.id === this.nearestMatchOrEvent?.id)?.id || ''
    }
  },
  methods: {
    ...mapMutations({
      setArchiveTickets: 'archiveTickets/setTickets',
    }),
    ...mapActions({
      getTickets: 'tickets/getTickets',
      getGlobalOffers: 'globalOffers/getGlobalOffers',
      getArchiveTickets: 'archiveTickets/getArchiveTickets',
    }),
    openPopup() {
      this.isPopupOpen = true
      document.body.classList.add('desktop-locked')

      if (this.windowWidth < 1280) {
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }
    },
    closePopup() {
      this.isPopupOpen = false
      document.body.classList.remove('desktop-locked')
    },
  },
  mounted() {
    this.getGlobalOffers()

    const archivePromise = this.getArchiveTickets({
      offset: 0,
      step: 10
    }) 
    .then(res => {
      this.archieveTicketsList = res
      this.setArchiveTickets(res)
    })
    
    const ticketsPromise = this.getTickets()

    Promise.all([archivePromise, ticketsPromise])
      .catch((err) => {
        this.isFetchTicketsError = true
      })
      .finally(() => {
        this.isPending = false
      })
  }
}
</script>