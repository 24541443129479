<template>
  <label class="loyalty-sort__item">
    <input 
      type="radio" 
      class="loyalty-sort__input"
      v-bind="$attrs"
      :checked="modelValue === $attrs.value" 
      @input="$emit('update:modelValue', $event.target.value)"
      :disabled="disabled"
    >
    <button 
      type="button" 
      class="btn btn_large loyalty-sort__btn"
      :class="{
        'btn_white': modelValue !== $attrs.value,
        'btn_blue': modelValue === $attrs.value
      }"
      :disabled="disabled"
    >
      {{ text }}
    </button>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  emits: ['update:modelValue']
}
</script>
