<template>
  <svg width="34" height="52" viewBox="0 0 34 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1801_11408)">
        <path d="M34 -1H-1V53H34V-1Z" fill="white"></path>
        <g filter="url(#filter0_d_1801_11408)">
          <mask id="mask0_1801_11408" style="mask-type:alpha;" maskUnits="userSpaceOnUse" x="-274" y="-64" width="300" height="432">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M-254 -64C-265.046 -64 -274 -55.0457 -274 -44V348C-274 359.046 -265.046 368 -254 368H6C17.0457 368 26 359.046 26 348V51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1V-44C26 -55.0457 17.0457 -64 6 -64H-254Z" fill="white"></path>
          </mask>
          <g mask="url(#mask0_1801_11408)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M-254 -64C-265.046 -64 -274 -55.0457 -274 -44V348C-274 359.046 -265.046 368 -254 368H6C17.0457 368 26 359.046 26 348V51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1V-44C26 -55.0457 17.0457 -64 6 -64H-254Z" :fill="fill"></path>
              <path d="M26 51H27V50H26V51ZM26 1V2H27V1H26ZM-273 -44C-273 -54.4934 -264.493 -63 -254 -63V-65C-265.598 -65 -275 -55.598 -275 -44H-273ZM-273 348V-44H-275V348H-273ZM-254 367C-264.493 367 -273 358.493 -273 348H-275C-275 359.598 -265.598 369 -254 369V367ZM6 367H-254V369H6V367ZM25 348C25 358.493 16.4934 367 6 367V369C17.598 369 27 359.598 27 348H25ZM25 51V348H27V51H25ZM26 50C12.7452 50 2 39.2548 2 26H0C0 40.3594 11.6406 52 26 52V50ZM2 26C2 12.7452 12.7452 2 26 2V0C11.6406 0 0 11.6406 0 26H2ZM25 -44V1H27V-44H25ZM6 -63C16.4934 -63 25 -54.4934 25 -44H27C27 -55.598 17.598 -65 6 -65V-63ZM-254 -63H6V-65H-254V-63Z" fill="#EEF0F1"></path>
          </g>
        </g>
    </g>
    <defs>
      <filter id="filter0_d_1801_11408" x="-284" y="-69" width="320" height="452" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
         <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
         <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
         <feOffset dy="5"></feOffset>
         <feGaussianBlur stdDeviation="5"></feGaussianBlur>
         <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.34902 0 0 0 0 0.631373 0 0 0 0.1 0"></feColorMatrix>
         <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1801_11408"></feBlend>
         <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1801_11408" result="shape"></feBlend>
      </filter>
      <clipPath id="clip0_1801_11408">
         <rect width="34" height="52" fill="white"></rect>
      </clipPath>
   </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      types: String,
      required: false
    }
  }
}
</script>
