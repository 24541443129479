import axios from 'axios'

export default {
	namespaced: true,
	state: {
		agreement: false,
    esiaLinked: false,
    esiaUrl: ''
	},
	mutations: {
		setAgreement(state, value) {
			state.agreement = value
		},
    setEsiaLinked(state, value) {
			state.esiaLinked = value
		},
    setEsiaUrl(state, value) {
			state.esiaUrl = value
		}
	},
	actions: {
		updateAgreement({commit}, {agreement}) {
			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/api/profile/updateBiometry`,
					data: {
						esiaAgreement: agreement
					}
				})
					.then( res => {
            commit('setAgreement', res.data.data.esiaAgreement)
						resolve(res)
					})
					.catch( error => {
						reject(error)
					})
			})	
		}
	}

}
