<template>
    <Preloader
        v-if="status === 'loading'"
        :text="$translation.preloader.loading"
        class="preloader_large"
    />
    <div class="wrapper" v-else>
        <Header />
        <div class="content">
            <main class="main">
                <router-view />
            </main>
            <Footer />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import Header from "@web/components/header/Header";
import Footer from "@web/components/footer/Footer";
import Preloader from "@com/components/preloader/Preloader";

export default {
    components: {
        Header,
        Footer,
        Preloader,
    },
    computed: {
        ...mapState({
            status: (state) => state.token.status,
        }),
    },
};
</script>
