<template>
  <Preloader
    v-if="isPending"
    :text="$translation.preloader.loading"
    class="preloader_large"
  />
  <template v-else>
    <Header>
      <h1 class="loyalty__title">
       {{ $translation.loyalty.stock.title }}
      </h1>
    </Header>
    
    <Stock 
      :events="stock"
      :sort="sort"
      :disabled="isLoading"
      @load-more="onLoadMore"
      @change-sort="onChangeSort"
    />
    <UpButton 
      section="[data-loyalty-header]"
      class="loyalty__up-btn"
    />
  </template>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Header from '@web/components/loyalty/Header.vue'
import Stock from '@web/components/loyalty/events/Stock.vue'
import Preloader from '@com/components/preloader/Preloader'
import UpButton from '@com/components/forms/UpButton'

export default {
  components: {
    Header,
    Stock,
    Preloader,
    UpButton
  },
  data() {
    return {
      isPending: true,
      isLoading: false,
      page: 1,
      sort: 'any',
      sortRow: 'is_active'
    }
  },
  computed: {
    ...mapState({
      stock: state => state.loyaltyStock.stock,
      pageCount: state => state.loyaltyStock.pageCount
    }),
  },
  methods: {
    ...mapMutations({
      addStock: 'loyaltyStock/addStock',
      setStock: 'loyaltyStock/setStock',
    }),
    ...mapActions({
      getStock: 'loyaltyStock/getStock'
    }),
    onLoadMore() {
      if (!this.isLoading && this.pageCount > this.page) {
        this.isLoading = true
       
        this.getStock({
          page: this.page + 1,
          sortRow: this.sortRow,
          sort: this.sort
        })
          .then(res => {
            this.page++
            this.addStock(res.data.data.events)
          })
          .finally(() => {
            this.isLoading = false
          })
      }  
    },
    onChangeSort(sort) {
      if (!this.isLoading && this.sort !== sort) {
        this.isLoading = true

        this.getStock({
          page: 1,
          sortRow: this.sortRow,
          sort: sort
        })
          .then(res => {
            this.page = 1
            this.sort = sort
            this.setStock(res.data.data.events)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  },
  created() {
    this.getStock({
      page: this.page,
      sortRow: this.sortRow,
      sort: this.sort
    })
      .then(res => {
        this.setStock(res.data.data.events)
      })
      .finally(() => {
        this.isPending = false
      })
  }
}
</script>
