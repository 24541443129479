<template>
  <div class="widgets">
    <component
      v-for="(widget, index) in widgets"
      :key="index"
      :is="widget.name"
      :content="widget.content"
    ></component>
  </div>
</template>

<script>
import WidgetEditor from '@web/components/widgets/includes/WidgetEditor'
import WidgetImage from '@web/components/widgets/includes/WidgetImage'

export default {
  components: {
    widgetEditor: WidgetEditor,
    widgetImg: WidgetImage
  },
  props: {
    widgets: {
      type: Array,
      required: false
    }
  },
}
</script>
