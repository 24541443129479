<template>
  <div class="orders-popup__order-item">
    <div class="orders-popup__order-item-row">
      <div class="orders-popup__order-item-left">
        <picture v-if="order.picture" class="orders-popup__order-item-img">
          <img :src="order.picture" :alt="order.name">
        </picture>
      </div>
      <div class="orders-popup__order-item-content">
        <div class="orders-popup__order-item-title">
          {{ order.name }}
        </div>
        <div class="orders-popup__order-item-data">
          <div class="orders-popup__order-item-data-column">
            <div class="orders-popup__order-item-data-header">
              {{ $translation.orders.popup.count }}
            </div>
            <div class="orders-popup__order-item-data-value">
              {{ order.count }} {{ declOfNum(order.count, $translation.orders.popup.thing) }}
            </div>
          </div>
          <div 
            v-if="order.color"
            class="orders-popup__order-item-data-column"
          >
            <div class="orders-popup__order-item-data-header">
              {{  $translation.orders.popup.color }}
            </div>
            <div class="orders-popup__order-item-data-value">
              <span 
                class="orders-popup__order-item-data-color-text"
              >
                {{ order.colorTitle }}
              </span>
              <span 
                class="orders-popup__order-item-data-color"
                :class="{
                  'orders-popup__order-item-data-color_border': isWhite
                }"
                :style="`background: ${order.color}`"
              ></span>
            </div>
          </div>
          <div 
            v-if="order.size"
            class="orders-popup__order-item-data-column"
          >
            <div class="orders-popup__order-item-data-header">
              {{ $translation.orders.popup.size }}
            </div>
            <div class="orders-popup__order-item-data-value">
              {{ order.size }}
            </div>
          </div>
        </div>
      </div>
      <div class="orders-popup__order-item-price">
        <div class="orders-popup__order-item-price-current">{{ order.discountPrice }} ₽</div>
        <div
          v-if="order.price !== order.discountPrice" 
          class="orders-popup__order-item-price-previous"
        >
          {{ order.price }} ₽
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { declOfNum } from '@web/functions/functions.js'

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    isWhite() {
      return this.order.color && (this.order.color.toLowerCase() === '#ffffff' || this.order.color === 'rgb(255, 255, 255)')
    }
  },
  methods: {
    declOfNum
  }
}
</script>

