<template>
    <div class="orders-card orders__card">
        <div class="orders-card__row-title">
            <div class="h3 orders-card__title">
                {{ $translation.orders.active.card.order }} №{{ order.orderNumber }}
            </div>
            <div class="orders-card__btns">
                <span class="orders-card__amount"
                    >{{ order.productsCount }}
                    {{
                        declOfNum(
                            order.productsCount,
                            $translation.orders.active.card.product
                        )
                    }}
                </span>
                <button
                    type="button"
                    class="btn btn_white orders-card__btn"
                    :disabled="isOrderPending"
                    @click="$emit('openPopup')"
                >
                    {{ $translation.orders.active.card.more }}
                </button>
                <form
                    v-if="order.paymentConfig"
                    method="post"
                    :action="order.paymentConfig.url"
                    class="orders-card__pay-form"
                >
                    <input
                        type="hidden"
                        name="Receipt"
                        :value="order.paymentConfig.receipt"
                    />
                    <input
                        type="hidden"
                        name="ReceiptSignature"
                        :value="order.paymentConfig.receiptSignature"
                    />
                    <input
                        type="hidden"
                        name="Shop_IDP"
                        :value="order.paymentConfig.shopIdp"
                    />
                    <input
                        type="hidden"
                        name="Currency"
                        :value="order.paymentConfig.currency"
                    />
                    <input
                        type="hidden"
                        name="Order_IDP"
                        :value="order.paymentConfig.orderIdp"
                    />
                    <input
                        type="hidden"
                        name="Subtotal_P"
                        :value="order.paymentConfig.subtotalP"
                    />
                    <input
                        type="hidden"
                        name="Signature"
                        :value="order.paymentConfig.signature"
                    />
                    <input
                        type="hidden"
                        name="URL_RETURN_OK"
                        :value="order.paymentConfig.urlReturnOk"
                    />
                    <input
                        type="hidden"
                        name="URL_RETURN_NO"
                        :value="order.paymentConfig.urlReturnNo"
                    />
                    <button
                        type="submit"
                        class="btn btn_blue orders-card__btn"
                        :disabled="isOrderPending"
                    >
                        {{ $translation.orders.active.card.pay }}
                    </button>
                </form>
            </div>
        </div>
        <div class="orders-card__row">
            <div class="orders-card__column-lg">
                <div class="h3 orders-card__title orders-card__title_mobile">
                    {{ $translation.orders.active.card.order }} №{{ order.id }}
                </div>
                <div class="orders-card__wrap">
                    <div class="orders-card__table orders-table">
                        <div class="orders-table__row">
                            <div class="orders-table__column-left">
                                {{ $translation.orders.active.card.status }}
                            </div>
                            <div
                                class="orders-table__column-right orders-table__column-right_highlight"
                                :class="{
                                    'orders-table__column-right_canceled':
                                        order.statusId === 'C',
                                }"
                            >
                                {{ order.statusTitle }}
                            </div>
                        </div>
                        <div class="orders-table__row">
                            <div class="orders-table__column-left">
                                {{
                                    $translation.orders.active.card
                                        .deliveryMethod
                                }}
                            </div>
                            <div class="orders-table__column-right">
                                {{ order.deliveryWay }}
                            </div>
                        </div>
                        <div class="orders-table__row">
                            <div class="orders-table__column-left">
                                {{
                                    order.deliveryWayId === 2
                                        ? $translation.orders.active.card
                                              .pickupAddress
                                        : $translation.orders.active.card
                                              .deliveryAddress
                                }}
                            </div>
                            <div class="orders-table__column-right">
                                {{ order.deliveryAddress }}
                            </div>
                        </div>
                        <!-- <div class="orders-table__row">
              <div class="orders-table__column-left">
                {{ $translation.orders.active.card.trackNumber }}
              </div>
              <div class="orders-table__column-right">
                <span class="orders-table__track">
                  {{ order.trackNumber }}
                </span>
              </div>
            </div> -->
                    </div>
                    <!-- ОТМЕНИТЬ -->
                    <button
                        v-if="order.statusId === 'N'"
                        type="button"
                        class="btn btn_white orders-card__btn"
                        :disabled="isOrderPending"
                        @click="openPrePopup(order.id)"
                    >
                        {{ $translation.btn.cancel }}
                    </button>
                </div>
            </div>
            <div class="orders-card__column-sm">
                <ul class="orders-card__img-group">
                    <li
                        v-for="(photo, index) in photos"
                        :key="index"
                        class="orders-card__img"
                    >
                        <picture class="orders-card__img-picture">
                            <img :src="photo" alt="" />
                        </picture>
                    </li>
                    <li
                        class="orders-card__img"
                        v-if="order.productsCount > photos.length"
                    >
                        <span class="orders-card__img-picture">
                            <span class="orders-card__img-text">
                                +{{ order.productsCount - photos.length }}
                            </span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="orders-card__btns orders-card__btns_mobile">
            <span class="orders-card__amount"
                >{{ order.productsCount }}
                {{
                    declOfNum(
                        order.productsCount,
                        $translation.orders.active.card.product
                    )
                }}</span
            >
            <div class="orders-card__btns-wrap">
                <button
                    type="button"
                    :disabled="isOrderPending"
                    class="btn btn_white orders-card__btn"
                    @click="$emit('openPopup')"
                >
                    {{ $translation.orders.active.card.more }}
                </button>
                <form
                    v-if="order.paymentConfig"
                    method="post"
                    :action="order.paymentConfig.url"
                    class="orders-card__pay-form"
                >
                    <input
                        type="hidden"
                        name="Receipt"
                        :value="order.paymentConfig.receipt"
                    />
                    <input
                        type="hidden"
                        name="ReceiptSignature"
                        :value="order.paymentConfig.receiptSignature"
                    />
                    <input
                        type="hidden"
                        name="Shop_IDP"
                        :value="order.paymentConfig.shopIdp"
                    />
                    <input
                        type="hidden"
                        name="Currency"
                        :value="order.paymentConfig.currency"
                    />
                    <input
                        type="hidden"
                        name="Order_IDP"
                        :value="order.paymentConfig.orderIdp"
                    />
                    <input
                        type="hidden"
                        name="Subtotal_P"
                        :value="order.paymentConfig.subtotalP"
                    />
                    <input
                        type="hidden"
                        name="Signature"
                        :value="order.paymentConfig.signature"
                    />
                    <input
                        type="hidden"
                        name="URL_RETURN_OK"
                        :value="order.paymentConfig.urlReturnOk"
                    />
                    <input
                        type="hidden"
                        name="URL_RETURN_NO"
                        :value="order.paymentConfig.urlReturnNo"
                    />
                    <button
                        type="submit"
                        class="btn btn_blue orders-card__btn"
                        :disabled="isOrderPending"
                    >
                        {{ $translation.orders.active.card.pay }}
                    </button>
                </form>
                <!-- ОТМЕНИТЬ -->
                <button
                    v-if="order.statusId === 'N'"
                    type="button"
                    class="btn btn_white orders-card__btn"
                    :disabled="isOrderPending"
                    @click="openPrePopup(order.id)"
                >
                    {{ $translation.btn.cancel }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { declOfNum } from "@web/functions/functions.js";

export default {
    props: {
        order: {
            type: Object,
            required: true,
        },
        isOrderPending: {
            type: Boolean,
            required: true,
        },
    },
    inject: ["windowWidth"],
    emits: ["openPopup", "cancelOrder"],
    computed: {
        photos() {
            const limit = this.windowWidth >= 480 ? 4 : 3;

            if (
                this.order.productsCount > this.order.productPhotos.length ||
                this.order.productPhotos.length > limit
            ) {
                return this.order.productPhotos.slice(0, limit - 1);
            }

            return this.order.productPhotos;
        },
    },
    methods: {
        ...mapMutations({
            setCurrentOrder: "orders/setCurrentOrder",
            setArchiveOrders: "archiveOrders/setOrders",
            setCancel: "activeOrders/setCancel",
        }),
        ...mapActions({
            getActiveOrders: "activeOrders/getActiveOrders",
            getArchiveOrders: "archiveOrders/getArchiveOrders",
        }),
        openPrePopup(id) {
            this.setCurrentOrder({
                id,
            });
            this.setCancel(true);
        },

        declOfNum,
    },
};
</script>
