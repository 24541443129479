<template>
    <section class="settings__section settings__section_achieves">
        <div class="settings-achieves">
            <swiper 
                class="settings-achieves__swiper"
                :modules="modules"
                navigation
                :slidesPerView="'auto'" 
                :spaceBetween="16" 
                >
                <swiper-slide
                    v-for="achieve in activeAchievements"
                    :key="achieve.title"
                >
                    <achievement-card
                        :achieveData="achieve"
                        :isSuccess="successAchievements.find(item => item === achieve.id) ? true : false"
                        @click="$emit('anchor', achieve.anchor)"
                    />
                </swiper-slide>
            </swiper>
            <div class="settings-achieves__progress-wrap">
                <!-- line -->
                <div class="settings-achieves__progress" ref="progress">
                    <div class="settings-achieves__progress-line" ref="line">
                        <img src="@web/assets/img/profile/ball.svg" alt="" />
                    </div>
                </div>
                <!-- tooltip -->
                <div class="settings-achieves__progress-tooltip" ref="tooltip">
                    <p
                        v-if="progress <= 50"
                        class="settings-achieves__progress-tooltip__percents"
                    >
                        {{ progress }}%
                    </p>
                    <p v-else-if="progress >= 100">
                        Поздравляем!<br />ты заполнил весь профиль!
                    </p>
                    <p v-else>До победы осталось<br />меньше половины</p>
                    <button
                        v-if="progress >= 100"
                        class="settings-achieves__progress-btn"
                        @click="$emit('hideAchievements')"
                    >
                        Я молодец
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import AchievementCard from "@web/components/profile/achieves/components/AchievementCard";

export default {
    components: {
        Swiper,
        SwiperSlide,
        AchievementCard,
    },
    emits: ["anchor"],
    data() {
        return {
            modules: [Navigation],
        };
    },
    computed: {
        ...mapState({
            achievements: (state) => state.achievements.achievements,
            successAchievements: (state) => state.achievements.successAchievements,
            progress: (state) => state.achievements.progress,
        }),
        activeAchievements() {
            return this.achievements.filter(item => item.active)
        }
    },
    methods: {
        ...mapActions({
            getAchievements: "achievements/getAchievements",
        }),
        tooltipPosition() {
            const progressLine = this.$refs.progress
            const progressBlueLine = this.$refs.line
            const tooltip = this.$refs.tooltip

            progressBlueLine.style.width = `${this.progress}%`;

            // синяя полоска не может быть уже ширины мячика
            if (progressBlueLine.clientWidth < 20) {
                progressBlueLine.style.width = "20px";
            }

            const tooltipWidth = tooltip.clientWidth;
            const progressWidth = progressLine.clientWidth;
            const ballCenter = progressBlueLine.clientWidth - 10;

            if (ballCenter * 2 < tooltipWidth) {
                tooltip.style.left = "0";
                tooltip.style.right = "auto";
            } else if (ballCenter + tooltipWidth / 2 > progressWidth) {
                tooltip.style.left = "auto";
                tooltip.style.right = "0";
            } else {
                tooltip.style.left = `${
                    ballCenter - tooltipWidth / 2
                }px`;
                tooltip.style.right = "auto";
            }
        },
    },
    mounted() {
        this.getAchievements();
        this.tooltipPosition();
        window.addEventListener('resize', () => {this.tooltipPosition()})
    },
    unmounted() {
        window.removeEventListener('resize', () => {this.tooltipPosition()})
    },
    watch: {
        progress() {
            this.tooltipPosition();
        },
    },
};
</script>
