<template>
    <div class="active-tickets__column active-tickets__column_small">
        <div class="ticket ticket_parking">
            <div
                class="ticket__header ticket__header_ticket ticket__header_center"
            >
                <span class="ticket__header-circle">
                    <CircleLeft fill="#ffffff" />
                </span>
                <span class="ticket__header-circle">
                    <CircleRight fill="#ffffff" />
                </span>
                {{ $translation.tickets.parking.title }}
            </div>
            <div class="ticket__body ticket__body_ticket">
                <div class="ticket__parking">
                    <div class="ticket__parking-left">
                        <div class="ticket__parking-place">
                            {{ $translation.tickets.parking.places }}
                        </div>
                        <div class="ticket__parking-number">
                            {{ parking.quantity }}
                        </div>
                        <div class="ticket__parking-text">
                            {{ parking.value }}
                        </div>
                    </div>
                    <div class="ticket__parking-right">
                        <a
                            href="https://fcdm.ru/club/parking/"
                            target="_blank"
                            class="ticket__parking-btn"
                        >
                            <span class="ticket__parking-btn-icon">
                                <span
                                    class="ticket__parking-btn-icon-square"
                                ></span>
                                <span
                                    class="ticket__parking-btn-icon-arrow"
                                ></span>
                            </span>
                            {{ $translation.tickets.parking.route }}
                        </a>
                    </div>
                </div>
                <a
                    v-if="parking.downloadUrl"
                    :href="parking.downloadUrl"
                    target="_blank"
                    class="btn btn_white ticket__download ticket__download_parking"
                >
                    {{ $translation.tickets.download }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import CircleLeft from "@web/components/tickets/circle/CircleLeft.vue";
import CircleRight from "@web/components/tickets/circle/CircleRight.vue";

export default {
    components: {
        CircleLeft,
        CircleRight,
    },
    props: {
        parking: {
            type: Object,
            required: true,
        },
    },
};
</script>
