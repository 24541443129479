<template>
  <div class="container">
    <section class="orders__archive orders-archive">
      <template v-if="!isFetchError">
        <Heading
          class="heading_orders-story"
          :title="$translation.orders.archive.title.text" 
          :amount="`${itemsCount} ${declOfNum(itemsCount, $translation.orders.archive.title.orders)}`"
        />
        <div class="orders-archive__cards relative"> 
          <Preloader
            v-show="isPending"
            :text="$translation.preloader.loading"
            class="preloader_absolute preloader_middle preloader_pagination"
          />
          <Card 
            v-for="item in items" 
            :key="item.id"
            :order="item"
            :is-order-pending="isOrderPending"
            @open-popup="$emit('openPopup', item.id)"
          />
        </div>
        <Pagination 
          modificator="pagination_mt"
          :step-mobile="stepMobile"
          :page-mobile="pageMobile"
          :step-desktop="stepDesktop"
          :page-desktop="pageDesktop"
          :disabled="isPending"
          :items-count="itemsCount"
          @change-step="changeStep"
          @change-page="changePage"
          @show-more="showMore"
        />
      </template>
      <h3 class="h3 heading__title" v-else>
        {{$translation.orders.archive.title.fetchError }}
      </h3>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Card from '@web/components/orders/archive/components/Card'
import Heading from '@web/components/heading/Heading'
import { declOfNum } from '@web/functions/functions.js'
import pagination from '@web/mixins/pagination'

export default {
  mixins: [pagination],
  components: {
    Card,
    Heading
  },
  props: {
    isOrderPending: {
      type: Boolean,
      required: true
    },
    isFetchError: {
      type: Boolean,
      required: true
    }
  },
  emits: ['openPopup'],
  computed: {
    ...mapState({
      items: state => state.archiveOrders.orders,
      itemsCount: state => state.archiveOrders.ordersCount
    })
  },
  methods: {  
    ...mapMutations({
      setItems: 'archiveOrders/setOrders',
    }),
    ...mapActions({
      getItems: 'archiveOrders/getArchiveOrders'
    }),
    declOfNum
  }
}
</script>

