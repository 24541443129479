<template>
  <Preloader
    v-if="isPending"
    :text="$translation.preloader.loading"
    class="preloader_large"
  />
  <template v-else>
    <div
      class="orders page"
      :class="{
        'orders_hidden': isPopupOpen
      }"
    >
      <Active
        :is-order-pending="isOrderPending"
        @open-popup="fetchOrder"
        @cancel-order="removeOrder"
        :isFetchError="isActiveOrdersFetchError"
      />
      <Archive
        :is-order-pending="isOrderPending"
        @open-popup="fetchOrder"
        :isFetchError="isArchiveOrdersFetchError"
      />

      <section class="banner banner--mt">
        <AdFoxBanner bannerType="other1" />
      </section>
    </div>
    <Popup
      v-if="isPopupOpen"
      :is-order-pending="isOrderPending"
      @close-popup="closePopup"
      @cancel-order="removeOrder"
    />
    <PrePopup
      v-if="cancel"
      @close-popup="closePopup"
      @cancel-order="removeOrder"
    />
  </template>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import Active from '@web/components/orders/active/Active'
import Archive from '@web/components/orders/archive/Archive'
import Popup from '@web/components/orders/popup/Popup'
import PrePopup from '@web/components/orders/popup/PrePopup'
import Preloader from '@com/components/preloader/Preloader'
import AdFoxBanner from '@web/components/adfox/AdFoxBanner'
import popupMixin from '@com/mixins/popup'

export default {
  mixins: [popupMixin],
  components: {
    Active,
    Archive,
    Preloader,
    Popup,
    PrePopup,
    AdFoxBanner
  },
  inject: ['windowWidth'],
  data() {
    return {
      isPopupOpen: false,
      isOrderPending: false,
      isPending: true,
      isActiveOrdersFetchError: false,
      isArchiveOrdersFetchError: false,
    }
  },
  computed: {
    ...mapGetters("activeOrders", ["cancel"]),
  },
  methods: {
    ...mapMutations({
      setCurrentOrder: 'orders/setCurrentOrder',
      setArchiveOrders: 'archiveOrders/setOrders'
    }),
    ...mapActions({
      getActiveOrders: 'activeOrders/getActiveOrders',
      getArchiveOrders: 'archiveOrders/getArchiveOrders',
      getOrder: 'orders/getOrder',
      cancelOrder: 'orders/cancelOrder'
    }),
    fetchOrder(id) {
      if (!this.isOrderPending) {
        this.isOrderPending = true
        this.setCurrentOrder({
          id
        })
        this.openPopup()
        this.getOrder({
          id: id
        })
          .catch(()=> {
            this.closePopup()
          })
          .finally(()=> {
            this.isOrderPending = false
          })
      }
    },
    openPopup() {
      this.isPopupOpen = true
      document.body.classList.add('desktop-locked')

      if (this.windowWidth < 1280) {
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }
    },
    closePopup() {
      this.isPopupOpen = false
      document.body.classList.remove('desktop-locked')
    },
    removeOrder(orderId) {
      this.cancelOrder(orderId)
    }
  },
  mounted() {
    const activePromise = this.getActiveOrders()
      .catch((err) => {
        this.isActiveOrdersFetchError = true
      })
      
      const archivePromise = this.getArchiveOrders({
        offset: 0,
        step: 10
      })
      .then(res => {
        this.setArchiveOrders(res)
      })
      .catch((err) => {
        this.isArchiveOrdersFetchError = true
      })


    Promise.all([activePromise, archivePromise])
      .finally(() => {
        this.isPending = false
      })
  },
}
</script>
