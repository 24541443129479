<template>
  <Wrapper>
    <Cards 
      :items="partners"
      @scroll-end="$emit('loadMore')"
      @open-popup="onOpenPopup"
      :is-partner="true"
      bannerType="partners"
    />
  </Wrapper>
  <Popup 
    v-if="popupName === 'loyaltyPartners'"
    :items="partners"
    :initial-item="initialItem"
    :is-partner="true"
  />
</template>

<script>
import Popup from '@web/components/loyalty/popup/Popup.vue'
import Wrapper from '@web/components/loyalty/Wrapper.vue'
import Cards from '@web/components/loyalty/cards/Cards.vue'
import popupMixin from '@com/mixins/popup'

export default {
  mixins: [popupMixin],
  components: {
    Wrapper,
    Cards,
    Popup
  },
  props: {
    partners: {
      type: Array,
      required: false
    }
  },
  emits: ['loadMore'],
  data() {
    return {
      initialItem: {}
    }
  },
  methods: {
    onOpenPopup(item) {
      this.initialItem = item

      this.openPopup({
        name: 'loyaltyPartners',
        contentClasses: ['popup__content_loyalty'],
        popupClasses: ['popup_grey']
      })
    },
  }
}
</script>
