<template>
  <div class="loyalty-promocodes">
      <Heading
        class="heading_loyalty"
        :title="$translation.loyalty.promocodes.title" 
      />
      <div class="loyalty-promocodes__items loyalty-swiper relative">
        <swiper
          :slidesPerView="'auto'"
          :spaceBetween="20"
        >
            <swiper-slide v-for="promocode in sortedPromocodes" :key="promocode.number">
              <PromocodeCard :promocode="promocode" />
            </swiper-slide>
        </swiper>
      </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import PromocodeCard from '@web/components/loyalty/promocodes/components/PromocodeCard'
import Heading from '@web/components/heading/Heading'

import "swiper/swiper-bundle.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    PromocodeCard,
    Heading
  },
  props: {
    promocodes: {
      type: Array,
      required: true
    },
  },
  computed: {
    sortedPromocodes() {
      return this.promocodes.sort((a, b) => new Date(a.date_end) - new Date(b.date_end))
    }
  },
  methods: {}
}
</script>
