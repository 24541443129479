<template>
    <div 
        class="settings-achieves__item"
        :class="{'settings-achieves__item--success': isSuccess}"
        :data-scroll="achieveData.anchor"
    >
        <div class="settings-achieves__item-label">
            <img v-if="isSuccess" src="@web/assets/img/profile/check.svg" alt="" />
            <img v-else src="@web/assets/img/profile/lock.svg" alt="" />
        </div>
        <img :src="isSuccess ? achieveData.success_images.ru : achieveData.image.ru" alt="" />
        <p 
            class="settings-achieves__item-title"
        >{{ achieveData.title.ru }}</p>
        <!-- BACKSIDE -->
        <div class="settings-achieves__item-backside">
            <p class="settings-achieves__item-backside-title">{{ achieveData.title.ru }}</p>
            <div class="settings-achieves__item-backside-bottom">
                <p class="settings-achieves__item-backside-text">{{ isSuccess ? achieveData.successText.ru : achieveData.requirementsText.ru }}</p>
                <ul class="settings-achieves__item-backside-list">
                    <template v-if="!isSuccess">
                        <li 
                            v-for="requirement in requirementTexts" 
                            class="settings-achieves__item-backside-item"
                            :key="requirement" 
                        >{{ requirement }}</li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        achieveData: {
            type: Object,
            required: true,
        },
        isSuccess: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            requirementsList: [
                { 
                    value: "allowEmail", 
                    text: "Разрешена отправка почты" 
                }, 
                { 
                    value: "allowSms", 
                    text: "Разрешена отправка смс" 
                }, 
                { 
                    value: "birthDate", 
                    text: "Дата рождения" 
                }, 
                { 
                    value: "cityId", 
                    text: "Город"
                }, 
                { 
                    value: "emailAddress", 
                    text: "E-mail" 
                }, 
                { 
                    value: "emailVerified", 
                    text: "E-mail подтвержден" 
                }, 
                { 
                    value: "esiaAgreement", 
                    text: "ESIA согласовано" 
                }, 
                { 
                    value: "esiaLinked", 
                    text: "ESIA залинковано" 
                }, 
                { 
                    value: "esiaUrl", 
                    text: "ESIA" 
                }, 
                { 
                    value: "fanId", 
                    text: "Карта болельщика" 
                }, 
                { 
                    value: "fanTeam", 
                    text: "Любимая команда" 
                }, 
                { 
                    value: "firstName", 
                    text: "Имя" 
                }, 
                { 
                    value: "genderCode", 
                    text: "Пол" 
                }, 
                { 
                    value: "lastName", 
                    text: "Отчество" 
                }, 
                { 
                    value: "levelName", 
                    text: "Название уровня" 
                }, 
                { 
                    value: "middleName", 
                    text: "Фамилия" 
                }, 
                { 
                    value: "mobilePhone", 
                    text: "Номер телефона" 
                }, 
                { 
                    value: "mobilePhoneVerified", 
                    text: "Номер телефона подтвержден" 
                },
                {
                    value: "extendedAttributes",
                    text: "Дополнительная информ..."
                }
            ]
        }
    },
    computed: {
        requirementTexts() {
            return this.requirementsList
                .filter(item => this.achieveData.requirements.includes(item.value))
                .map(item => item.text)
        }
    },
};
</script>
