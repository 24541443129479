<template>
  <div 
    class="loyalty-special__item" 
    :class="{
      'loyalty-special__item--blue': (offer && offer.blue),
      'loyalty-special__item--last': last,
    }"
  >
    <!-- TYPICAL -->
    <template v-if="!last">
      <div class="loyalty-special__item-content">
        <div class="loyalty-special__item-img-wrap">
          <img :src="offer.img" alt="">
        </div>
        <h4 class="loyalty-special__item-title">{{ offer.title }}</h4>
      </div>

      <!-- ads -->
      <div v-if="offer.legal_name" class="loyalty-special__item-ads">
        <p>{{ offer.legal_name }}</p>
        <span>ИНН: {{ offer.inn }}, </span><span>erid: {{ offer.erid }}</span>
      </div>
      
      <a 
        v-if="offer.btn_url"
        class="btn btn_blue" 
        target="_blank"
        :href="offer.btn_url"
      >
        {{ offer.btn_name }}
      </a>

      <button 
        v-else
        class="btn btn_blue" 
        @click="$emit('openPopup', offer)"
        type="button"
      >
        {{ offer.btn_name }}
      </button>
    </template>
    <!-- LAST -->
    <template v-else>
      <router-link to="/loyalty/stock" class="loyalty-special__item-content loyalty-special__item-content--last">
        <span>БОЛЬШЕ</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 16L16 8" stroke="#0959A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 16V8L8 8" stroke="#0959A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </router-link>
    </template>
    
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    }
  },
  props: {
    offer: {
      type: Object,
      required: false,
    },
    last: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['openPopup'],
  computed: {},
  methods: {}
}

</script>
