<template>
  <ul class="header__menu">
    <li class="header__menu-item">
      <a :href="$translation.header.menu.main.link" target="_blank" class="header__menu-link">
        {{ $translation.header.menu.main.name }}
        <span class="header__menu-arrow"></span>
      </a>
    </li>
    <li class="header__menu-item">
      <a :href="$translation.header.menu.tickets.link" target="_blank" class="header__menu-link">
        {{ $translation.header.menu.tickets.name }}
        <span class="header__menu-arrow"></span>
      </a>
    </li>
    <li class="header__menu-item">
      <a :href="$translation.header.menu.shop.link" target="_blank" class="header__menu-link">
        {{ $translation.header.menu.shop.name }}
        <span class="header__menu-arrow"></span>
      </a>
    </li>    
  </ul>
</template>
