import axios from 'axios'

export default {
	namespaced: true,
	state: {
    entities: {}
	},
	mutations: {
		setEntities(state, value) {
      state.entities = value
    }
	},
  actions: {
    getEntities({commit}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/loyalty/entities',
        })
          .then(res => {
            resolve(res)
            commit('setEntities', res.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
