<template>
  <div class="pagination pagination_desktop" :class="modificator">
    <ul 
      v-if="buttons.length"
      class="pagination__btns"
    >
      <li 
        v-for="(item, index) in buttons"
        :key="index"
        class="pagination__btns-item"
      >
        <span
          v-if="item.dots" 
          class="pagination__btns-dots"
        >
          ...
        </span>
        <button
          v-else 
          type="button" 
          class="pagination__btns-btn"
          :class="{
            'pagination__btns-btn_active': item.value === pageDesktop,
            'pagination__btns-btn_far': item.far
          }"
          @click="$emit('changePage', item.value)"
          :disabled="disabled"
        >
          {{ item.value + 1 }}
        </button>
      </li>
    </ul> 
    <div class="pagination__more">
      <span class="pagination__more-label">
        {{ $translation.pagination.showBy }}
      </span>
      <ul class="pagination__more-list">
        <li 
          v-for="item in steps"
          :key="item"
          class="pagination__more-item"
        >
          <button
            type="button" 
            class="pagination__more-btn" 
            :class="{
              'pagination__more-btn_active': stepDesktop === item
            }"
            :disabled="(stepDesktop === item) || disabled"
            @click="$emit('changeStep', item)"
          >
            {{ item }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div 
    v-if="pageMobile + 1 < pageCount"
    class="pagination pagination_mobile"
    :class="modificator"
  >
    <button
      :disabled="disabled" 
      @click="$emit('showMore')"
      class="btn btn_white pagination__btn"
    >
      {{ $translation.pagination.showMore }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: false,
      default: [10, 20, 30, 40]
    },
    stepMobile: {
      type: Number,
      required: true
    },
    pageMobile: {
      type: Number,
      required: true
    },
    stepDesktop: {
      type: Number,
      required: true
    },
    pageDesktop: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    modificator: {
      type: String,
      required: false
    },
    itemsCount: {
      type: Number,
      required: true
    }
  },
  inject: ['windowWidth'],
  emits: ['changeStep', 'changePage', 'showMore'],
  computed: {
    pageCount() {
      if (this.windowWidth >= 960) {
        return Math.ceil(this.itemsCount / this.stepDesktop)
      } else {
        return Math.ceil(this.itemsCount / this.stepMobile)
      }
    },
    buttons() {
      if (this.pageCount <= 5) {
        const btns = []

        for (let i = 0; i < this.pageCount; i++) {
          btns.push({
            value: i
          })
        }

        return btns
      }

      if (this.pageDesktop <= 2) {
        const btns = []

        for (let i = 0; i <= 3; i++) {
          btns.push({
            value: i
          })
        }

        return btns.concat([
          { 
            dots: true
          },
          {
            value: this.pageCount - 1,
            far: true
          }
        ])
      }

      if (this.pageDesktop >= this.pageCount - 3) {
        const btns = [
          {
            value: 0,
            far: true
          },
          {
            dots: true
          }
        ]

        for (let i = 4; i > 0; i--) {
          btns.push({
            value: this.pageCount - i
          })
        }

        return btns
      }

      return [
          {
            value: 0,
            far: true
          },
          {
            dots: true
          },
          {
            value: this.pageDesktop - 1
          },
          {
            value: this.pageDesktop
          },
          {
            value: this.pageDesktop + 1
          },
          {
            dots: true
          },
          {
            value: this.pageCount - 1,
            far: true
          },
      ]
    }
  }
}
</script>

