<template>
  <p class="event__title">{{ event.name }}</p>
  <div class="active-tickets__group">
    <Ticket 
      :tickets="tickets"
      :date="event.date"
    />
  </div> 
</template>

<script>
import Ticket from '@web/components/tickets/ticketsDomDynamo/components/Ticket'

export default {
  components: {
    Ticket,
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    tickets() {
      return this.event.consist.filter(item => item.type === 'gum')
    },
  }
}
</script>

