<template>
    <div class="match">
        <div class="match__row">
            <div
                v-if="!noLogo"
                class="match__logos"
                :class="`match__logos_${size}`"
            >
                <!-- IF MATCH -->
                <template v-if="validLogos.length">
                    <picture
                        v-for="logo in validLogos"
                        :key="logo.url"
                        class="match__logo"
                        :class="`match__logo_${size}`"
                    >
                        <img
                            :src="ticketsHeader && logo.url_alt ? logo.url_alt : logo.url"
                            :alt="logo.caption"
                            :title="logo.caption"
                        />
                    </picture>
                </template>
                <!-- IF GUM -->
                <picture
                    v-else-if="match.consist[0].type === 'gum'"
                    class="match__logo match__logo_gum"
                >
                    <img src="@web/assets/img/tickets/gum.png" alt="" />
                </picture>
                <!-- ELSE -->
                <picture
                    v-else-if="defaultLogo"
                    class="match__logo match__logo_ticket"
                    :class="`match__logo_${size}`"
                >
                    <img
                        v-if="defaultLogo === 'ticket-blue'"
                        src="@web/assets/img/tickets/ticket-blue.png"
                        alt=""
                    />
                    <img
                        v-else-if="defaultLogo === 'ticket-white'"
                        src="@web/assets/img/tickets/ticket-white.png"
                        alt=""
                    />
                </picture>
            </div>
            <div class="match__content">
                <div class="match__title" :class="`match__title_${size}`">
                    {{ match.name }}
                </div>
                <div
                    class="match__text match__text_desktop"
                    :class="`match__text_${size}`"
                >
                    <p>
                        {{ match.date }}
                    </p>
                    <p v-if="match.tournament">
                        {{ match.tournament }}
                    </p>

                    <h4 class="match__vip" v-if="vipStatus === 'gastrobar'">BetBoom GastroBar</h4>
                    <h4 class="match__vip" v-if="vipStatus === 'platinum'">PLATINUM</h4>
                    <h4 class="match__vip" v-if="vipStatus === 'gold'">GOLD</h4>
                    <h4 class="match__vip" v-if="vipStatus === 'vvip'">VVIP Ложи</h4>
                    <h4 class="match__vip" v-if="vipStatus === 'vip'">VIP Ложи</h4>
                </div>
            </div>
        </div>
        <div
            class="match__text match__text_mobile"
            :class="`match__text_${size}`"
        >
            <p>
                {{ match.date }}
            </p>
            <p v-if="match.tournament">
                {{ match.tournament }}
            </p>

            <h4 class="match__vip" v-if="vipStatus === 'gastrobar'">BetBoom GastroBar</h4>
            <h4 class="match__vip" v-if="vipStatus === 'platinum'">PLATINUM</h4>
            <h4 class="match__vip" v-if="vipStatus === 'gold'">GOLD</h4>
            <h4 class="match__vip" v-if="vipStatus === 'vvip'">VVIP Ложи</h4>
            <h4 class="match__vip" v-if="vipStatus === 'vip'">VIP Ложи</h4>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        match: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: "sm",
        },
        defaultLogo: {
            type: String,
            required: false,
        },
        noLogo: {
            type: Boolean,
            required: false,
        },
        ticketsHeader: {
            type: Boolean,
            required: false,
            default: false
        },
        vipStatus: {
            type: String,
            required: false,
            default: false
        },
    },
    computed: {
        validLogos() {
            return this.match.logos.filter((logo) => logo.url);
        },
    },
};
</script>
