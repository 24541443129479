import axios from 'axios'

export default {
  namespaced: true,
	actions: {
    getProfileData({commit}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/profile/getContact`
        })
          .then(res => {
            resolve(res)

            commit('personal/setFirstName', res.data.data.firstName, { root: true })
            commit('personal/setLastName', res.data.data.lastName, { root: true })
            if (res.data.data.middleName) {
              commit('personal/setMiddleName', res.data.data.middleName, { root: true })
            }
            if (res.data.data.birthDate !== null) {
              commit('personal/setBirthDate', res.data.data.birthDate, { root: true })
            }
            commit('personal/setChangeBirthDate', res.data.data.changeBirthDate, { root: true })

            if (res.data.data.genderCode) {
              commit('personal/setGender', res.data.data.genderCode, { root: true })
            }

            if (res.data.data.cityId) {
              commit('personal/setCity', res.data.data.cityId, { root: true })
            }

            if (res.data.data.cities) {
              commit('defaults/setCities', res.data.data.cities, { root: true })
            }

            commit('biometrics/setAgreement', res.data.data.esiaAgreement, { root: true })
            commit('biometrics/setEsiaLinked', res.data.data.esiaLinked, { root: true })
            if (res.data.data.esiaUrl !== undefined) {
              commit('biometrics/setEsiaUrl', res.data.data.esiaUrl, { root: true })
            }

            if (res.data.data.fanId !== undefined) {
              commit('fanID/setFanID', String(res.data.data.fanId), { root: true })
            }

            commit('fanID/setFanIdDate', res.data.data.fanIdDate, { root: true })

            commit('fanID/setUserFromList', res.data.data.userFromList, { root: true })

            if (res.data.data.photo !== undefined) {
              commit('photo/setPhoto', res.data.data.photo, { root: true })
            }

            commit('phone/setPhone', res.data.data.mobilePhone || '', { root: true })
            if (res.data.data.mobilePhone === null || res.data.data.mobilePhone === '') {
              commit('phone/setPhoneVerified', true, { root: true })
            } else {    
              commit('phone/setPhoneVerified', res.data.data.mobilePhoneVerified, { root: true })
            }
            commit('phone/setCountryCode', res.data.data.phoneCountry.toLowerCase(), { root: true })

            commit('email/setEmail', res.data.data.emailAddress, { root: true })
            commit('email/setEmailVerified', res.data.data.emailVerified, { root: true })
            
            commit('notifications/setAllowSms', res.data.data.allowSms, { root: true })
            commit('notifications/setAllowEmail', res.data.data.allowEmail, { root: true })
            commit('notifications/setMailingCategories', res.data.data.mailingCategories, { root: true })
        
            if (res.data.data.startRootingFor !== undefined) {
              commit('additional/setStartCheer', String(res.data.data.startRootingFor), { root: true })
            }
            if (res.data.data.favouriteNumber !== undefined) {
              commit('additional/setFavoriteNumber', String(res.data.data.favouriteNumber), { root: true })
            }
            if (res.data.data.favouritePlayer !== undefined) {
              commit('additional/setFavoriteFootballer', String(res.data.data.favouritePlayer), { root: true })
            }
            if (res.data.data.fanTeam) {
              commit('additional/setFanTeam', String(res.data.data.fanTeam), { root: true })
            }

            commit('defaults/setFavoriteFootballerOptions', res.data.data.favoriteFootballerOptions, { root: true })
            commit('defaults/setTeams', res.data.data.teams, { root: true })
            
            commit('achievements/setSuccessAchievements', res.data.data.achieves.achievements, { root: true })
            commit('achievements/setProgress', res.data.data.achieves.progress, { root: true })
          })
          .catch(error => {
            reject(error)
          })
      })
    }
	}
}
