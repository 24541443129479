<template>
  <div v-if="content" v-html="content">
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: false
    }
  }
}
</script>
