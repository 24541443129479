<template>
  <img v-if="content" :src="content" alt="">
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: false
    }
  },
}
</script>
