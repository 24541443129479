<template>
  <teleport to="#popup">
    <div class="popup__title popup__title_mrg-b-lg">Подтвердите изменения</div>
    <div class="popup__phone-message">
        {{ $translation.profile.phone.popup.message }}<br>
      {{ phone }}
    </div>
    <div class="popup__code">
      <div
        v-for="index in 4"
        :key="index"
        class="popup__code-input"
        :class="{
          error: !validateSymbol(index - 1) && validation
        }"
      >
        <input
          type="text"
          :disabled="inProgress"
          maxlength="1"
          :data-code-input="index - 1"
          @input="onInput(index - 1, $event)"
        >
      </div>
    </div>
    <div
      v-if="confirmFailed"
      class="popup__code-fail"
    >
      {{ errorMessage }} . <button type="button" @click="sendCode">Повторить попытку</button>
    </div>
    <div
      class="popup__code-message"
      :class="{
        active: timer > 0
      }"
    >
        {{ $translation.profile.phone.popup.timer }} ({{ timer }})
    </div>
    <div
      class="popup__code-btn-container"
      :class="{
        active: timer === 0
      }"
    >
      <button
        type="button"
        @click="sendAgain"
        class="popup__code-btn"
      >
          {{ $translation.profile.phone.popup.sendAgainBtn }}
      </button>
    </div>
  </teleport>
</template>

<script>
import { checkStringLength } from '@com/functions/validation.js'

export default {
  props: {
    phone: {
      type: String,
      required: true
    },
    confirmFailed: {
      type: Boolean,
      required: false
    },
    inProgress: {
      type: Boolean,
      required: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: 'Что-то пошло не так'
    },
    timer: {
      type: Number,
      required: true
    }
  },
  emits: ['sendCode', 'sendSms'],
  data() {
    return {
      symbols: ['', '', '', ''],
      sentTimes: 0,
      validation: false
    }
  },
  computed: {
    code() {
      let code = ''
      this.symbols.forEach( symbol => {
        code += String(symbol)
      })
      return code
    },
    isValid() {
      return this.validateSymbol(0) &&
             this.validateSymbol(1) &&
             this.validateSymbol(2) &&
             this.validateSymbol(3)
    }
  },
  methods: {
    validateSymbol(index) {
      return checkStringLength(this.symbols[index], 1, 1)
    },
    sendCode() {
      if (!this.inProgress) {
        if (this.isValid) {
          this.$emit('sendCode', this.code)
          this.validation = false
        } else {
          this.validation = true
        }
      }
    },
    sendAgain() {
      if (this.timer === 0) {
        this.$emit('sendSms')
        this.sentTimes = 0;
      }
    },
    onInput(index, e) {
      const value = e.target.value

      if (value) {
        const reg = new RegExp('^[0-9]+$')

        if (reg.test(value)) { // цифра
          this.symbols[index] = value
          index <= 4 ? this.focusInput(index + 1) : this.focusInput(index)
        } else { // всё остальное
          e.target.value = ''
          this.symbols[index] = ''
        }
      } else { // удаление
        this.symbols[index] = ''
        index > 0 ? this.focusInput(index - 1) : this.focusInput(index)
      }
    },
    focusInput(input) {
      document.querySelector(`[data-code-input="${input}"]`).focus()
    },
  },
  watch: {
    isValid() {
      if (this.isValid && this.sentTimes === 0) {
        this.sendCode()
        this.sentTimes++
      }
    }
  },
  mounted() {
    this.focusInput(0)
  }
}
</script>
