<template>
  <teleport to="#popup">
    <div class="loyalty-popup">
      <swiper
        :simulateTouch="false"
        :modules="modules"
        :slidesPerView="1"
        :slidesPerGroup="1"
        :spaceBetween="15"
        :autoHeight="true"
        :initialSlide="initialSlide"
        :observer="true"
        :navigation="{
          nextEl: '[data-slider-next]',
          prevEl: '[data-slider-prev]'
        }"
        :pagination="{
          el: '[data-slider-pagination]',
          type: 'bullets',
          clickable: true,
          bulletClass: 'loyalty-popup__bullet',
          bulletActiveClass: 'active',
          bulletElement: 'li',
          dynamicBullets: true,
          dynamicMainBullets: 1
        }"
        :breakpoints="{
          1280: {
            spaceBetween: 25,
          }
        }"
        @slideChange="resetСode();"
        class="loyalty-popup__slider"
      >
        <swiper-slide 
          v-for="item in activeItems"
          :key="item.id"
          class="loyalty-popup__slide"
        >
          <picture 
            :class="isPartner ? 'loyalty-popup__logo' : 'loyalty-popup__img'"
          >
            <img 
              :src="item.popup_img" 
              alt=""
            >
          </picture>
          <div class="loyalty-popup__title">
            {{ item.popup_title }}
          </div>
          <div v-if="isPartner" class="loyalty-popup__text" v-html="item.popup_content">
          </div>
          <div v-else class="loyalty-popup__text">
            <WidgetsRenderer 
              :widgets="item.popup_widgets"
            />
          </div>
          <div class="loyalty-popup__ad-info">
            <p v-if="item.legal_name">Реклама. {{ item.legal_name }}, ИНН {{ item.inn }}, erid: {{ item.erid }}</p>
          </div>

          <div class="popup__btns-wrap">
            <!-- for PROMOCODE -->
            <button 
              v-if="item.popup_is_promocode && promocode"
              class="btn btn_large btn_grey loyalty-popup__btn promocode-btn"
              @click="copyText"
            >
              <svg v-show="!isPromocodeCopied" class="btn__icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8421 15H3.26316C2.56842 15 2 14.4273 2 13.7273V4.81818H3.26316V13.7273H10.8421V15ZM12.7368 12.4545H5.78947C5.09474 12.4545 4.52632 11.8818 4.52632 11.1818V2.27273C4.52632 1.57273 5.09474 1 5.78947 1H12.7368C13.4316 1 14 1.57273 14 2.27273V11.1818C14 11.8818 13.4316 12.4545 12.7368 12.4545ZM12.7368 2.27273H5.78947V11.1818H12.7368V2.27273Z" fill="#0959A1"/>
              </svg>
              <span>{{ isPromocodeCopied ? 'Скопировано' : promocode }}</span>
            </button>

            <button 
              v-else-if="item.popup_is_promocode"
              class="btn btn_large btn_grey loyalty-popup__btn promocode-btn"
              @click="handlerPromocodeBtn(item.popup_promocode_type, item.id)"
            >получить промокод
            </button>

            <!-- for URL -->
            <a 
              :href="item.popup_btn_link" 
              target="_blank"
              class="btn btn_large btn_blue loyalty-popup__btn"
            >
              {{ item.popup_btn_text }}
            </a>
          </div>
        </swiper-slide>
      </swiper>
      <button data-slider-prev class="loyalty-popup__arrow loyalty-popup__arrow_prev">
        <svg width="28" height="77" viewBox="0 0 28 77" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 75.5L2 38.5L27 1.5" stroke="white" stroke-width="2"/>
        </svg>
      </button>
      <button data-slider-next class="loyalty-popup__arrow loyalty-popup__arrow_next">
        <svg width="28" height="77" viewBox="0 0 28 77" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 75.5L26 38.5L1 1.5" stroke="white" stroke-width="2"/>
        </svg>
      </button>
      <ul class="loyalty-popup__pagination" data-slider-pagination></ul>
    </div>
  </teleport>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Navigation, Pagination } from 'swiper';
import WidgetsRenderer from '@web/components/loyalty/popup/widgets/WidgetsRenderer.vue'
import 'swiper/swiper-bundle.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    WidgetsRenderer
  },
  props: {
    items: {
      type: Array,
      required: false
    },
    initialItem: {
      type: Object,
      required: false
    },
    isPartner: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      modules: [Navigation, Pagination],
      isPromocodeCopied: false,
      resetPromo: false,
    }
  },
  computed: {
    ...mapState({
      promocode: state => state.loyaltyPartners.promocode,
    }),
    activeItems() {
      return this.items.filter(item => item.is_active)
    },
    initialSlide() {
      return this.activeItems.findIndex(item => item === this.initialItem)
    }
  },
  methods: {
    ...mapActions({
      getPromocode: 'loyaltyPartners/getPromocode',
    }),
    ...mapMutations({
      resetPromocode: 'loyaltyPartners/setPromocode',
    }),

    handlerPromocodeBtn(type, id) {
      this.getPromocode({popup_promocode_type: type, partner_id: id})
    },

    copyText() {
        navigator.clipboard.writeText(this.promocode)
        this.isPromocodeCopied = true
        setTimeout(() => {
          this.isPromocodeCopied = false
        }, 3000);
    },
    resetСode() {
      this.resetPromo = true;
      this.resetPromocode('');
    },
  },
  watch: {
    promocode(newValue) {
      if (!newValue && !this.resetPromo) {
        alert('Возникла техническая ошибка, напишите на почту loyalty@fcdynamo.ru');
      }
      this.resetPromo = false;
    }
  }
}
</script>

