import { declOfNum } from '@web/functions/functions.js'

export default {
  computed: {
    orderItems() {
      return [].concat(...this.events.map(event => event.consist.map(item => {
          item.type = item.type === 'general' ? event.type : item.type
          return item
        })
      ))
    },
    ticketsCount() {
      return this.orderItems.filter(item => item.type === 'ticket').length
    },
    parkingCount() {
      return this.orderItems.filter(item => item.type === 'parking').length
    },
    subscriptionsCount() {
      return this.orderItems.filter(item => item.type === 'season_ticket').length
    },
    restaurantCount() {
      return this.orderItems.filter(item => item.type === 'restaurant').length
    },
    ticketsDomDynamoCount() {
      return this.orderItems.filter(item => item.type === 'gum').length
    },
    orderList() {
      let value = ''

      const items = [
        {
          count: this.ticketsCount,
          name:  this.$translation.tickets.tickets.titleDeclension
        },
        {
          count: this.parkingCount,
          name: this.$translation.tickets.parking.titleDeclension
        },
        {
          count: this.subscriptionsCount,
          name: this.$translation.tickets.subscriptions.titleDeclension
        },
        {
          count: this.restaurantCount,
          name: this.$translation.tickets.restaurant.titleDeclension
        },
        {
          count: this.ticketsDomDynamoCount,
          name: this.$translation.tickets.tickets.titleDeclension
        },
      ]

      const notEmptyItems = items.filter(item => item.count)
  
      notEmptyItems.forEach((item, i) => {
        value += item.count >= 1 ? `${item.count} ` : ''
        value += this.declOfNum(item.count, item.name)
        
        if (i < notEmptyItems.length - 1) {
          value += ' + '
        }
      })
  
      return value
    }
  },
  methods: {
    declOfNum
  }
}
