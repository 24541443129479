<template>
  <nav class="header__nav">
    <ul 
      class="header__nav-list"
    >
      <li 
        v-for="item in items"
        :key="item.link"
        class="header__nav-item"
      >
        <router-link 
          :to="item.link"
          class="header__nav-link" 
          @click="$emit('changePage')"
        >
          <Component 
            :is="item.icon"
            class="header__nav-img"
          />
          <span class="header__nav-text" v-html="item.text"></span> 
        </router-link>
      </li>
      <li class="header__nav-item">
        <a href="#" class="header__nav-link" @click="onWebExit">
          <IconExit class="header__nav-img" />
          <span class="header__nav-text" v-html="$translation.header.nav.exit"></span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapActions } from 'vuex'

import IconTickets from '@com/components/header/icons/IconTickets'
import IconOrders from '@com/components/header/icons/IconOrders'
import IconSettings from '@com/components/header/icons/IconSettings'
import IconLoyalty from '@com/components/header/icons/IconLoyalty'
import IconExit from '@com/components/header/icons/IconExit'

export default {
  emits: ['changePage'],
  components: {
    IconTickets,
    IconOrders,
    IconSettings,
    IconExit,
    IconLoyalty
  },
  data() {
    return {
      items: [
        {
          link: '/tickets',
          icon: 'IconTickets',
          text: this.$translation.header.nav.tickets
        },
        {
          link: '/orders',
          icon: 'IconOrders',
          text: this.$translation.header.nav.orders
        },
        {
          link: '/loyalty',
          icon: 'IconLoyalty',
          text: this.$translation.header.nav.loyalty
        },
        {
          link: '/profile',
          icon: 'IconSettings',
          text: this.$translation.header.nav.settings
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      webDeAuth: 'token/webDeAuth',
      webManzanaDeAuth: 'token/webManzanaDeAuth',
      webLogout: 'token/webLogout',
      authorizeRedirect: 'token/authorizeRedirect'
    }),
    onWebExit() {
      this.webDeAuth()
        .then(res => {
          this.webManzanaDeAuth({url: res.data.data.link})
            .finally(()=> {
              this.webLogout()
              this.authorizeRedirect()
            })
        })
    }
  }
}
</script>
