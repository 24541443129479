<template>
  <div class="orders-popup__total-row">
    <div class="orders-popup__total-left" >
      {{ name }}
    </div>
    <div class="orders-popup__total-right">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
}
</script>
