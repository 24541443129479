import axios from 'axios'

export default {
  namespaced: true,
  state: {
    tickets: [],
    ticketsCount: 0,
  },
  mutations: {
    setTickets(state, tickets) {
      state.tickets = tickets
    },
    setTicketsCount(state, count) {
      state.ticketsCount = count
    }
  },
  actions: {
    getArchiveTickets({commit}, {offset, step, type = ''}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: 'api/ticket/history',
          params: {
            offset,
            step,
            eventConsistType: type
          }
        })
          .then(res => {
            resolve(res.data.data.orders)
            commit('setTicketsCount', res.data.data.count)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    pay({}, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `api/ticket/pay/${id}`
        })
          .then(res => {
            resolve()
            window.open(res.data.data.paymentUrl)
          })
          .catch(() => {
            reject()
          })
      })
    },
    cancel({}, order) {
      return axios({
        method: 'delete',
        url: `api/ticket/${order.id}/cancel`
      })
    },
    return({}, order) {
      return axios({
        method: 'delete',
        url: `api/ticket/${order.id}/refund`,
        data: {
          ticketIds: order.ticketIds
        }
      })
    }
  }
}
