import axios from 'axios'

export default {
	namespaced: true,
  state: {
    partners: [],
    pageCount: 1,
    promocode: ''
  },
  mutations: {
		setPartners(state, value) {
      state.partners = value
    },
    addPartners(state, value) {
      state.partners = state.partners.concat(value)
    },
    setPageCount(state, value) {
      state.pageCount = value
    },
    setPromocode(state, value) {
      state.promocode = value
    },
	},
  actions: {
    getPartners({commit}, {page}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/loyalty/partners?page=${page}`
        })
          .then(res => {
            commit('setPageCount', res.data.data.pageCount)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getPromocode( {commit}, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/api/loyalty/personalPromocodes/',
          data: data
        })
          .then(res => {
            commit('setPromocode', res.data.data.promocode.text)
            resolve(res)
          })
          .catch( error => {
            reject(error)
          })
      })
		},
    
  }
}
