import axios from 'axios'

export default {
	namespaced: true,
	state: {
		fanID: '',
		fanIdDate: '',
		userFromList: ''
	},
	mutations: {
		setFanID(state, value) {
			state.fanID = value
		},
		setFanIdDate(state, value) {
			state.fanIdDate = value
		},
		setUserFromList(state, value) {
			state.userFromList = value
		}
	},
	actions: {
		updateFanID({commit, state}, {fanID}) {
			return new Promise((resolve, reject) => {
				const data = {
					fanId: fanID
				}

				if (!state.fanIdDate) {
					data.fanIdProfile = true
				}

				axios({
					method: 'post',
					url: `/api/profile/updateExtendedAttributes`,
					data
				})
					.then( res => {
						resolve(res)

						if (!state.fanIdDate) {
							dataLayer.push({'event': 'add_faniddata_elk_success'})
						}

						commit('setFanID', res.data.data.fanId)
						commit('setFanIdDate', res.data.data.fanIdDate)
					})
					.catch( error => {
						reject(error)
					})
			})	
		}
	}

}
