import { createStore } from 'vuex'

import Defaults from './modules/Defaults'
import Token from './modules/Token'
import Footer from './modules/Footer'
import Pages from './modules/Pages'
import Popup from '@com/store/modules/Popup'
import Banner from './modules/Banner'

//PROFILE
import Profile from './modules/profile/Profile'
import Personal from './modules/profile/Personal'
import Password from './modules/profile/Password'
import Phone from './modules/profile/Phone'
import Email from './modules/profile/Email'
import Notifications from './modules/profile/Notifications'
import Additional from './modules/profile/Additional'
import Biometrics from './modules/profile/Biometrics'
import FanID from './modules/profile/FanID'
import Photo from './modules/profile/Photo'
import Achievements from './modules/profile/Achievements'

// ORDERS
import Orders from './modules/orders/Orders'
import ActiveOrders from './modules/orders/ActiveOrders'
import ArchiveOrders from './modules/orders/ArchiveOrders'

// LOYALTY
import Loyalty from './modules/loyalty/Loyalty'
import LoyaltyCommon from './modules/loyalty/LoyaltyCommon'
import LoyaltyStock from './modules/loyalty/LoyaltyStock'
import LoyaltyRaffles from './modules/loyalty/LoyaltyRaffles'
import LoyaltyPartners from './modules/loyalty/LoyaltyPartners'

// TICKETS
import ArchiveTickets from './modules/tickets/ArchiveTickets'
import Tickets from './modules/tickets/Tickets'
import GlobalOffers from './modules/tickets/GlobalOffers'

// FAQ
import FAQ from './modules/faq/faq'

// Promocodes
import Promocodes from './modules/promocodes/promocodes'

export default createStore({
  modules: {
    defaults: Defaults,
    personal: Personal,
    password: Password,
    phone: Phone,
    email: Email,
    fanID: FanID,
    notifications: Notifications,
    additional: Additional,
    biometrics: Biometrics,
    photo: Photo,
    token: Token,
    profile: Profile,
    footer: Footer,
    pages: Pages,
    popup: Popup,
    activeOrders: ActiveOrders,
    archiveOrders: ArchiveOrders,
    orders: Orders,
    banner: Banner,
    loyalty: Loyalty,
    loyaltyCommon: LoyaltyCommon,
    loyaltyStock: LoyaltyStock,
    loyaltyRaffles: LoyaltyRaffles,
    loyaltyPartners: LoyaltyPartners,
    archiveTickets: ArchiveTickets,
    tickets: Tickets,
    globalOffers: GlobalOffers,
    faq: FAQ,
    achievements: Achievements,
    promocodes: Promocodes,
  }
})
