<template>
  <Datepicker 
    :modelValue="dateFormatted"
    @update:modelValue="value => $emit('update:modelValue', value ? value.toLocaleDateString() : '')"
    :textInput="true"
    format="dd.MM.yyyy"
    :locale="$locale"
    :selectText="$translation.forms.datePicker.selectText"
    :cancelText="$translation.forms.datePicker.cancelText"
    :nowButtonLabel="$translation.forms.datePicker.nowButtonLabel"
    :autoApply="true"
    :minDate="minDate"
    :maxDate="maxDate"
    :enableTimePicker="false"
    :yearRange="[minDate.getFullYear(), maxDate.getFullYear()]"
    :hideInputIcon="true"
    :clearable="true"
    :disabled="disabled"
  >
    <template #dp-input="{}">
      <input 
        type="text" 
        class="input" 
        v-bind="$attrs" 
        :value="modelValue" 
        @input="$emit('update:modelValue', $event.target.value)" 
        :placeholder="$translation.forms.datePicker.placeholder" 
        :disabled="disabled"
        ref="dateInput" 
      />
    </template>
  </Datepicker>
</template>

<script>
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

import Inputmask from 'inputmask'

export default {
  inheritAttrs: false,
  components: {
    Datepicker,
  },
  props: {
    modelValue: {
      type: String,
      required: false
    },
    minDate: {
      type: Object,
      required: false
    },
    maxDate: {
      type: Object,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    dateFormatted() {
      if (/^[0-3][0-9][.][0-1][0-9][.]\d{4}$/.test(this.modelValue)) {
        const splitedDate = this.modelValue.split('.')
        return new Date(`${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`)
      } else {
        return null
      }
    }
  },
  mounted() {
    const im = new Inputmask('**.**.****')
    im.definitions['9'] = false
    im.definitions['*'] = '[0-9]'
    im.mask(this.$refs.dateInput)
  }
}
</script>
