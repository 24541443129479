import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { UNAUTHORIZED_CODE } from '@com/constants/responseCodes.js'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import './assets/scss/template.scss'

import ru from '@web/lang/ru/ru.js'
import en from '@web/lang/en/en.js'

import detectBrowser from "vue-detect-browser"

const app = createApp(App)

app.config.unwrapInjectedRef = true

const locale = document.body.dataset.locale

app.config.globalProperties.$locale = locale || 'ru'
app.config.globalProperties.$translation = locale === 'en' ? en : ru
axios.defaults.baseURL = locale === 'en' ? '/en' : ''

store.commit('token/setRedirectUrl', document.body.dataset.redirectUrl)

app.use(store).use(router).use(detectBrowser)

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
})

axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const currentUrl = window.location.href;
  // автоматическое отображение ошибки для get запроса
  if (error.response.config.method === 'get' && !currentUrl.includes('/tickets') && !currentUrl.includes('/orders')) {
    store.dispatch('popup/openAlert', error.response.data.message || '')
  }

  if (error.response.status === UNAUTHORIZED_CODE) {
    store.dispatch('token/webLogout', null, { root: true })
    store.dispatch('token/authorizeRedirect', null, { root: true })
  }

  return Promise.reject(error)
});

Sentry.init({
  app,
  dsn: "https://8db46297485741c99488a940930b11fa@glitchtip.fcdm.ru/5",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["my.fcdm.ru ", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.mount('#app')
