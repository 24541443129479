import axios from 'axios'

export default {
	namespaced: true,
	state: {
    phone: '',
		phoneVerified: true,
		countryCode: ''
	},
	mutations: {
		setPhone(state, value) {
			state.phone = value
		},
		setPhoneVerified(state, value) {
			state.phoneVerified= value
		},
		setCountryCode(state, value) {
			state.countryCode = value
		}
	},
	actions: {
		changeMobilePhone({commit}, {phone}) {
			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/api/profile/changeMobilePhone`,
					data: {
						mobilePhone: phone
					}
				})
					.then( res => {
						resolve(res)

						commit('setPhone', phone || '')
						commit('setPhoneVerified', false)
					})
					.catch( error => {
						reject(error)
					})
			}) 
		},
		sendSmsForMobilePhoneVerification({}) {
			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/api/profile/sendSmsForMobilePhoneVerification`
				})
					.then( res => {
						resolve(res)
					})
					.catch( error => {
						reject(error)
					})
			})
		},
		verifyMobilePhone({commit}, {code}) {
			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/api/profile/verifyMobilePhone`,
					data: {
						code: code
					}
				})
					.then( res => {
						resolve(res)

						commit('setPhone', res.data.data.mobilePhone || '')
						commit('setPhoneVerified', res.data.data.mobilePhoneVerified)
					})
					.catch( error => {
						reject(error)
					})
			})
		},
	}

}
