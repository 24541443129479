<template>
  <MatchInfo
    class="active-tickets__match"
    size="md"
    :match="match" 
  />
  <div class="active-tickets__group">
    <Ticket 
      :tickets="tickets"
      :fanIdRequired="match.fanIdRequired"
    />
    <Parking 
      v-for="parking in parkings"
      :key="parking.id"
      :parking="parking"
    />
  </div> 
</template>

<script>
import Ticket from '@web/components/tickets/tickets/components/Ticket'
import Parking from '@web/components/tickets/tickets/components/Parking'
import MatchInfo from '@web/components/tickets/matchInfo/MatchInfo.vue'

export default {
  components: {
    Ticket,
    Parking,
    MatchInfo
  },
  props: {
    match: {
      type: Object,
      required: true
    }
  },
  computed: {
    tickets() {
      return this.match.consist.filter(item => item.type === 'general')
    },
    parkings() {
      return this.match.consist.filter(item => item.type === 'parking')
    }
  }
}
</script>

