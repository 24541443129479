import axios from "axios";

export default {
    namespaced: true,
    state: {
        achievements: [],
        successAchievements: [],
        progress: [],
    },
    mutations: {
        setAchievements(state, value) {
            state.achievements = value;
        },
        setSuccessAchievements(state, value) {
            state.successAchievements = value;
        },
        setProgress(state, value) {
            state.progress = value;
        },
    },
    actions: {
        getAchievements({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "get",
                    url: "/api/profile/achievements",
                })
                    .then((res) => {
                        resolve(res);
                        commit("setAchievements", res.data.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
};
