import axios from "axios";

export default {
    namespaced: true,
    state: {
        orders: [],
        cancel: false,
    },
    mutations: {
        setOrders(state, orders) {
            state.orders = orders;
        },
        setCancel(state, cancel) {
            state.cancel = cancel;
        },
    },
    actions: {
        getActiveOrders({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "get",
                    url: `/api/profile/activeOrders`,
                })
                    .then((res) => {
                        resolve(res);
                        commit("setOrders", res.data.data.orders);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        updateCancel({ commit }, cancel) {
            commit("setCancel", cancel);
        },
    },
    getters: {
        cancel: (state) => state.cancel,
    },
};
