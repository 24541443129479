<template>
  <div class="vh-100">
    <Preloader
      v-if="isPending"
      :text="$translation.confirmEmail.preloaderText"
      class="preloader_large"
    />
    <Alert
      name="confirmEmail" 
      :is-slot="true"
    >
      <div class="popup__title popup__title_small">
        {{ popupText }}
      </div>
      <br>
      <div class="center underline pointer" @click="closePopup">
        {{ $translation.confirmEmail.closePopupText }}
      </div>
    </Alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Preloader from '@com/components/preloader/Preloader'
import Alert from '@com/components/popup/components/Alert'
import popupMixin from '@com/mixins/popup'

export default {
  mixins: [popupMixin],
  components: {
    Preloader,
    Alert
  },
  data() {
    return {
      isPending: true,
      popupText: ''
    }
  },
  methods: {
    ...mapActions({
      confirmEmailAddress: 'email/confirmEmailAddress'
    })
  },
  mounted() {
    const token = this.$route.query.token

    if (token) {
      this.confirmEmailAddress({
        token: token
      })
        .then( res => {
          this.popupText = res.data.message || this.$translation.confirmEmail.emailSuccess
        })
        .catch( error => {
          this.popupText = error.response.data.message || this.$translation.confirmEmail.failedToken
        })
        .finally(()=> {
          this.isPending = false
          this.openPopup({
            name: 'confirmEmail',
            contentClasses: ['popup__content_small popup__content_center'],
            onClose: () => {
              this.$router.push({
                name: 'home'
              })
            }
          })
        })
    } else {
      this.$router.push({
        name: 'home'
      })
    }

  }
}
</script>
