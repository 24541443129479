<template>
  <div class="orders-popup__order">
    <template v-if="list.composition?.length">
      <div class="orders-popup__order-title">
        {{ $translation.orders.popup.orderList }}
      </div>
      <Card 
        v-for="(item, index) in list.composition"
        :key="index"
        :order="item"
      />
    </template>
    <template v-if="list.returned?.length">
      <div class="orders-popup__order-item-return">
        {{ $translation.orders.popup.returned }}
      </div> 
      <Card 
        v-for="(item, index) in list.returned"
        :key="index"
        :order="item"
        class="orders-popup__order-item_return"
      />
    </template>
  </div>
</template>

<script>
import Card from '@web/components/orders/popup/components/composition/components/Card'

export default {
  components: {
    Card
  },
  props: {
    list: {
      type: Object,
      required: true
    }
  }
}
</script>
