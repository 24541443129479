<template>
  <div class="heading">
    <h3 class="h3 heading__title">
      {{ title }}
    </h3>
    <span class="heading__amount">
      {{ amount }}
    </span> 
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    amount: {
      type: String,
      required: false
    }
  }
}
</script>
