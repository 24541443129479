<template>
  <div class="orders-table__row">
    <div class="orders-table__column-left">
      {{ name }}
    </div>
    <div 
      class="orders-table__column-right"
      :class="valueClass"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      value: {
        type: String,
        required: true
      },
      valueClass: {
        type: String,
        required: false
      }
    }
  }
</script>
