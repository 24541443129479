import axios from 'axios'

export default {
	namespaced: true,
	state: {
		photo: ''
	},
	mutations: {
		setPhoto(state, value) {
			state.photo = value
		}
	},
	actions: {
		updatePhoto({commit}, {photo}) {
			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/api/profile/updatePhoto`,
					data: {
						photo
					}
				})
					.then( res => {
						resolve(res)

						commit('setPhoto', res.data.data.photo || photo)
					})
					.catch( error => {
						reject(error)
					})
			})	
		},
    uploadPhoto({}, imgData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/api/profile/uploadPhoto',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: imgData
        })
          .then( res => {
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
		}
	}

}
