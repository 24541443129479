<template>
  <div class="archive-card orders-archive__card">
      <div class="archive-card__row">
        <div class="archive-card__item archive-card__item_date">
          {{ order.orderedDate }}
        </div>
        <div class="archive-card__item archive-card__item_number">
          №{{ order.orderNumber }}
        </div>
        <div class="archive-card__item archive-card__item_amount">
          {{ order.productsCount }} {{ declOfNum(order.productsCount, $translation.orders.archive.card.product) }}
        </div>
        <div 
          class="archive-card__item archive-card__item_status archive-card__item_opacity"
          :class="{
            'archive-card__item_canceled': order.statusId === 'C' 
          }"
        >
          {{ order.statusTitle }}
        </div>
        <div class="archive-card__item archive-card__item_points">
          <!-- +{{ order.points }} {{ declOfNum(order.points,  $translation.orders.archive.card.points) }} -->
        </div>
        <div class="archive-card__item archive-card__item_price">
          {{ order.price }} ₽
        </div>
        <div class="archive-card__item archive-card__item_btns">
          <button 
            type="button" 
            class="btn btn_white archive-card__item-details" 
            :disabled="isOrderPending"
            @click="$emit('openPopup')"
          >
           {{ $translation.orders.archive.card.details }}
          </button>
        </div>
      </div>
  </div>
</template>

<script>
import { declOfNum } from '@web/functions/functions.js'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    isOrderPending: {
      type: Boolean,
      required: true
    }
  },
  emits: ['openPopup'],
  methods: {
    declOfNum,
  }
}
</script>
