import axios from 'axios'

export default {
	namespaced: true,
	state: {
    common: {
      number: '',
      name: '',
      phone: '',
      countryCode: 'ru',
      email: '',
      level_number: 1,
      level_end_date: "", // дата завершения уровня
      level_sum_for_change: "0", // сколько осталось накопить
      level_sum_for_keep: "0", // сколько осталось чтобы удержать уровень
      level_total_by_level: "0", // сколько накопил за нахождение на уровне
    }
	},
  getters: {
    points_left(state, getters) {
      return getters.is_level_final ? Math.round(+state.common.level_sum_for_keep) : Math.round(+state.common.level_sum_for_change)
    },
    points_end(state, getters) {
      return +getters.points_left + Math.round(+state.common.level_total_by_level)
    },
    is_level_final(state) {
      return state.common.level_number === 2
    },
    is_level_confirmed(state, getters) {
      return getters.is_level_final && getters.points_end === Math.round(+state.common.level_total_by_level)
    },
    percentageAccumulation(state, getters) {
      return Math.round(+state.common.level_total_by_level) / getters.points_end * 100 + '%'
    }
  },
	mutations: {
		setCommon(state, value) {
      state.common = value
    }
	},
  actions: {
    getCommon({commit}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: '/api/loyalty/profile',
        })
          .then(res => {
            resolve(res)
            commit('setCommon', res.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    sendQuestion({}, {name, call_type, question, phone, email, files}) {
			return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('type', call_type);
        formData.append('question', question);
        formData.append('phone', phone);
        formData.append('email', email);

        files.forEach(file => {
          formData.append('files[]', file)
        })

				axios({
					method: 'post',
					url: `/api/loyalty/form`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
					data: formData
				})
					.then( res => {
						resolve(res)
					})
					.catch( error => {
						reject(error)
					})
			})	
		}
  }
}
