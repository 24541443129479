<template>
  <Preloader
    v-if="isPending"
    :text="$translation.preloader.loading"
    class="preloader_large"
  />
  <div 
  v-else
  class="settings page"
  >
    <div class="container">
      <h1
        class="h1 settings__h1"
        :class="{
          'settings__h1_fixed': pageYOffset > 0
        }"
      >
        {{ $translation.profile.h1 }}
      </h1>
      <Achievements 
        v-if="isShowAchievements" 
        @anchor="scrollTo" 
        @hide-achievements="hideAchievements" 
      />

      <Personal id="personalData" class="profile__scroll-to-block" />
      <Contact id="contactData" class="profile__scroll-to-block" />
      <FanId id="fanId" class="profile__scroll-to-block" />

      <Password />
      <Notifications id="notifications" />
      <Additional id="additionalData" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Achievements from '@web/components/profile/achieves/Achievements'
import Personal from '@web/components/profile/Personal'
import Password from '@web/components/profile/Password'
import Contact from '@web/components/profile/contact/Contact'
import FanId from '@web/components/profile/FanId'
import Notifications from '@web/components/profile/Notifications'
import Additional from '@web/components/profile/Additional'
// import Biometrics from '@web/components/profile/biometrics/Biometrics'
import Preloader from '@com/components/preloader/Preloader'

export default {
  name: 'Settings',
  components: {
    Preloader,
    Personal,
    Password,
    Contact,
    Notifications,
    Additional,
    Achievements,
    // Biometrics,
    FanId,
  },
  data() {
    return {
      pageYOffset: 0,
      isPending: true,
      isShowAchievements: true,
    }
  },
  computed: {
    ...mapState({
      progress: (state) => state.achievements.progress,
    }),
  },
  methods: {
    ...mapActions({
      updateVip: 'email/updateVip',
      getProfileData: 'profile/getProfileData',
    }),
    handleScroll() {
      this.pageYOffset = window.pageYOffset
    },
    scrollTo(anchor) {
      document.querySelector(`#${anchor}`).scrollIntoView({ behavior: 'smooth' });
    },
    hideAchievements() {
      localStorage.setItem('hideAchievements', true)
      this.isShowAchievements = false
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    if (localStorage.hideAchievements) {
      this.isShowAchievements = false
    }
    this.getProfileData()
      .then(() => {
        this.updateVip({
          email: this.$store.state.email.email
        })
      })
      .finally(() => {
        this.isPending = false
      })
  },
  watch: {
    progress() {
      if (this.progress < 100) {
        localStorage.removeItem('hideAchievements')
        this.isShowAchievements = true
      }
    } 
  }
 }
</script>
