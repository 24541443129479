<template>
  <div class="subscribe-block">
    <div class="subscribe-block__content">
      <h3>Оформи подписку на новости чтобы не пропустить начало продаж. </h3>
      <form action="" class="subscribe-block__content-form">
        <input type="text" placeholder="Введите ваш e-mail">
        <button type="submit">Оформить подписку</button>
      </form>
      <p class="subscribe-block__content-note">Нажимая на кнопку, ты соглашаешься с <a href="#">правилами обработки персональных данных</a> и получение рекламно-информационных рассылок.</p>
    </div>
    <div class="subscribe-block__img">
      <img src="./cover.png" alt="">
    </div>
  </div>
</template>

<script>


export default {
  components: {},
}
</script>

