<template>
    <form
        class="relative"
        @submit.prevent="onSubmit"
        @reset.prevent="onReset"
    >
        <FormItem
            :isError="(!emailVerified && !isChanged) || (!isValid && validation)"
            :label="label"
        >
            <template v-slot:header>
                <span
                    v-if="!emailVerified && !isChanged"
                    class="form-item__message form-item__message_error form-item__message_clickable form-item__message_desk"
                    @click="onSubmit"
                >
                    {{ $translation.profile.email.repeatSend }}
                </span>
            </template>
            <EmailInput
                v-model.trim="email"
                :disabled="saveInProgress || $store.state.email.isVip"
            />
            <template v-slot:footer>
                <div
                    v-if="!emailVerified && !isChanged"
                    class="form-item__message form-item__message_error form-item__message_clickable form-item__message_mobile"
                    @click="onSubmit"
                >
                    {{ $translation.profile.email.repeatSend }}
                </div>
            </template>
        </FormItem>
        <transition name="fade">
            <div
                v-show="isChanged"
                class="settings__btns settings__btns_narrow"
            >
                <button
                    type="submit"
                    class="btn btn_blue"
                    :disabled="(!isValid && validation) || saveInProgress"
                >
                    {{ $translation.btn.save }}
                </button>
                <button
                    type="reset"
                    class="btn btn_white"
                    :disabled="saveInProgress"
                >
                    {{ $translation.btn.cancel }}
                </button>
                <div class="settings__btns-text">
                    <p>
                        {{ $translation.profile.email.text }}
                    </p>
                </div>
            </div>
        </transition>
        <Preloader
            v-show="saveInProgress"
            :text="$translation.preloader.save"
            class="preloader_form"
        />
    </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EmailInput from '@com/components/forms/EmailInput'
import Preloader from '@com/components/preloader/Preloader'
import FormItem from '@com/components/forms/FormItem'
import { checkEmail } from '@com/functions/validation.js'
import popupMixin from '@com/mixins/popup'
import store from '../../../../../store'

export default {
    mixins: [popupMixin],
    components: {
        EmailInput,
        Preloader,
        FormItem
    },
    emits: ['saved'],
    data() {
        return {
            email: '',
            saveInProgress: false,
            validation: false,
        }
    },
    computed: {
        ...mapState({
            emailStore: state => state.email.email,
            emailVerified: state => state.email.emailVerified
        }),
        isChanged() {
            return this.email !== this.emailStore
        },
        isValid() {
            return checkEmail(this.email)
        },
        label() {
            if (!this.isValid && this.validation) {
                return this.$translation.profile.email.error
            } else if (!this.emailVerified && !this.isChanged) {
                return this.$translation.profile.email.unconfirmed
            } else {
                return this.$translation.profile.email.label
            }
        }
    },
    methods: {
        ...mapActions({
            updateEmail: 'email/updateEmail',
        }),
        onReset() {
            this.validation = false
            this.reset()
        },
        reset() {
            this.email = this.emailStore
        },
        onSubmit() {
            if (!this.saveInProgress) {
                if (this.isValid) {
                    this.saveInProgress = true

                    this.updateEmail({
                        email: this.email
                    })
                        .then(() => {
                            this.openAlert(this.$translation.profile.email.popup.text)
                        })
                        .catch(error => {
                            this.$emit('saved', 'error', error.response.data.message || this.$translation.message.unknownError)
                        })
                        .finally(() => {
                            this.saveInProgress = false
                            this.validation = false
                        })

                } else {
                    this.validation = true
                }
            }
        }
    },
    mounted() {
        this.reset()
    }
}
</script>
