import axios from 'axios'

export default {
	namespaced: true,
  state: {
    raffles: [],
    pageCount: 1
  },
  mutations: {
		setRaffles(state, value) {
      state.raffles = value
    },
    addRaffles(state, value) {
      state.raffles = state.raffles.concat(value)
    },
    setPageCount(state, value) {
      state.pageCount = value
    }
	},
  actions: {
    getRaffles({commit}, {page, sortRow, sort}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/loyalty/events/raffle?page=${page}&sortRow=${sortRow}&sort=${sort}`
        })
          .then(res => {
            commit('setPageCount', res.data.data.pageCount)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  }
}
