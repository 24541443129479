import axios from 'axios'

export default {
  namespaced: true,
	state: {
    startCheer: '',
    favoriteNumber: '',
    favoriteFootballer: '',
		fanTeam: ''
	},
	mutations: {
    setStartCheer(state, value) {
			state.startCheer = value
		},
		setFavoriteNumber(state, value) {
			state.favoriteNumber = value
		},
		setFavoriteFootballer(state, value) {
			state.favoriteFootballer = value
		},
		setFanTeam(state, value) {
			state.fanTeam = value
		}
	},
	actions: {
		updateAdditional({commit, state}, payload) {
			return new Promise((resolve, reject) => {
				for (let key in payload) {
					if (!payload[key]) {
						delete payload[key]
					}	
				}
				
				axios({
					method: 'post',
					url: `/api/profile/updateExtendedAttributes`,
					data: payload
				})
					.then(res => {
						resolve(res)

						commit('setStartCheer', res.data.data.startRootingFor || state.startCheer)
						commit('setFavoriteNumber', res.data.data.favouriteNumber || state.favoriteNumber)
						commit('setFavoriteFootballer', res.data.data.favouritePlayer || state.favoriteFootballer)
						commit('setFanTeam', res.data.data.fanTeam || state.fanTeam)
					})
					.catch( error => {
						reject(error)
					})
			})
		}
	}

}
