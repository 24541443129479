<template>
  <div 
    class="loyalty-common-block loyalty-faq" 
    :class="{
      '_no-shadow': detectBrowser.isSafari
    }">
    <div class="loyalty-faq__title">
      {{ $translation.loyalty.common.faq.title }}
    </div>
    <div class="loyalty-accordion">
      <!-- item -->
      <div 
        v-for="(item, index) in items"
        :key="index"
        class="loyalty-accordion__item" 
        :class="{
          'loyalty-accordion__item_blue': item.isForm
        }"
      >
        <!-- header -->
        <div class="loyalty-accordion__header" @click="toggle($event, item)">
          {{ item.question[$locale] }}
          <button 
            class="loyalty-accordion__header-btn"
            :class="{
              active: item.isActive,
              'loyalty-accordion__header-btn_blue': !item.isForm,
              'loyalty-accordion__header-btn_white': item.isForm
            }"
          >
            <svg viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="white" stroke-width="1.5"/>
            </svg>
          </button>
        </div>
        <!-- transition for body -->
        <transition
          name="accordion"
          @enter="start"
          @after-enter="end"
          @before-leave="start"
          @after-leave="end"
        >
          <!-- body -->
          <div 
            v-show="item.isActive"
            class="loyalty-accordion__body"
          >
            <div 
              v-if="!item.isForm"
              class="loyalty-accordion__content"
              v-html="item.answer[$locale]"
            >
            </div>
            <Form :user-data="userData" v-else />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import slide from '@web/mixins/slide'
import Form from './components/Form'

export default {
  props: {
    55: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
      default: [],
    },
  },
  mixins: [slide],
  components: {
    Form,
  },
  data() {
    return {
      items: [],
    }
  },
  methods: {
    ...mapActions({
      getQuestions: 'faq/getQuestions',
    }),
    toggle($event, currentItem) {
      if (!$event.target.closest('[data-faq-form]') && !$event.target.closest('[data-country-list]') && !$event.target.closest('a')) {
        this.items.forEach(item => item.isActive = item === currentItem ? !currentItem.isActive : false)
      }
    },
  },
  // created() {
  //   this.items = this.questions.map(item => {
  //     return Object.assign(item, {
  //       isForm: false,
  //       isActive: false,
  //     })
  //   }).concat({
  //     question: this.$translation.loyalty.common.faq.form.title,
  //     answer: '',
  //     isForm: true,
  //     isActive: false
  //   })
  // },
  watch: {
    questions() {
      this.items = this.questions.map(item => {
      return Object.assign(item, {
        isForm: false,
        isActive: false,
      })
      }).concat({
        question: this.$translation.loyalty.common.faq.form.title,
        answer: '',
        isForm: true,
        isActive: false
      })
    }
  }
}
</script>
