<template>
    <form 
        class="loyalty-accordion__form"
        data-faq-form
        @submit.prevent="onSubmit"
    >
        <div class="loyalty-accordion__form-column">
        <FormItem
            class="loyalty-accordion__form-item"
            :isError="!isNameValid && validation"
            :errorMessage="$translation.loyalty.common.faq.form.error.name"
        >
            <TextInput 
            :placeholder="$translation.loyalty.common.faq.form.placeholder.name"
            class="input_large"
            v-model="name"
            :disabled="isPending"
            />
        </FormItem>
        <FormItem
            class="loyalty-accordion__form-item"
            :isError="!isPhoneValid && validation"
            :errorMessage="$translation.loyalty.common.faq.form.error.phone"
        >
            <PhoneInput 
            class="input_large"
            v-model="phone"
            :disabled="isPending"
            :countryCode="countryCode"
            @change-country="onChangeCountry"
            @change-mask="onChangeMask"
            />
        </FormItem>
        <FormItem
            class="loyalty-accordion__form-item"
            :isError="!isEmailValid && validation"
            :errorMessage="$translation.loyalty.common.faq.form.error.email"
        >
            <TextInput 
            :placeholder="$translation.loyalty.common.faq.form.placeholder.email"
            class="input_large"
            v-model="email"
            :disabled="isPending"
            />
        </FormItem>
        <FormItem
            class="loyalty-accordion__form-item"
            :isError="!isTypeValid && validation"
            :errorMessage="$translation.loyalty.common.faq.form.error.type"
        >
            <Select 
            :placeholder="{
                text: $translation.loyalty.common.faq.form.placeholder.type,
                value: '',
            }"
            :options="callTypes"
            class="select_large"
            v-model="call_type"
            :disabled="isPending"
            />
        </FormItem>
        </div>
        <div class="loyalty-accordion__form-column">
        <FormItem
            class="loyalty-accordion__form-item"
            :isError="!isQuestionValid && validation"
            :errorMessage="$translation.loyalty.common.faq.form.error.question"
        >
            <Textarea 
            class="textarea_large"
            :placeholder="$translation.loyalty.common.faq.form.placeholder.question"
            v-model="question"
            :disabled="isPending"
            />
        </FormItem>
        <FormItem
            class="loyalty-accordion__form-item loyalty-accordion__form-item--flex"
            :isError="!isFilesSizeValid && validation"
            :errorMessage="$translation.loyalty.common.faq.form.error.filesSize"
        >
            <FileInput
                @upload-files="onFilesLoad"
                :label="$translation.forms.fileInput.attachFile"
                :disabled="isPending || files.length >= 10"
                :totalFilesSize="totalFilesSize"
                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.mov,.mp4,.wmv,.avi"
                multiple
                ref="fileInput"
            />
            <div class="loyalty-accordion__form-note"
                >
                <span :class="{
                    'loyalty-accordion__form-note--error': !isFilesCountValid
                }">Вы можете добавить не более 10 файлов.</span>
                <br/>
                <span :class="{
                    'loyalty-accordion__form-note--error': !isFilesSizeValid
                }">Максимальный общий размер файлов: 10 МБ.</span>
            </div>
            <!-- files -->
            <div class="file-input__uploaded-wrap">
                <span 
                    v-for="(file, index) in files" 
                    :key="index"
                    @click.prevent=""
                    :class="{
                        'file-input__uploaded-item--disabled': isPending,
                        'file-input__uploaded-item--oversize': !isFilesSizeValid || !isFilesCountValid,
                    }"
                    class="file-input__uploaded-item" 
                >
                    <span 
                        class="file-input__uploaded-item__name" 
                    >{{ file.name }}</span>
                    <span 
                        class="file-input__uploaded-close"
                        @click.prevent="onRemoveUploaded(file, index)"
                        :class="{
                            'file-input__uploaded-close--oversize': !isFilesSizeValid || !isFilesCountValid,
                        }"
                    >
                    </span>
                </span>
            </div>
        </FormItem>
        <button 
            type="submit" 
            class="btn btn_white btn_large loyalty-accordion__form-btn"
            :disabled="isPending || !isFilesSizeValid || !isFilesCountValid"
        >
            {{ $translation.btn.submit }}
        </button>
        <div class="loyalty-accordion__form-agreement" v-html="$translation.loyalty.common.faq.form.agreement"></div>
        </div>
    </form>
    <!-- popup -->
    <Popup
        v-if="popupName === 'loyaltyForm'" 
    />
</template>
  
<script>
import {mapState, mapActions } from 'vuex'

import Popup from './Popup'
import TextInput from '@com/components/forms/TextInput'
import Select from '@com/components/forms/Select'
import Textarea from '@com/components/forms/Textarea'
import FormItem from '@com/components/forms/FormItem'
import PhoneInput from '@com/components/forms/PhoneInput'
import FileInput from '@com/components/forms/FileInput'

import { checkStringFilled, checkName, checkNumbersEqual, checkPhone, checkEmail } from '@com/functions/validation.js'
import popupMixin from '@com/mixins/popup'

export default {
    props: {},
    mixins: [popupMixin],
    components: {
        Popup,
        TextInput,
        Select,
        Textarea,
        FormItem,
        PhoneInput,
        FileInput
    },
    data() {
        return {
            name: '',
            phone: '',
            call_type: '',
            email: '',
            question: '',
            isPending: false,
            countryCode: 'ru',
            validation: false,
            mask: '',
            files: [],
            maxFileSize: 1024 * 1024 * 10,
        }
    },
    mounted() {
        this.name = this.common.name
        this.phone = this.common.phone
        this.countryCode = this.common.countryCode.toLowerCase()
        this.email = this.common.email
    },
    computed: {
        ...mapState({
            common: state => state.loyaltyCommon.common
        }),
        totalFilesSize() {
            return this.files.reduce((acc, item) => acc + item.size, 0)
        },
        callTypes() {
            return Object.keys(this.$translation.loyalty.common.faq.form.callTypes).map(key => {
                return {
                    text: this.$translation.loyalty.common.faq.form.callTypes[key],
                    value: key
                }
            })
        },
        isFilesSizeValid() {
            return this.totalFilesSize <= this.maxFileSize;
        },
        isFilesCountValid() {
            return !(this.files.length > 10);
        },
        isNameValid() {
            return checkStringFilled(this.name) && checkName(this.name)
        },
        isPhoneValid() {
            if (checkStringFilled(this.mask)) {
                return checkNumbersEqual(this.phone.replace(/[^\d]/g, '').length, this.mask.replace(/[^\\.]/g, '').length)
            } else {
                return checkPhone(this.phone)
            }
        },
        isTypeValid() {
            return checkStringFilled(this.call_type)
        },
        isEmailValid() {
            return checkEmail(this.email)
        },
        isQuestionValid() {
            return this.question.length > 10
        },
        isValid() {
            return this.isNameValid && this.isPhoneValid && this.isTypeValid && this.isEmailValid && this.isQuestionValid && this.isFilesSizeValid
        }
    },
    methods: {
        ...mapActions({
            getCommon: 'loyaltyCommon/getCommon',
            sendQuestion: 'loyaltyCommon/sendQuestion',
        }),
        onFilesLoad(files) {
            for (let i = 0; i < files.length; i++) {
                this.files.push(files[i]);
            }

            const originNames = []
            const originFiles = []

            this.files.forEach((file) => {
                if (originNames.indexOf(file.name) === -1) { 
                    originNames.push(file.name)
                    originFiles.push(file)
                } 
            })

            this.files = originFiles
        },
        onSubmit() {
            if (!this.isPending) {
                if (this.isValid) {
                    this.isPending = true

                    this.sendQuestion({
                        name: this.name,
                        call_type: this.call_type,
                        question: this.question,
                        phone: this.phone,
                        email: this.email,
                        files: this.files
                    })
                    .then(() => {
                        this.reset()
                        this.validation = false

                        this.openPopup({
                        name: 'loyaltyForm',
                        contentClasses: ['popup__content_loyalty-form'],
                        btnClasses: ['popup__close_grey'],
                        popupClasses: ['popup_grey']
                        })
                    })
                    .catch(err => {
                        this.openAlert(err.response.data.message || '')
                    })
                    .finally(() => {
                        this.isPending = false
                    })
                } else {
                    this.validation = true
                }
            }
        },
        reset() {
            this.name = this.common.name
            this.call_type = ''
            this.question = ''
            this.phone = this.common.phone
            this.email = this.common.email
            this.countryCode = this.common.countryCode.toLowerCase()
            this.files = []
        },
        onChangeCountry(code) {
            this.countryCode = code
            this.phone = ''
        },
        onChangeMask(mask) {
            this.mask = mask
        },
        onRemoveUploaded(file, index) {
            this.files.splice(index,1);
            this.$refs.fileInput.clearFilesList();
        },
    },
}
</script>
  