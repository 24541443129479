<template>
  <teleport to="#popup">
    <div class="cancel-ticket">
      <div class="cancel-ticket__title" v-html="title">
      </div>
      <div 
        v-if="this.action === 'return'"
        class="cancel-ticket__text"
      >
        {{ text }}
      </div>
      <button 
        v-if="!this.isCanceled"
        class="btn btn_white cancel-ticket__btn"
        :disabled="isPending"
        @click="$emit('cancel')"
      >
        {{ btn }}
      </button> 
      <a 
        v-else
        :href="$translation.header.menu.tickets.link"
        target="_blank"
        class="btn btn_white cancel-ticket__btn"
      >
        {{ btn }}
      </a> 
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true
    },
    isCanceled: {
      type: Boolean,
      required: true
    },
    isPending: {
      type: Boolean,
      required: true
    }
  },
  emits: ['cancel'],
  computed: {
    title() {
      return this.$translation.tickets.story.popup.title[this.action][this.isCanceled ? 'isCanceled' : 'isNotCanceled']
    },
    text() {
      return this.$translation.tickets.story.popup.text[this.isCanceled ? 'isCanceled' : 'isNotCanceled']
    },
    btn() {
      return this.$translation.tickets.story.popup.btn[this.isCanceled ? 'isCanceled' : this.action]
    }
  }
}
</script>
