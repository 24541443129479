<template>
  <!-- если кнопка одна, то вешаем story-card--1-btn, если 2, то story-card--2-btn -->
  <div
    class="tickets-story__item story-card"
    :class="`story-card--${btnsCount}-btn`"
    @click="onClick"
  >
    <div class="story-card__header">
      <div class="story-card__header-item story-card__header-item_date">
        {{ order.date }}
      </div>
      <div class="story-card__header-item story-card__header-item_number">
        №{{ order.id }}
      </div>
      <div class="story-card__header-item story-card__header-item_amount">
        {{ orderList }}
      </div>
      <div class="story-card__header-item story-card__header-item_price">
        {{ order.price }} ₽
      </div>
      <div
        class="story-card__header-item story-card__header-item_status"
        :class="{
          'story-card__header-item_paid': order.statusId === 'P',
          'story-card__header-item_canceled': order.statusId === 'C',
          'story-card__header-item_expects': order.statusId === 'E',
          'story-card__header-item_updated': order.statusId === 'U',
        }"
      >
        {{ $translation.tickets.story.statusTitle[order.statusId] }}
      </div>

      <!-- если кнопки есть -->
      <div
        v-if="btnsCount"
        class="story-card__btns"
      >
        <template v-if="order.statusId === 'E'">
          <button
            @click="onOpenPopup('cancel')"
            class="btn btn_white story-card__btn story-card__btn--cancel"
            :disabled="isPending"
            data-btn
          >
            {{ $translation.tickets.story.cancel }}
          </button>
          <button
            class="btn btn_blue story-card__btn story-card__btn--pay"
            :disabled="isPending"
            data-btn
            @click="onPay"
          >
            {{ $translation.tickets.story.pay }}
          </button>
        </template>
        <button
          v-if="order.statusId === 'P' || order.statusId === 'U'"
          @click="onOpenPopup('return')"
          class="btn btn_white story-card__btn story-card__btn--cancel"
          :disabled="isPending"
          data-btn
        >
          {{ $translation.tickets.story.return }}
        </button>
        <button
          class="story-card__arrow story-card__arrow--btns"
          :class="{
            active: isActive
          }"
        >
        </button>
      </div>
      <!-- если кнопок нету -->
      <button
        v-else
        class="story-card__arrow story-card__arrow--fixed"
        :class="{
          active: isActive
        }"
      >
      </button>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="isActive"
        class="story-card__body"
      >
        <div class="story-card__body-inner">
          <div
            v-for="event in order.events"
            :key="event.id"
            class="story-card__body-item"
          >
            <div class="story-card__body-left">
              <MatchInfo
                :no-logo="type === 'gum'"
                :match="event"
                default-logo="ticket-blue"
              />
            </div>
            <div class="story-card__body-right">
              <div class="story-card__body-table">
                <div
                  v-for="item in event.consist"
                  :key="item.id"
                  class="story-card__body-table-row"
                >
                  <div class="story-card__body-table-left">
                    {{ item.type === 'general' ? $translation.tickets.orderTypes[event.type] : item.value}}
                  </div>
                  <div class="story-card__body-table-right">
                    <template v-if="typeof item.value === 'object'">
                      <span class="story-card__body-table-separator">
                        {{ $translation.tickets.sector }}
                        {{ item.value.sector }} {{ item.value.restaurant ? ' ' + item.value.restaurant : '' }}
                      </span>
                      <span class="story-card__body-table-separator">
                        {{ $translation.tickets.row }}
                        {{ item.value.row }}
                      </span>
                      <span class="story-card__body-table-separator">
                        {{ $translation.tickets.place }}
                        {{ item.value.place }}
                      </span>
                    </template>
                    <template v-else>
                      {{ event.consist.length }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <Popup
    v-if="popupName === `returnTicket${order.id}`"
    :action="cancelAction"
    :is-canceled="isCanceled"
    :is-pending="isPending"
    @cancel="onCancel"
  />
</template>

<script>
import { mapActions } from 'vuex'
import orderList from '@web/mixins/orderList'
import slide from '@web/mixins/slide'
import Popup from './Popup.vue'
import MatchInfo from '@web/components/tickets/matchInfo/MatchInfo.vue'
import popupMixin from '@com/mixins/popup'

export default {
  mixins: [orderList, slide, popupMixin],
  components: {
    Popup,
    MatchInfo
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true,
    }
  },
  emits: ['cancel'],
  data() {
    return {
      isActive: false,
      isPending: false,
      cancelAction: '',
      isCanceled: false,
    }
  },
  computed: {
    btnsCount() {
      switch (this.order.statusId) {
        case 'E': // ОТМЕНИТЬ И ОПЛАТИТЬ
          return 2
        case 'P': // ВЕРНУТЬ
          return this.order.btnAction ? 1 : 0
        case 'U': // ВЕРНУТЬ
          return this.order.btnAction ? 1 : 0
        case 'C':
          return 0
      }
    },
    events() { // используется для миксина orderList
      return JSON.parse(JSON.stringify(this.order.events))
    }
  },
  methods: {
    ...mapActions({
      pay: 'archiveTickets/pay',
      cancel: 'archiveTickets/cancel',
      return: 'archiveTickets/return'
    }),
    onClick(e) {
      if (!e.target.closest('[data-btn]') && this.order.events.length) {
        this.isActive = !this.isActive
      }
    },

    onOpenPopup(action) {
      this.cancelAction = action

      this.openPopup({
        name: `returnTicket${this.order.id}`,
        contentClasses: ['popup__content_cancel-ticket popup__content_bdrs-20'],
        popupClasses: ['popup_grey']
      })
    },
    onCancel() {
      const query = this.cancelAction === 'cancel' ? this.cancel : this.return

      this.isPending = true

      query({id: this.order.id, ticketIds: this.order.ticketIds})
        .then(() => {
          this.isCanceled = true
          this.$emit('cancel')
        })
        .finally(() => {
          this.isPending = false
        })
    },
    onPay() {
      this.isPending = true

      this.pay(this.order.id)
        .finally(() => {
          this.isPending = false
        })
    }
  }
}
</script>
