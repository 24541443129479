<template>
    <swiper
        :enabled="false"
        :direction="'vertical'"
        :slidesPerView="'auto'"
        :spaceBetween="20"
        :mousewheel="true"
        :modules='modules'
        :breakpoints="{
            720: {
                direction: 'horizontal',
                enabled: true,
                spaceBetween: 20,
                slidesPerView: 'auto',
            },
            960: {
                direction: 'horizontal',
                enabled: true,
                spaceBetween: 30,
                slidesPerView: 'auto',
            },
        }"
        class="event-cards__swiper"
    >
        <!-- Matches -->
        <swiper-slide
            class="event-cards__swiper-slide"
            v-for="(match, idx) in matches"
            :key="idx"
        >
            <div class="event-card">
                <!-- top -->
                <div class="event-card__top">
                    <!-- info -->
                    <div class="event-card__info">
                        <div class="event-card__info-team">
                            <img :src="match.ownerTeam" alt="" />
                        </div>
                        <div class="event-card__info-wrap">
                            <div class="event-card__info-date">
                                {{ match.gameDate }}
                            </div>
                            <div class="event-card__info-time">
                                {{ match.gameTime }}
                            </div>
                        </div>
                        <div class="event-card__info-team">
                            <img :src="match.guestTeam" alt="" />
                        </div>
                    </div>
                    <!-- title -->
                    <h4 class="event-card__top-title">
                        {{ match.label }}, {{ match.stage }}
                    </h4>
                </div>
                <!-- bottom -->
                <div class="event-card__bottom">
                    <a
                        :href="match.buy.link"
                        target="_blank"
                        class="btn event-card__btn"
                    >
                        {{ match.buttons[0].name }}
                    </a>
                    <a
                        :href="match.buy.link"
                        target="_blank"
                        class="btn event-card__btn event-card__btn--gold"
                    >
                        {{ match.buttons[1].name }}
                    </a>
                </div>
            </div>
        </swiper-slide>

        <!-- Season Tickets -->
        <swiper-slide
            class="event-cards__swiper-slide"
            v-for="(seasonTicket, idx) in seasonTickets"
            :key="idx"
        >
            <div class="event-card">
                <!-- top -->
                <div class="event-card__top">
                    <img
                        src="@web/assets/img/tickets/seasonTickets.png"
                        class="event-card__cover"
                    />
                </div>
                <!-- bottom -->
                <div class="event-card__bottom">
                    <div class="event-card__bottom-title-wrap">
                        <h3 class="event-card__bottom-title">
                            {{ seasonTicket.label }}
                        </h3>
                    </div>
                    <a
                        :href="seasonTicket.buy.link"
                        class="btn event-card__btn"
                    >
                        {{ seasonTicket.buy.label }}
                    </a>
                </div>
            </div>
        </swiper-slide>

        <!-- Museum -->
        <swiper-slide
            class="event-cards__swiper-slide"
            v-for="(item, idx) in museum"
            :key="idx"
        >
            <div class="event-card">
                <!-- top -->
                <div class="event-card__top">
                    <img 
                        :src="item.logo" 
                        class="event-card__cover" 
                    />
                </div>
                <!-- bottom -->
                <div class="event-card__bottom">
                    <div class="event-card__bottom-title-wrap">
                        <h3 class="event-card__bottom-title">
                            {{ item.label }}
                        </h3>
                    </div>
                    <a :href="item.buy.link" target="_blank" class="btn event-card__btn">
                        {{ item.buy.label }}
                    </a>
                </div>
            </div>
        </swiper-slide>

        <!-- Dynamo House -->
        <swiper-slide
            class="event-cards__swiper-slide"
            v-for="(item, idx) in dynamoHouse"
            :key="idx"
        >
            <div class="event-card">
                <!-- top -->
                <div class="event-card__top">
                    <img 
                        :src="item.logo" 
                        class="event-card__cover" 
                    />
                </div>
                <!-- bottom -->
                <div class="event-card__bottom">
                    <div class="event-card__bottom-title-wrap">
                        <h3 class="event-card__bottom-title">
                            {{ item.label.length > 47 ? `${item.label.slice(0, 47)}...` : item.label }}
                        </h3>
                    </div>
                    <a :href="item.buy.link" target="_blank" class="btn event-card__btn">
                        {{ item.buy.label }}
                    </a>
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
import { mapGetters } from "vuex";

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Mousewheel } from 'swiper';
import "swiper/swiper-bundle.css";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {},
    data() {
        return {
            modules: [Mousewheel]
        }
    },
    computed: {
        ...mapGetters({
            matches: "globalOffers/tickets",
            seasonTickets: "globalOffers/seasonTickets",
            museum: "globalOffers/museum",
            dynamoHouse: "globalOffers/dynamoHouse",
        }),
    },
};
</script>
