<template>
  <section class="settings__section settings__section_personal">
    <transition name="fade">
      <span 
        v-show="showMessage"
        class="settings__save-message settings__save-message_personal"
        :class="{
          error: status === 'error',
          success: status === 'success'
        }"
      >
        {{ message }}
      </span>
    </transition>
    <form
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
      class="relative"
    >
      <div class="settings__row">
        <div class="settings__column">
          <FormItem
            :isError="!isLastNameValid && validation"
            :label="$translation.profile.personal.label.lastName"
            :errorMessage="$translation.profile.personal.error.lastName"
          >
            <TextInput 
              v-model.trim="lastName"
              :disabled="saveInProgress || $store.state.email.isVip"
              id="lastNameInput"
            />
          </FormItem>
        </div>
        <div class="settings__column">
          <FormItem
            :isError="!isFirstNameValid && validation"
            :label="$translation.profile.personal.label.firstName"
            :errorMessage="$translation.profile.personal.error.firstName"
          >
            <TextInput 
              v-model.trim="firstName"  
              :disabled="saveInProgress || $store.state.email.isVip"
              id="firstNameInput"
            />
          </FormItem>
        </div>
      </div>
      <div class="settings__row ">
        <div class="settings__column">
          <FormItem
            :isError="!isMiddleNameValid && validation"
            :label="$translation.profile.personal.label.middleName"
            :errorMessage="$translation.profile.personal.error.middleName"
          >
            <TextInput 
              v-model.trim="middleName" 
              :disabled="saveInProgress || $store.state.email.isVip"
              id="middleNameInput"
            />
          </FormItem>
        </div>
        <div class="settings__column">
          <FormItem
            :isError="!isBirthDateValid && validation"
            :label="$translation.profile.personal.label.birthDate"
            :errorMessage="$translation.profile.personal.error.birthDate"
          >
            <template v-slot:header>
              <span 
                v-if="!changeBirthDate"
                class="form-item__info"
              >
                <span class="form-item__info-icon">
                </span>
                <span class="form-item__info-message">
                  <span class="form-item__info-text" v-html="$translation.profile.personal.changeBirthText">
                  </span>
                </span>
              </span>
            </template>
            <DatePicker 
              v-model="birthDate" 
              :minDate="minDate"
              :maxDate="maxDate"
              :disabled="saveInProgress || !changeBirthDate || $store.state.email.isVip"
              id="birthDateInput"
            />
          </FormItem>
        </div>
      </div>
      <div class="settings__row settings__row_no-mrg">
        <div class="settings__column">
          <FormItem
            :label="$translation.profile.personal.label.gender"
          >
            <div class="settings__radio">
              <Radio
                name="genderCode"
                :value="1"
                v-model.number="genderCode"
                :disabled="saveInProgress || $store.state.email.isVip"
              >
                {{ $translation.profile.personal.label.man }}
              </Radio>
              <Radio
                name="genderCode"
                :value="2"
                v-model.number="genderCode"
                :disabled="saveInProgress || $store.state.email.isVip"
              >
                {{ $translation.profile.personal.label.woman }}
              </Radio>
            </div>
          </FormItem>
        </div>
        <div class="settings__column">
          <FormItem
            :isError="!isCityValid && validation"
            :label="$translation.profile.personal.label.city"
            :errorMessage="$translation.profile.personal.error.city"
          >
            <Select
              v-model="cityId"
              :disabled="saveInProgress || $store.state.email.isVip"
              :options="cities"
              :placeholder="{
                value: '',
                text: $translation.profile.personal.error.city
              }"
              id="citySelect"
            />
          </FormItem> 
        </div>
      </div>
      <transition name="fade">
        <div 
          v-show="isChanged"
          class="settings__btns"
        >
          <button
            type="submit"
            class="btn btn_blue"
            :disabled="(!isValid && validation) || saveInProgress"
          >
            {{ $translation.btn.save }}
          </button>
          <button
            type="reset"
            class="btn btn_white"
            :disabled="saveInProgress"
          >
            {{ $translation.btn.cancel }}
          </button>
        </div>
      </transition>
      <Preloader
        v-show="saveInProgress"
        :text="$translation.preloader.save"
        class="preloader_form"
      />
    </form>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import TextInput from '@com/components/forms/TextInput'
import Preloader from '@com/components/preloader/Preloader'
import DatePicker from '@com/components/forms/DatePicker'
import FormItem from '@com/components/forms/FormItem'
import Select from '@com/components/forms/Select'
import Radio from '@com/components/forms/Radio'

import { checkStringFilled, checkStringEmpty, checkName, checkDate } from '@com/functions/validation.js'

export default {
  components: {
    TextInput,
    Preloader,
    DatePicker,
    FormItem,
    Select,
    Radio
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      middleName: '',
      birthDate: '',
      maxDate: new Date(),
      minDate: new Date('1879-01-01'),
      cityId: '',
      genderCode: null,
      saveInProgress: false,
      validation: false,
      status: '',
      message: '',
      showMessage: false
    }
  },
  computed: {
    ...mapState({
      firstNameStore: state => state.personal.firstName,
      lastNameStore: state => state.personal.lastName,
      middleNameStore: state => state.personal.middleName,
      birthDateStore: state => state.personal.birthDate,
      changeBirthDate: state => state.personal.changeBirthDate,
      cityStore: state => state.personal.cityId,
      cities: state => state.defaults.cities,
      genderStore: state => state.personal.genderCode,
    }),
    isChanged() {
      return this.firstName !== this.firstNameStore || 
             this.lastName !== this.lastNameStore || 
             this.middleName !== this.middleNameStore ||
             this.birthDate !== this.birthDateStore ||
             this.cityId !== this.cityStore ||
             this.genderCode !== this.genderStore
    },
    isLastNameValid() {
      return checkStringFilled(this.lastName) && checkName(this.lastName)
    },
    isFirstNameValid() {
      return checkStringFilled(this.firstName) && checkName(this.firstName)
    },
    isMiddleNameValid() {
      return checkStringEmpty(this.middleName) || checkName(this.middleName)
    },
    isBirthDateValid() {
      return checkDate(this.birthDate, this.minDate,  this.maxDate)
    },
    isCityValid() {
      return checkStringFilled(this.cityId)
    },
    isValid() {
      return true
      this.isLastNameValid && 
      this.isFirstNameValid && 
      this.isMiddleNameValid && 
      this.isBirthDateValid &&
      this.isCityValid
    }
  },
  methods: {
    ...mapActions({
      updatePersonal: 'personal/updatePersonal',
      getProfileData: 'profile/getProfileData',
    }),
    onReset() {
      this.validation = false
      this.reset()
    },
    reset() {
      this.firstName = this.firstNameStore
      this.lastName = this.lastNameStore
      this.middleName = this.middleNameStore
      this.birthDate = this.birthDateStore
      this.cityId = this.cityStore
      this.genderCode = this.genderStore
    },
    onSubmit() {
      if ((!this.saveInProgress) && this.isChanged) {
        
        if (this.isValid) {
          this.saveInProgress = true
          
          this.updatePersonal({
            lastName: this.lastName,
            firstName: this.firstName,
            middleName: this.middleName,
            birthDate: this.birthDate !== this.birthDateStore ? this.birthDate : null,
            cityId: this.cityId,
            genderCode: this.genderCode
          })
            .then( res => {
              this.reset()
              this.message = res.data.message || this.$translation.message.changeSuccess
              this.status = 'success'
              this.getProfileData()
            })
            .catch( error => {
              this.message =  error.response.data.message || this.$translation.message.unknownError
              this.status = 'error'
            })
            .finally(()=> {
              this.saveInProgress = false
              this.validation = false
              this.showMessage = true
              setTimeout(() => this.showMessage = false, 3000)
            })
          
        } else {
          this.validation = true
        }
      }
    }
  },
  mounted() {
    this.reset()
  }
}
</script>
