<template>
  <div 
    class="loyalty-card loyalty__card"
    :class="{
      'loyalty-card_disabled ': !item.is_active,
      'loyalty-card_partner': isPartner
    }"
  >
    <picture 
      class=""
      :class="{
        'loyalty-card__img': !isPartner,
        'loyalty-card__logo': isPartner
      }"
    >
      <img 
        :src="item.img" 
        alt=""
      >
    </picture>
    <div 
      class="loyalty-card__title"
      :class="{
        'loyalty-card__title_partner': isPartner
      }"
    >
      {{ item.title }}
    </div>
    <div 
      class="loyalty-card__text"
      v-html="item.text"
    >
    </div>
    <div class="loyalty-card__ad-info">
      <p v-if="item.legal_name">Реклама. {{ item.legal_name }}<br/>ИНН {{ item.inn }}, erid: {{ item.erid }}</p>
    </div>
    <time 
      v-if="!isPartner && item.date"
      class="loyalty-card__date"
    >
      {{ item.date }}
    </time>
    <a 
      v-if="item.btn_url"
      @click="resetPromocode('')"
      :href="item.btn_url" 
      target="_blank"
      class="btn btn_blue btn_large loyalty-card__btn"
      :class="{
        'loyalty-card__btn_partner': isPartner
      }"
    >
      {{ item.btn_name || $translation.btn.detailed }}
    </a>
    <button 
      v-else
      @click="cardBtn()"
      type="button"
      class="btn btn_blue btn_large loyalty-card__btn"
      :class="{
        'loyalty-card__btn_partner': isPartner
      }"
    >
      {{ item.btn_name || $translation.btn.detailed }}
    </button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    isPartner: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    ...mapMutations({
      resetPromocode: 'loyaltyPartners/setPromocode',
    }),
    cardBtn() {
      this.resetPromocode('');
      this.$emit('openPopup')
    }
  },
  emits: ['openPopup']
}
</script>
