import axios from 'axios'

export default {
	namespaced: true,
	state: {
    orders: [],
    ordersCount: 0,
	},
	mutations: {
    setOrders(state, orders) {
      state.orders = orders
    },
    setOrdersCount(state, count) {
      state.ordersCount = count
    }
	},
  actions: {
    getArchiveOrders({commit}, {offset, step}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/profile/archiveOrders`,
          params: {
            offset,
            step
          }
        })
          .then(res => {
            resolve(res.data.data.orders)
            commit('setOrdersCount', res.data.data.count)
          })
          .catch( error => {
            reject(error)
          })
      })
    }
  }
}
