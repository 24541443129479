<template>
  <div 
    v-if="matches.length"
    class="active-tickets"
  >
    <div class="container">
      <Heading
        class="heading_tickets"
        :title="$translation.tickets.tickets.paid" 
        :amount="orderList"
      />
      <Match
        v-for="match in matches" 
        :key="match.id"
        :match="match"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Match from '@web/components/tickets/tickets/components/Match'
import Heading from '@web/components/heading/Heading'
import orderList from '@web/mixins/orderList'

export default {
  mixins: [orderList],
  components: {
    Match,
    Heading
  },
  computed: {
    ...mapGetters({
      matches: 'tickets/tickets'
    }),
    events() { // используется для миксина orderList
      return JSON.parse(JSON.stringify(this.matches))
    }
  }
}
</script>
