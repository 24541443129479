<template>
  <transition name="fade-two-sided">
    <button 
      v-show="isActive"
      type="button"
      class="up-btn"
      @click="onClick"
    >
      <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38 22L20 4L2 22" stroke="#0959A1" stroke-width="4.5"/>
      </svg>
    </button>
  </transition>
</template>

<script>
export default {
  props: {
    section: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    sectionEl() {
      return document.querySelector(this.section)
    }
  },
  methods: {
    onClick() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    checkPosition() {
      this.isActive = this.sectionEl.getBoundingClientRect().bottom <= 0
    }
  },
  created() {
    this.checkPosition()
    window.addEventListener('scroll', this.checkPosition);
    window.addEventListener('resize', this.checkPosition);
  },
  unmounted() {
    window.removeEventListener('scroll', this.checkPosition);
    window.removeEventListener('resize', this.checkPosition);
  }
}
</script>
