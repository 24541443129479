import axios from "axios";

export default {
    namespaced: true,
    state: {
        tickets: [],
    },
    getters: {
        tickets(state) {
            return state.tickets.filter((ticket) => ticket.type === "ticket" && ticket.consist[0].type === "general");
        },
        ticketsDomDynamo(state) {
            return state.tickets.filter((ticket) => ticket.type === "ticket" && ticket.consist[0].type === "gum");
        },
        subscriptions(state) {
            return state.tickets.filter((ticket) => ticket.type === "season_ticket");
        },
        nearestMatchOrEvent(state) {
            return state.tickets.find((ticket) => ticket.afterDays >= 0);
        },
    },
    mutations: {
        setTickets(state, tickets) {
            state.tickets = tickets;
        },
    },
    actions: {
        getTickets({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: "get",
                    url: "api/ticket",
                })
                    .then((res) => {
                        resolve();
                        commit("setTickets", res.data.data.tickets);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
};
