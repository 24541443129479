<template>
  <section class="settings__section settings__additional">
    <form
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <h2 class="h2 settings__h2 settings__h2_flex">
        {{ $translation.profile.additional.h2 }}
        <transition name="fade">
          <span 
            v-show="showMessage"
            class="settings__save-message"
            :class="{
              error: status === 'error',
              success: status === 'success'
            }"
          >
            {{ message }}
          </span>
        </transition>
      </h2>
      <div class="settings__row">
        <div class="settings__column">
          <div class="relative">
            <Preloader
              v-show="saveInProgress"
              :text="$translation.preloader.save"
              class="preloader_form"
            />
            <FormItem
              class="settings__additional-item"
              :isError="!isStartCheerValid && validation"
              :label="$translation.profile.additional.label.startCheer"
              :errorMessage="$translation.profile.additional.error.startCheer"
            >
              <DatePicker 
                v-model="startCheer"
                :disabled="saveInProgress || $store.state.email.isVip"
                :minDate="minDate"
                :maxDate="maxDate"
              />
            </FormItem>
            <FormItem
              class="settings__additional-item"
              :isError="!isFavoriteNumberValid && validation"
              :label="$translation.profile.additional.label.favoriteNumber"
              :errorMessage="$translation.profile.additional.error.favoriteNumber"
            >
              <NumberInput 
                v-model.trim="favoriteNumber"
                :disabled="saveInProgress || $store.state.email.isVip"
                step="1"
                min="1"
                max="99"
              />
            </FormItem>
            <FormItem
              class="settings__additional-item"
              :label="$translation.profile.additional.label.favoriteFootballer"
            >
              <Select
                v-model="favoriteFootballer"
                :disabled="saveInProgress || $store.state.email.isVip"
                :options="favoriteFootballerOptions"
                :placeholder="{
                  value: '',
                  text: '—'
                }"
              />
            </FormItem>
            <FormItem
              :label="$translation.profile.additional.label.supportOtherTeam"
            >
              <Select
                v-model="fanTeam"
                :disabled="saveInProgress || $store.state.email.isVip"
                :options="teams"
                :placeholder="{
                  value: '',
                  text: '—'
                }"
              />
            </FormItem>
            <transition name="fade">
              <div 
                v-show="isChanged"
                class="settings__btns settings__btns_narrow"
              >
                <button
                  type="submit"
                  class="btn btn_blue"
                  :disabled="(!isValid && validation) || saveInProgress"
                >
                  {{ $translation.btn.save }}
                </button>
                <button
                  type="reset"
                  class="btn btn_white"
                  :disabled="saveInProgress"
                >
                  {{ $translation.btn.cancel }}
                </button>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Select from '@com/components/forms/Select'
import NumberInput from '@com/components/forms/NumberInput'
import Preloader from '@com/components/preloader/Preloader'
import FormItem from '@com/components/forms/FormItem'
import DatePicker from '@com/components/forms/DatePicker'
import { checkDate, checkNumberRange, checkStringEmpty } from '@com/functions/validation.js'

export default {
  components: {
    Select,
    NumberInput,
    Preloader,
    DatePicker,
    FormItem
  },
  data() {
    return {
      startCheer: '',
      maxDate: new Date(),
      minDate: new Date('1900-01-01'),
      favoriteNumber: '',
      favoriteFootballer: '',
      fanTeam: '',
      saveInProgress: false,
      validation: false,
      status: '',
      message: '',
      showMessage: false
    }
  },
  computed: {
    ...mapState({
      favoriteFootballerOptions: state => state.defaults.favoriteFootballerOptions,
      teams: state => state.defaults.teams,
      startCheerStore: state => state.additional.startCheer,
      favoriteNumberStore: state => state.additional.favoriteNumber,
      favoriteFootballerStore: state => state.additional.favoriteFootballer,
      fanTeamStore: state => state.additional.fanTeam,
    }),
    isChanged() {
      return (+this.favoriteNumber !== +this.favoriteNumberStore) || 
             (this.favoriteFootballer !== this.favoriteFootballerStore) ||
             (this.startCheer !== this.startCheerStore) ||
             (this.fanTeam !== this.fanTeamStore)
    },
    isStartCheerValid() {
      return checkDate(this.startCheer, this.minDate, this.maxDate) || checkStringEmpty(this.startCheer)
    },
    isFavoriteNumberValid() {
      return checkNumberRange(Number(this.favoriteNumber), 1, 99) || checkStringEmpty(this.favoriteNumber)
    },
    isValid() {
      return this.isStartCheerValid && 
             this.isFavoriteNumberValid
    }
  },
  methods: {
    ...mapActions({
      updateAdditional: 'additional/updateAdditional',
      getProfileData: 'profile/getProfileData',
    }),
    onReset() {
      this.validation = false
      this.reset()
    },
    reset() {
      this.startCheer = this.startCheerStore
      this.favoriteNumber = this.favoriteNumberStore
      this.favoriteFootballer = this.favoriteFootballerStore
      this.fanTeam = this.fanTeamStore
    },
    onSubmit() {
      if ((!this.saveInProgress) && this.isChanged) {
        if (this.isValid) {
          this.saveInProgress = true

          this.updateAdditional({
            startRootingFor: this.startCheer,
            favouriteNumber: this.favoriteNumber,
            favouritePlayer: this.favoriteFootballer,
            fanTeam: this.fanTeam
          })
            .then(res => {
              this.reset()
              this.message = res.data.message || this.$translation.message.changeSuccess
              this.status = 'success'
              this.getProfileData()
            })
            .catch(error => {
              this.message = error.response.data.message || this.$translation.message.unknownError
              this.status = 'error'
            })
            .finally(()=> {
              this.saveInProgress = false
              this.validation = false
              this.showMessage = true
              setTimeout(() => this.showMessage = false, 3000)
            })
        } else {
          this.validation = true
        }
      }
    },
  },
  mounted() {
    this.reset()
  }
}
</script>
