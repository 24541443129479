<template>
    <Wrapper>
        <Sort
            :sort="sort"
            :disabled="disabled"
            @change-sort="(value) => $emit('changeSort', value)"
        />
        <Cards
            :items="events"
            @scroll-end="$emit('loadMore')"
            @open-popup="onOpenPopup"
            bannerType="stock"
        />
    </Wrapper>
    <Popup
        v-if="popupName === 'loyaltyEvents'"
        :items="events"
        :initial-item="initialItem"
        :is-partner="false"
    />
</template>

<script>
import Cards from "@web/components/loyalty/cards/Cards.vue";
import Sort from "@web/components/loyalty/events/components/sort/Sort.vue";
import Wrapper from "@web/components/loyalty/Wrapper.vue";
import Popup from "@web/components/loyalty/popup/Popup.vue";
import popupMixin from "@com/mixins/popup";

export default {
    mixins: [popupMixin],
    components: {
        Cards,
        Sort,
        Wrapper,
        Popup,
    },
    props: {
        events: {
            type: Array,
            required: false,
        },
        sort: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    emits: ["loadMore", "changeSort"],
    data() {
        return {
            initialItem: {},
        };
    },
    methods: {
        onOpenPopup(item) {
            this.initialItem = item;

            this.openPopup({
                name: "loyaltyEvents",
                contentClasses: ["popup__content_loyalty"],
                popupClasses: ["popup_grey"],
            });
        },
    },
};
</script>
