import axios from 'axios'

export default {
	namespaced: true,
	state: {
		firstName: '',
		lastName: '',
		middleName: '',
		birthDate: '',
		changeBirthDate: true,
		genderCode: null,
		cityId: ''
	},
	mutations: {
		setFirstName(state, value) {
			state.firstName = value
		},
		setLastName(state, value) {
			state.lastName = value
		},
		setMiddleName(state, value) {
			state.middleName = value
		},
		setBirthDate(state, value) {
			state.birthDate = value
		},
		setChangeBirthDate(state, value) {
			state.changeBirthDate = value
		},
		setGender(state, value) {
			state.genderCode = value
		},
		setCity(state, value) {
			state.cityId = value
		}
	},
	actions: {
		updatePersonal({commit}, {lastName, firstName, middleName, birthDate = null, genderCode = null, cityId}) {
			let params = {
				lastName,
				firstName,
				middleName,
				genderCode,
				cityId
			}

			if (birthDate !== null) {
				params.birthDate = birthDate
			}

			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/api/profile/updateContact`,
					data: params
				})
					.then( res => {
						resolve(res)

						commit('setFirstName', res.data.data.firstName)
						commit('setLastName', res.data.data.lastName)
						commit('setBirthDate', res.data.data.birthDate)
						commit('setMiddleName', res.data.data.middleName || middleName)
						commit('setCity', res.data.data.cityId)
						commit('setGender', res.data.data.genderCode)

						if (res.data.data.birthDateDisable) {
							commit('setChangeBirthDate', false)
						}
					})
					.catch( error => {
						reject(error)
					})
			})
		}
			
	}

}
