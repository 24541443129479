export default {
    "header": {
        "mainLinkAlt": "Personal account | FC Dynamo Moscow ⚪️🔵 ©  1923",
        "burger": "Menu",
        "menu": {
            "main": {
                "name": "Main website",
                "link": "https://en.fcdm.ru/"
            },
            "tickets": {
                "name": "Tickets",
                "link": "https://tickets.fcdm.ru/en/"
            },
            "shop": {
                "name": "Shop",
                "link": "https://shop.fcdm.ru/en/"
            }
        },
        "nav": {
            "tickets": "my tickets",
            "orders" : "my orders",
            "settings": "profile settings",
            "loyalty": "Loyalty program",
            "exit": "logout"
        }
    },
    "footer": {
        "footballСlub": "FC Dynamo Moscow.",
        "copyright": "All rights reserved.",
        "contacts": {
            "title": "contacts",
            "items": [
                {
                    "title": "Client office",
                    "phone": {
                        "link": "84959871923",
                        "name": "+7 (495) 987-19-23"
                    },
                    "mail": {
                        "link": "tickets@fcdynamo.ru",
                        "name": "tickets@fcdynamo.ru"
                    }
                },
                {
                    "title": "VIP",
                    "phone": {
                        "link": "89777771923",
                        "name": "+7 (977) 777-19-23"
                    },
                    "mail": {
                        "link": "hospitality@fcdynamo.ru",
                        "name": "hospitality@fcdynamo.ru"
                    }
                },
                {
                    "title": "Fan`s support office",
                    "phone": {
                        "link": "84956427172",
                        "name": "+7 (495) 642-71-72 int. 468"
                    },
                    "mail": {
                        "link": "fans@fcdynamo.ru",
                        "name": "fans@fcdynamo.ru"
                    }
                }
            ]
        }
    },
    "forms": {
        "datePicker": {
            "selectText": "Select",
            "cancelText": "Cancel",
            "nowButtonLabel": "Now",
            "placeholder": "DD.MM.YYYY"
        },
        "fileInput": {
            "attachFile": "Attach file"
        }
    },
    "confirmEmail": {
        "preloaderText": "Confirmation in progress",
        "closePopupText": "Main page",
        "emailSuccess": "Email well confirmed!",
        "failedToken": "Invalid token",
    },
    "notFound": {
        "h1": "Page <br> not found",
        "text": "This page has been deleted or never existed",
        "link": "To tickets"
    },
    "preloader": {
        "loading": "Loading",
        "save": "Save changes",
    },
    "message": {
        "changeSuccess": "Changes saved",
        "unknownError": "Something went wrong",
        "tryUpdate": "Try to refresh the page."
    },
    "btn": {
        "save": "Save changes",
        "cancel": "Cancel",
        "bind": "",
        "publicServices": "",
        "submit": "Send",
        "detailed": "More information",
        "active": "Active",
        "past": "Past "
    },
    "pagination": {
        "showBy": "show",
        "showMore": "show 5 more"
    },
    "profile": {
        "h1": "Profile settings",
        "personal": {
            "label": {
                "lastName": "Surname",
                "firstName": "Name",
                "middleName": "Middle name",
                "birthDate": "Date of birth",
                "city": "Город",
                "gender": "Пол",
                "man": "Мужчина",
                "woman": "Женщина"
            },
            "error": {
                "lastName": "Invalid surname",
                "firstName": "Invalid name",
                "middleName": "Invalid middle name",
                "birthDate": "Invalid date of birth",
                "city": "Select city"
            },
            "changeBirthText": "Your date of birth can be changed two times only <br> Contact <a href=\"mailto:support@fcdm.ru\">support service</a>."
        },
        "password": {
            "h2": "Change password",
            "label": {
                "password": "New password",
                "repeatedPassword": "Repeat password"
            },
            "error": {
                "password": "One symbol at minimum",
                "repeatedPassword": "Passwords do not match"
            }
        },
        "biometrics": {
            "h2": "",
            "text": "",
            "label": {
                "start": "",
                "link": "",
                "end": ""
            }
        },
        "fanId": {
            "h2": "Fan ID",
            "label": "Fan ID",
            "error": "Invalid Fan ID",
            "apply": "How to apply",
            "present": "Gifts for registration from the Club",
            "status": "Your FAN ID number is being checked",
            "bindSubscriptions": "Bind subscriptions",
            "exists": "Существует контакт с таким номером. Обратитесь в <a href='#' target='_blank'>форму обратной связи.</a>"
        },
        "photo": {
            "h2": "Photo",
            "requirements": "Photo Requirements",
            "error": "The photo is too heavy"
        },
        "contact": {
            "h2": "Contacts"
        },
        "email": {
            "repeatSend": "Send email again",
            "text": "A confirmation link will be sent to your email",
            "label": "Email",
            "error": "Invalid email",
            "unconfirmed": "Email not confirmed",
            "popup": {
                "text": "A confirmation link has been sent to your email"
            }
        },
        "phone": {
            "repeatSend": "Resend code",
            "text": "НConfirmation code will be sent to your number",
            "popup": {
                "title": "Confirm changes",
                "message": "We have sent confirmation code to",
                "timer": "Resend code by SMS",
                "repeatBtn": "Retry",
                "sendAgainBtn": "Resend"
            },
            "preloaderText": "Sending code in progress",
            "label": "Phone number",
            "error": "Invalid phone number",
            "unconfirmed": "Phone number not confirmed",
            "confirmCodeErrorMessage": "Invalid confirmation code"
        },
        "notifications": {
            "h2": "Notifications",
            "h3": "My subscriptions",
            "label": {
                "allowSms": "Compliance to receive informational messages and news letters by SMS",
                "allowEmail": "Compliance to receive informational messages and news letters by email"
            }
        },
        "additional": {
            "h2": "More information",
            "label": {
                "startCheer": "When did you start cheering for Dynamo?",
                "favoriteNumber": "Your favourite shirt number",
                "favoriteFootballer": "Your favourite football player",
                "supportOtherTeam": "I am a fan of another team"
            },
            "error": {
                "startCheer": "Invalid date",
                "favoriteNumber": "Choose number from 1 to 99",
                "favoriteFootballer": "Choose your favorite football player",
                "supportOtherTeam": "Choose a team"
            },
            "text": "Your form <br> for this season is ready",
            "btn": "buy a t-shirt"
        },
    },
    "orders": {
        "h1": "Shop orders",
        "active": {
            "title": {
                "empty": "You have no active orders in the store",
                "full" : "Active orders",
                "fetchError": "Failed to retrieve active orders data"
            },
            "card": {
                "order": "Order",
                "product": ["product", "products", "products"],
                "more": "show more",
                "status": "status",
                "deliveryMethod": "delivery method",
                "pickupAddress": "pickup point address",
                "deliveryAddress": "delivery address",
                "trackNumber": "track number",
                "pay": "pay for the order",
            }
        },
        "archive": {
            "title": {
                "text": "Order archive",
                "orders": ["order", "orders", "orders"],
                "fetchError": "Failed to retrieve archived order data"
            },
            "card": {
                "product": ["product", "products", "products"],
                "points": ["point", "point", "point"],
                "details": "show more details"
            }
        },
        "popup": {
            "toList": "back to list",
            "order": "order",
            "status": "status",
            "deliveryMethod": "delivery method",
            "pickupAddress": "pickup point address",
            "deliveryAddress": "delivery address",
            "paymentMethod": "payment method",
            "date": "Order date",
            "pointsForOrder": "Points per order",
            "points": ["point", "point", "point"],
            "orderList": "Order list",
            "count": "Amount",
            "thing": ["piece", "piece", "piece"],
            "color": "Color",
            "size": "Size",
            "returned": "returned",
            "product": ["product", "products", "products"],
            "forPrice": "for the amount",
            "discountsAndBonuses": "Discounts and bonuses",
            "returns": "Refund",
            "delivery": "Delivery",
            "total": "Total",
            "haveQuestions": "Have any questions?",
            "phone": "You can call us",
            "everyDay": "On weekdays from 10 to 19"
        }
    },
    "loyalty": {
        "nav": {
            "common": "Loyalty program",
            "stock": "Offers",
            "raffle": "Prize drawing",
            "partners": "Partners"
        },
        "common": {
            "levelNames": {
                "1": "Standard",
                "2": "Premium"
            },
            "cardNumber": "Card number",
            "title": "Hello",
            "description": {
                "1": "Make purchases of tickets and paraphernalia for 10,000 rubles, go to the next level, and you will have access to privileges from the club and discounts from partners.",
                "2": "Now you have access to discounts on merchandise and tickets, as well as special promotions and offers from the club and partners. Keep shopping to keep these benefits."
            },
            "card": {
                "wallet": "Refill the Wallet",
                "yourLevel": "Level",
                "accumulationAmount": "Amount of deposit to get next level",
                "confirmationAmount": "The amount of deposit to confirm the level",
                "level": "Level",
                "youAreHere": "You are here",
                "showMore": "Show more",
                "level_description": {
                  "1": "<p>To go to the next level and get more privileges and discounts, you need to buy at the total of 10,000 rubles.</p><p>A personal profile, account and purchase counter are created for each member of the loyalty program.</p><p>This personal counter is needed to keep record of all purchases made on <a href='https://tickets.fcdm.ru/' target='_blank'>tickets.fcdm.ru</a> and <a href='https://shop.fcdm.ru/' target='_blank'>shop.fcdm.ru</a>, in the official merchandise store located in the Arena Plaza shopping mall, as well as contributions to the endowment fund on <a href='https://fund.fcdm.ru/' target='_blank'>fund.fcdm.ru</a> made by using a Loyalty Program Member Card. Purchases are counted subject to the authorization of the loyalty program participant in the personal profile.</p><p>In the progress status, you can see how much is left to store up before moving to the next level. The more purchases you make, the more privileges are available to you!</p>",
                  "2": "<p>Now all the privileges of the loyalty program are available to you: a discount on buying club merchandise in the official store and online shop, a 30% discount on buying tickets, a birthday gift, participation in prize draws and access to exclusive offers from program partners.</p><p>To save all the privileges, you must confirm your level within 365 days by making purchases at the total of 10,000 rubles or more.</p><p>In the progress status, you can see how much is left to store up for the confirmation of the level. You can also see the date before which you need to do it.</p><p>The personal counter is needed to keep record of all purchases made on <a href='https://tickets.fcdm.ru/' target='_blank'>tickets.fcdm.ru</a> and <a href='https://shop.fcdm.ru/' target='_blank'>shop.fcdm.ru</a>, in the official merchandise store located in the Arena Plaza shopping mall, as well as contributions to the endowment fund on <a href='https://fund.fcdm.ru/' target='_blank'>fund.fcdm.ru</a> made by using a Loyalty Program Member Card. Purchases are counted subject to the authorization of the loyalty program participant in the personal profile.</p><p>All information about current discounts is posted in the personal account.</p><p>The more purchases you make, the more chances you have to save all the privileges!</p>",
                  "confirmed": "<p>You confirmed your level and kept all the advantages of the loyalty program: a discount on buying club merchandise in the official store and online shop, a 30% discount on buying tickets, a birthday gift, participation in prize draws and access to exclusive offers from program partners.</p><p>On the day indicated under the scale, your level will automatically last for another year, after which the confirmation date will be updated.</p><p>All information about current discounts is posted in the personal account.</p>"
                },
                "level_description_short": {
                  "1": "<p>To go to the next level and get more privileges and discounts, you need to buy at the total of 10,000 rubles. A personal profile, account and purchase counter are created for each member of the loyalty program.",
                  "2": "<p>Now all the privileges of the loyalty program are available to you: a discount on buying club merchandise, a 30% discount on buying tickets, participation in prize draws, and access to exclusive offers from program partners. To save all the privileges, you must confirm your level within 365 days by making purchases at the total of 10,000 rubles or more.",
                  "confirmed": "<p>You confirmed your level and kept all the advantages of the loyalty program: a discount on buying club merchandise, a 30% discount on buying tickets, a birthday gift, participation in prize draws, and access to exclusive offers from program partners."
                },
                "confirmation": "Confirm until",
                "levelIsConfirmed": "Level is confirmed"
            },
            "resources": {
                "shop": {
                    "title": "15% for&nbsp;merchandise",
                    "btn": "BUY KIT"
                },
                "tickets": {
                    "title": "30% for&nbsp;tickets",
                    "btn": "BUY TICKETS"
                }
            },
            "faq": {
                "title": "faq",
                "data": [
                    {
                        'question': {
                            'en': 'What is the loyalty program of FC Dynamo?'
                        },
                        'answer':  {
                            'en': 'FC Dynamo loyalty program is an opportunity to receive discounts on merchandise, participate in raffles, get pleasant bonuses and exclusive privileges from our partners.'
                        },
                    },
                    {
                        'question': {
                            'en': 'What if I have already registered and have a personal account?'
                        },
                        'answer':  {
                            'en': 'If you were previously registered in the loyalty program and you have a personal account, then re-registration is not required. Please, use your current account.'
                        },
                    },
                    {
                        'question': {
                            'en': 'Who can join the loyalty program?'
                        },
                        'answer':  {
                            'en': '<p>Participation in the Program is voluntary. Any individual aged 18 (eighteen) and older at the time of registration in the Program can become a Participant.</p><p>Legal entities and their representatives are not allowed to participate in the Program.</p>'
                        },
                    },
                    {
                        'question': {
                            'en': 'How to upgrade/confirm your status?'
                        },
                        'answer':  {
                            'en': '<p>Each participant of the loyalty program gets a profile, a personal account and a purchase counter.</p><p>To upgrade the status, the participant needs to make purchases for a total amount of 10,000 (ten thousand) rubles. All purchases made on the company\'s websites <a href="https://tickets.fcdm.ru" target="_blank">https://tickets.fcdm.ru</a> and <a href="https://shop.fcdm.ru" target="_blank">https://shop.fcdm.ru</a>, as well as contributions to <a href="https://fund.fcdm.ru" target="_blank">the Endowment Fund</a> are taken into account. Purchases are taken into account only after the authorization of the Loyalty Program participant in the personal account.</p><p>To confirm the status, the participant must make purchases for the amount specified in the Loyalty Program Rules during the validity period of his status.</p>',
                        },
                    },
                    {
                        'question': {
                            'en': 'Where can I find the information about my status?',
                        },
                        'answer':  {
                            'en': 'The participant can track the total amount of purchases and his status in his personal account. Or request information by sending an email to <a href="mailto:loyalty@fcdm.ru">loyalty@fcdm.ru</a>.'
                        },
                    },
                    {
                        'question': {
                            'en': 'Where can I find information about current loyalty program promotions?',
                        },
                        'answer':  {
                            'en': 'All up-to-date information about current promotions and partner offers is posted on <a href="https://loyalty.fcdm.ru/" target="_blank">the loyalty.fcdm.ru</a> website in the News and Partners sections. Also, information about new promotions and offers is distributed via email.'
                        },
                    },
                    {
                        'question': {
                            'en': 'How many times can I use privileges of the loyalty program?',
                        },
                        'answer':  { 
                            'en': '<p>A loyalty program participant can take advantage of the discount and/or offers according to the terms of each promotion. Terms, expiry dates and restrictions for each promotion may differ. Details are posted here: <a href="https://loyalty.fcdm.ru/" target="_blank">loyalty.fcdm.ru</a></p><p>The list of companies offering discounts, discount rate and the conditions for their provision may change without prior notice. These discounts are not cumulative with other discounts and special offers of these companies.</p><p>When paying for goods and services of partner companies, take the initiative and remind the staff to give you a discount.</p>'
                        },
                    },
                ],
                "form": {
                    "title": {
                        "en": "If you haven't found an answer to your question, write it in this form",
                    },
                    "placeholder": {
                        "name": "First name",
                        "email": "Email",
                        "type": "Type of request",
                        "question": "Question"
                    },
                    "error": {
                        "name": "Invalid first name",
                        "phone": "Invalid phone number",
                        "email": "Invalid Email",
                        "type": "Select the type of request",
                        "question": "10 symbols minimum",
                        "filesSize": "The total file size exceeds #MB"
                    },
                    "callTypes": {
                        "changePersonalData": "Change personal data",
                        "help": "Help / Consultation",
                        "discounts": "Discounts / Promo codes",
                        "loyaltyStatus": "Loyalty program status",
                        "complaint": "Complaint / Claim ",
                        "cooperation": "Cooperation",
                        "tickets": "Tickets / Season Tickets"
                    },
                    "agreement": "By clicking on the button, you agree to <a href='https://fcdm.ru/club/policy' target='_blank'>the rules for processing personal data</a> and receiving promotional newsletters",
                    "popup": {
                        "title": "Your request is accepted! ",
                        "text": "The application has been sent. <br> Thank you for the feedback. We will notify you about the solution of the problem on the data you specified.",
                        "btn": "Ок"
                    }
                }
            }
        },
        "stock": {
            "title": "Offers",
        },
        "raffles": {
            "title": "Prize drawing"
        },
        "partners": {
            "title": "Partners' offers"
        },
        "promocodes": {
            "title": "Promocodes"
        },
        "special": {
            "title": "Special Offers"
        },
    },
    "tickets": {
        "h1": "MY TICKETS AND&nbsp;SEASON TICKETS",
        "children": "children's",
        "noSubscriptions": "No season tickets",
        "sector": "sector",
        "row": "row",
        "place": "seat",
        "download": "download",
        "downloadAll": "download all",
        "wallet": "add to Wallet",
        "buy": "buy",
        "date": "date",
        "time": "time",
        "parkingTicket": {
            "spots": "spots",  
            "entrance": "Entrance from <br />Teatralnaya avenue",
            "route": "route"     
        },
        "orderTypes": {
            "ticket": "Ticket",
            "parking": "Parking",
            "season_ticket": "Season ticket",
            "restaurant": "Restaurant"
        },
        "cover": {
            "buyTicketForFriend": "Buy a ticket for your friend with 30% discount",
            "noTickets": "You don't have any tickets bought",
            "youGoToMatch": "You are going to the match",
            "after": "in",
            "days": ["day", "day", "days"],
            "today": "today",
            "fetchError": "Failed to obtain information about tickets and subscriptions",
        },
        "subscriptions": {
            "title": "Season tickets",
            "titleDeclension": ["season ticketт", "season ticket", "season tickets"],
            "paid": "Оплаченные абонементы",
            "download": "download season ticket",
        },
        "tickets": {
            "title": "Tickets",
            "titleDeclension": ['ticket', 'ticket', 'tickets'],
            "paid": "Оплаченные билеты",
        },
        "ticketsDomDynamo": {
            "title": "BetBoom Дом Динамо",
        },
        "parking": {
            "title": "parking",
            "titleDeclension": ['parking', 'parking', 'parking lot'],
            "places": "places",
            "route": "route"
        },
        "restaurant": {
            "title": "restaurant",
            "titleDeclension": ['restaurant', 'restaurant', 'restaurant'],
        },
        "story": {
            "title": "Purchase history",
            "order": ['order', 'order', 'orders'],
            "allOrders": "all orders",
            "cancel": "cancel order",
            "pay": "purchase order",
            "return": "have order back",
            "statusTitle": {
                "E": "expecting payment",
                "C": "cancelled",
                "P": "paid",
                "U": "updated",
            },
            "popup": {
                "title": {
                    "cancel": {
                        "isCanceled": "Order is cancelled.",
                        "isNotCanceled": "Do you really want to cancel your&nbsp;order?"
                    },
                    "return": {
                        "isCanceled": "Return issued.",
                        "isNotCanceled": "Do you really want to have it&nbsp;back?"
                    }
                },
                "text": {
                    "isCanceled": "Money will be returned to the card account from which the payment was made within 14 days. If you have any questions, please contact us by e-mail tickets@fcdm.ru.",
                    "isNotCanceled": "In accordance with the rules described in clause 5.2.2 of the Offer is to be returned."
                },
                "btn": {
                    "isCanceled": "buy tickets",
                    "cancel": "Cancel order",
                    "return": "Have it back "
                }
            }
        }
    },
    "faq": {
        "h1": "Вопросы и ответы",
    }
}
