<template>
  <Preloader
    v-if="isPending"
    :text="$translation.preloader.loading"
    class="preloader_large"
  />
</template>


<script>
import { mapActions } from 'vuex'
import Preloader from '@com/components/preloader/Preloader'
import { TOO_MANY_REQUESTS_CODE } from '@com/constants/responseCodes.js'

export default {
  components: {
    Preloader,
  },
  data() {
    return {
      isPending: true,
    }
  },
  methods: {
    ...mapActions({
      webAuth: 'token/webAuth',
      checkAuth: 'token/checkAuth',
      webLogin: 'token/webLogin',
      authorizeRedirect: 'token/authorizeRedirect',
      webManzanaDeAuth: 'token/webManzanaDeAuth'
    }),
    fetchToken() {
      const id = this.$route.query.id
      const state = this.$route.query.state
      if (id && state) {
        this.webAuth({id, state})
          .then( res => {
            this.webLogin({
              token: res.data.data.token
            })
            this.$router.push({
              name: 'home'
            })
          })
          .catch( error => {
            if (error.response.status === TOO_MANY_REQUESTS_CODE) {
              this.webManzanaDeAuth({url: error.response.data.data.logoutLink})
                .finally(()=> {
                  this.authorizeRedirect()
                })
            } else {
              this.authorizeRedirect()
            }
          })
          .finally(() => {
            this.isPending = false
          })
      } else {
        this.$router.push({
          name: 'home'
        })
      }
    }
  },
  created() {
    this.fetchToken()
  }
}
</script>
