<template>
  <div class="orders-popup__data">
    <div class="orders-table">
      <Item 
        :name="$translation.orders.popup.status"
        :value="order.statusTitle"
      />
      <Item 
        :name="$translation.orders.popup.deliveryMethod"
        :value="order.deliveryWay"
      />
      <Item 
        :name="order.deliveryWayId === 2  ? $translation.orders.popup.pickupAddress : $translation.orders.popup.deliveryAddress"
        :value="order.deliveryAddress"
        value-class="orders-table__column-right_limited"
      />
      <Item 
        :name="$translation.orders.popup.paymentMethod"
        :value="order.paymentWay"
      />
      <Item 
        :name="$translation.orders.popup.date"
        :value="order.orderedDate"
      />
    </div>
  </div>
</template>

<script>
import Item from '@web/components/orders/popup/components/data/components/Item.vue'

export default {
  components: {
    Item
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
