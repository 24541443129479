import { v4 as uuidv4 } from 'uuid'

export function getDeviceId() {
  let deviceId = localStorage.getItem('dynamo_elk_device_id')

  if (!deviceId) {
    deviceId = uuidv4()
    localStorage.setItem('dynamo_elk_device_id', deviceId)
  }

  return deviceId
}

export function declOfNum(number, words) {  
  return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
