<template>
    <!-- FRONTSIDE -->
    <div
        v-if="isFrontside"
        class="loyalty-promocodes__item"
        :class="{ 'loyalty-promocodes__item--dark': true }"
    >
        <!-- CONTENT -->
        <div class="loyalty-promocodes__item-content">
            <!-- top -->
            <div class="loyalty-promocodes__item-top">
                <span class="loyalty-promocodes__item-title">{{
                    promocode.number
                }}</span>
                <button class="loyalty-promocodes__item-btn" @click="copy">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.8421 17H5.26316C4.56842 17 4 16.4273 4 15.7273V6.81818H5.26316V15.7273H12.8421V17ZM14.7368 14.4545H7.78947C7.09474 14.4545 6.52632 13.8818 6.52632 13.1818V4.27273C6.52632 3.57273 7.09474 3 7.78947 3H14.7368C15.4316 3 16 3.57273 16 4.27273V13.1818C16 13.8818 15.4316 14.4545 14.7368 14.4545ZM14.7368 4.27273H7.78947V13.1818H14.7368V4.27273Z"
                            fill="#0959A1"
                        />
                    </svg>
                </button>
            </div>
            <!-- date before -->
            <span class="loyalty-promocodes__item-date">До {{ dateEnd }}</span>
            <span class="loyalty-promocodes__item-success" v-show="isShowSuccesCopyMessage">Прокомод скопирован!</span>
            <!-- bottom -->
            <div class="loyalty-promocodes__item-bottom">
                <span>{{ promocode.description }}</span>
                <button
                    class="loyalty-promocodes__item-btn"
                    @click="isFrontside = false"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_9160_64)">
                            <path
                                d="M3.28092 1.70311C2.48014 1.87498 1.86686 2.49998 1.69889 3.31639C1.64811 3.55467 1.6403 3.90623 1.64811 5.40623L1.65983 7.21092L1.75748 7.35545C2.03873 7.76561 2.60123 7.7617 2.87858 7.34764L2.96842 7.21483V5.46092C2.96842 4.2617 2.98405 3.64842 3.0153 3.52733C3.07389 3.28905 3.28873 3.0742 3.52702 3.01561C3.65202 2.98436 4.3278 2.96873 5.74186 2.96873C7.98795 2.96873 7.94889 2.97264 8.1403 2.72264C8.40592 2.37498 8.27702 1.89842 7.87077 1.71483C7.72623 1.64842 7.56998 1.64061 5.62467 1.64451C3.98014 1.64451 3.48014 1.66014 3.28092 1.70311Z"
                                fill="#0959A1"
                            />
                            <path
                                d="M12.1293 1.71483C11.723 1.89842 11.5941 2.37498 11.8558 2.71873C12.0551 2.97655 11.9691 2.96873 14.2387 2.96873C15.6683 2.96873 16.348 2.98436 16.473 3.01561C16.7113 3.0742 16.9262 3.28905 16.9847 3.52733C17.016 3.64842 17.0316 4.2617 17.0316 5.46092V7.21483L17.1215 7.34764C17.3988 7.7617 17.9613 7.76561 18.2426 7.35545L18.3402 7.21092V5.29686C18.3402 3.42577 18.3363 3.37108 18.2543 3.10545C18.0863 2.5742 17.6957 2.11327 17.2152 1.87498C16.7582 1.65233 16.6332 1.64061 14.3558 1.64061C12.4301 1.64061 12.2738 1.64842 12.1293 1.71483Z"
                                fill="#0959A1"
                            />
                            <path
                                d="M5.79297 4.78125C5.39062 4.91016 5.03516 5.22656 4.85156 5.61719C4.74609 5.83984 4.74609 5.84375 4.73438 6.92188L4.72266 8.00781H10H15.2773L15.2656 6.92188L15.2539 5.83594L15.1289 5.58203C14.9766 5.26953 14.6953 4.99609 14.3828 4.85156L14.1602 4.74609L10.0586 4.73828C6.74219 4.73438 5.92578 4.74219 5.79297 4.78125Z"
                                fill="#0959A1"
                            />
                            <path
                                d="M0.351562 9.42578C0.152344 9.53125 0 9.77734 0 10C0 10.2227 0.152344 10.4688 0.351562 10.5742L0.519531 10.6641H10H19.4805L19.6484 10.5742C19.8477 10.4688 20 10.2227 20 10C20 9.77734 19.8477 9.53125 19.6484 9.42578L19.4805 9.33594H10H0.519531L0.351562 9.42578Z"
                                fill="#0959A1"
                            />
                            <path
                                d="M4.73438 13.0742C4.74609 14.1602 4.74609 14.1602 4.85156 14.3828C4.99609 14.6953 5.26953 14.9766 5.58203 15.1289L5.83984 15.2539H10H14.1602L14.3828 15.1484C14.6953 15.0039 14.9766 14.7305 15.1289 14.418L15.2539 14.1641L15.2656 13.0781L15.2773 11.9922H10H4.72266L4.73438 13.0742Z"
                                fill="#0959A1"
                            />
                            <path
                                d="M1.99208 12.426C1.91396 12.4689 1.80849 12.5705 1.75771 12.6447L1.66005 12.7892L1.64833 14.5939C1.63661 16.59 1.65224 16.758 1.8749 17.215C2.10536 17.6838 2.5624 18.0783 3.08193 18.2502C3.33193 18.3361 3.39443 18.34 5.50771 18.3517C7.42958 18.3635 7.6913 18.3557 7.83974 18.301C8.26161 18.1369 8.41396 17.633 8.14443 17.2814C7.94521 17.0236 8.03115 17.0314 5.76161 17.0314C4.33193 17.0314 3.65224 17.0158 3.52724 16.9846C3.28896 16.926 3.07411 16.7111 3.01552 16.4728C2.98427 16.3517 2.96865 15.7385 2.96865 14.5392V12.7853L2.8788 12.6525C2.67568 12.3478 2.29286 12.2502 1.99208 12.426Z"
                                fill="#0959A1"
                            />
                            <path
                                d="M17.3397 12.4338C17.2694 12.4806 17.1678 12.5822 17.1209 12.6525L17.0311 12.7853V14.5392C17.0311 15.7385 17.0155 16.3517 16.9842 16.4728C16.9256 16.7111 16.7108 16.926 16.4725 16.9846C16.3475 17.0158 15.6678 17.0314 14.2381 17.0314C11.9686 17.0314 12.0545 17.0236 11.8553 17.2814C11.5858 17.633 11.7381 18.1369 12.16 18.301C12.3084 18.3557 12.5702 18.3635 14.492 18.3517C16.6014 18.34 16.6678 18.3361 16.9139 18.2541C17.5623 18.0353 18.0506 17.5392 18.2537 16.8947C18.3358 16.6291 18.3397 16.5744 18.3397 14.7033V12.7892L18.242 12.6447C18.0389 12.3478 17.6131 12.2502 17.3397 12.4338Z"
                                fill="#0959A1"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_9160_64">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
        </div>
        <!-- ICON -->
        <div class="loyalty-promocodes__item-icon">
            <img
                src="@web/assets/img/loyalty/promocode_logo_light.svg"
                alt=""
            />
            <!-- <template v-if="promocode.type === 'dynamo'">
                <img
                    v-if="content.dark"
                    src="@web/assets/img/loyalty/promocode_logo_light.svg"
                    alt=""
                />
                <img
                    v-else
                    src="@web/assets/img/loyalty/promocode_logo_dark.svg"
                    alt=""
                />
            </template>
            <template v-if="promocode.type === 'betboom'">
                <img
                    v-if="promocode.dark"
                    src="@web/assets/img/loyalty/promocode_bb_light.svg"
                    alt=""
                />
                <img
                    v-else
                    src="@web/assets/img/loyalty/promocode_bb_dark.svg"
                    alt=""
                />
            </template>
            <template v-if="promocode.type === 'present'">
                <img
                    src="@web/assets/img/loyalty/promocode_present.png"
                    alt=""
                />
            </template> -->
        </div>
    </div>

    <!-- BACKSIDE -->
    <div
        v-else
        class="loyalty-promocodes__item loyalty-promocodes__item--backside"
        :class="{ 'loyalty-promocodes__item--dark': false }"
    >
        <div class="loyalty__number-barcode" v-if="barcodeData">
            <img :src="barcodeData" alt="Barcode" />
        </div>
        <button
            class="loyalty-promocodes__item-btn loyalty-promocodes__item-btn--backside"
            @click="isFrontside = true"
        >
            <svg
                width="18"
                height="24"
                viewBox="0 0 18 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.7535 0.719633C12.3406 1.11261 12 1.44909 12 1.46137C12 1.4933 13.5019 2.92274 13.5355 2.92274C13.551 2.92274 13.6645 2.8245 13.791 2.70415C13.9845 2.51994 14.0155 2.48065 13.9923 2.44135C13.9768 2.41433 13.8194 2.2596 13.6439 2.09258L13.3213 1.79049L14.3974 1.79785L15.471 1.80768L15.6981 1.87399C16.3226 2.0582 16.8026 2.44135 17.0916 2.98906C17.1948 3.17817 17.2903 3.52203 17.3084 3.7529C17.3161 3.86588 17.3368 3.96658 17.3548 3.97886C17.3755 3.99605 17.5097 4.00342 17.6929 3.99851L18 3.99114L17.9948 3.73325C17.9845 3.09221 17.6206 2.35784 17.0787 1.88136C16.7303 1.57435 16.2658 1.32874 15.8013 1.21085C15.551 1.14454 15.5355 1.14454 14.431 1.13225L13.3161 1.11752L13.6645 0.785947C13.8555 0.604197 14.0129 0.444551 14.0129 0.429815C14.0129 0.390517 13.5897 -8.83484e-08 13.5484 -8.83484e-08C13.5277 -8.83484e-08 13.169 0.324203 12.7535 0.719633Z"
                    fill="#0959A1"
                />
                <path
                    d="M5.24645 23.2804C5.65935 22.8874 6 22.5509 6 22.5386C6 22.5067 4.49806 21.0773 4.46452 21.0773C4.44903 21.0773 4.33548 21.1755 4.20903 21.2959C4.01548 21.4801 3.98452 21.5194 4.00774 21.5587C4.02323 21.5857 4.18065 21.7404 4.35613 21.9074L4.67871 22.2095L3.60258 22.2021L2.52903 22.1923L2.30194 22.126C1.67742 21.9418 1.19742 21.5587 0.908387 21.0109C0.805162 20.8218 0.709677 20.478 0.691613 20.2471C0.683871 20.1341 0.663226 20.0334 0.645162 20.0211C0.624516 20.0039 0.490323 19.9966 0.307097 20.0015L1.85127e-07 20.0089L0.00516154 20.2668C0.0154841 20.9078 0.379355 21.6422 0.921291 22.1186C1.26968 22.4256 1.73419 22.6713 2.19871 22.7892C2.44903 22.8555 2.46452 22.8555 3.56903 22.8677L4.68387 22.8825L4.33548 23.2141C4.14452 23.3958 3.9871 23.5554 3.9871 23.5702C3.9871 23.6095 4.41032 24 4.45161 24C4.47226 24 4.83097 23.6758 5.24645 23.2804Z"
                    fill="#0959A1"
                />
                <path
                    d="M4.18126 5.54868C3.60716 5.68236 3.16748 6.16845 3.04706 6.8034C3.01065 6.98873 3.00505 7.26215 3.01065 8.42877L3.01906 9.83236L3.08907 9.94477C3.2907 10.2638 3.69398 10.2607 3.89281 9.93869L3.95722 9.8354V8.4713C3.95722 7.53862 3.96842 7.06164 3.99083 6.96746C4.03284 6.78214 4.18686 6.61504 4.35769 6.56947C4.44731 6.54517 4.9318 6.53302 5.94558 6.53302C7.55586 6.53302 7.52786 6.53605 7.66508 6.34162C7.85551 6.07123 7.7631 5.70059 7.47185 5.5578C7.36823 5.50615 7.25621 5.50007 5.86156 5.50311C4.68255 5.50311 4.32409 5.51526 4.18126 5.54868Z"
                    fill="#0959A1"
                />
                <path
                    d="M10.526 5.5578C10.2348 5.70059 10.1424 6.07123 10.33 6.33858C10.4728 6.53909 10.4112 6.53302 12.0383 6.53302C13.0633 6.53302 13.5506 6.54517 13.6402 6.56947C13.811 6.61504 13.9651 6.78214 14.0071 6.96746C14.0295 7.06164 14.0407 7.53862 14.0407 8.4713V9.8354L14.1051 9.93869C14.3039 10.2607 14.7072 10.2638 14.9088 9.94477L14.9788 9.83236V8.34371C14.9788 6.88847 14.976 6.84594 14.9172 6.63935C14.7968 6.22617 14.5168 5.86768 14.1723 5.68236C13.8446 5.50919 13.755 5.50007 12.1223 5.50007C10.7417 5.50007 10.6297 5.50615 10.526 5.5578Z"
                    fill="#0959A1"
                />
                <path
                    d="M5.98511 7.94173C5.69666 8.04198 5.44181 8.28807 5.31019 8.59187C5.23458 8.76504 5.23458 8.76808 5.22617 9.60659L5.21777 10.4512H9.00124H12.7847L12.7763 9.60659L12.7679 8.76201L12.6783 8.56453C12.5691 8.32149 12.3674 8.10882 12.1434 7.99641L11.9838 7.91438L9.04325 7.90831C6.66563 7.90527 6.08033 7.91135 5.98511 7.94173Z"
                    fill="#0959A1"
                />
                <path
                    d="M2.0831 11.5545C1.94027 11.6365 1.83105 11.8279 1.83105 12.0011C1.83105 12.1743 1.94027 12.3657 2.0831 12.4477L2.20352 12.5176H9.00033H15.7971L15.9176 12.4477C16.0604 12.3657 16.1696 12.1743 16.1696 12.0011C16.1696 11.8279 16.0604 11.6365 15.9176 11.5545L15.7971 11.4846H9.00033H2.20352L2.0831 11.5545Z"
                    fill="#0959A1"
                />
                <path
                    d="M5.22617 14.3926C5.23458 15.2372 5.23458 15.2372 5.31019 15.4104C5.41381 15.6534 5.60984 15.8722 5.83388 15.9907L6.01872 16.0879H9.00124H11.9838L12.1434 16.0059C12.3674 15.8935 12.5691 15.6808 12.6783 15.4377L12.7679 15.2403L12.7763 14.3957L12.7847 13.5511H9.00124H5.21777L5.22617 14.3926Z"
                    fill="#0959A1"
                />
                <path
                    d="M3.25726 13.8876C3.20125 13.921 3.12563 14 3.08923 14.0577L3.01922 14.1701L3.01081 15.5737C3.00241 17.1262 3.01361 17.2568 3.17324 17.6123C3.33847 17.9768 3.66613 18.2837 4.0386 18.4174C4.21783 18.4842 4.26264 18.4872 5.77771 18.4963C7.15555 18.5055 7.34318 18.4994 7.4496 18.4568C7.75206 18.3292 7.86127 17.9373 7.66804 17.6639C7.52522 17.4634 7.58683 17.4695 5.95974 17.4695C4.93476 17.4695 4.44747 17.4573 4.35785 17.433C4.18702 17.3874 4.033 17.2204 3.99099 17.035C3.96858 16.9409 3.95738 16.4639 3.95738 15.5312V14.1671L3.89297 14.0638C3.74735 13.8268 3.4729 13.7509 3.25726 13.8876Z"
                    fill="#0959A1"
                />
                <path
                    d="M14.2628 13.8937C14.2124 13.9301 14.1396 14.0091 14.1059 14.0638L14.0415 14.1671V15.5312C14.0415 16.4639 14.0303 16.9409 14.0079 17.035C13.9659 17.2204 13.8119 17.3874 13.6411 17.433C13.5514 17.4573 13.0642 17.4695 12.0392 17.4695C10.4121 17.4695 10.4737 17.4634 10.3309 17.6639C10.1376 17.9373 10.2469 18.3292 10.5493 18.4568C10.6557 18.4994 10.8434 18.5055 12.2212 18.4963C13.7335 18.4872 13.7811 18.4842 13.9575 18.4204C14.4224 18.2503 14.7725 17.8644 14.9181 17.3631C14.9769 17.1566 14.9797 17.114 14.9797 15.6588V14.1701L14.9097 14.0577C14.7641 13.8268 14.4588 13.7509 14.2628 13.8937Z"
                    fill="#0959A1"
                />
            </svg>
        </button>
    </div>
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
    components: {},
    data() {
        return {
            isFrontside: true,
            barcodeData: null,
            isShowSuccesCopyMessage: false,
        };
    },
    props: {
        promocode: {
            type: Object,
            required: true,
        },
    },
    computed: {
        dateEnd() {
            const date = new Date(this.promocode.date_end)
            const day = date.getDate()
            const month = date.getMonth() + 1
            const year = date.getFullYear().toString().slice(-2)
            return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
        }
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(this.promocode.number);
            this.isShowSuccesCopyMessage = true;

            setTimeout(() => {
                this.isShowSuccesCopyMessage = false;
            }, 5000);
        },

        generateBarcode() {
            try {
                const canvas = document.createElement('canvas');
                JsBarcode(canvas, this.promocode.number, {
                format: 'CODE128', // Вы можете выбрать другой формат баркода по необходимости
                displayValue: false,
                width:4,
                height:40, // Отображение значения текста под баркодом
                });
                this.barcodeData = canvas.toDataURL();
            } catch (error) {
                console.error('Ошибка при создании штрихкода', error);
            }
        },
    },
    created() {
        this.generateBarcode();
    }
};
</script>
