<template>
    <div class="popup popup_tickets" @click="closePopupClickHandler">
        <div class="popup__body popup__body_tickets">
            <div
                data-popup-wrapper
                class="popup__content popup__content_tickets"
            >
                <div class="popup__img_tickets"></div>
                <div class="popup__content_tickets bottom">
                    <h3>Наслаждайся футболом с большим комфортом на выгодных условиях</h3>
                    <p>Повысь класс билета и будь ближе всех к футболистам на лучших местах центральной трибуны с панорамным видом на поле и доступом
                        в премиальные рестораны.</p>
                    <a 
                        :href="`https://tickets.fcdm.ru/ticketupgrade/${nearestMatchOrEvent.id}/order/${nearestMatchOrderId}/`" 
                        target="_blank" 
                        class="btn btn_blue-bordered popup__btn_tickets"
                    >улучшить места</a>
                </div>
                <button
                    class="popup__close popup__close_tickets"
                    type="button"
                    @click="$emit('closePopup')"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    emits: ["closePopup"],
    methods: {
        closePopupClickHandler(event) {
            if (!event.target.closest("[data-popup-wrapper]")) {
                this.$emit("closePopup");
            }
        },
    },
    props: {
        nearestMatchOrderId: {
            type: String,
            required: false
        }
    },
    computed: {
        ...mapGetters({
            nearestMatchOrEvent: "tickets/nearestMatchOrEvent",
        }),
    }
};
</script>
