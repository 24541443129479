export default {
  namespaced: true,
	state: {
    countryOptions: [
      ['Australia', 'Австралия', 'au', '61', '+.. ... ... ...'],
      ['Austria', 'Австрия', 'at', '43', '+..(...)... ....'],
      ['Azerbaijan', 'Азербайджан', 'az', '994', '+... .. ... .. ..'],
      ['Albania', 'Албания', 'al', '355', '+...(...)... ...'],
      ['Algeria', 'Алжир', 'dz', '213', '+... .. ... ....'],
      ['American Samoa', 'Американское Самоа', 'as', '1684', '+.(...)... ....'],
      ['Anguilla', 'Ангилья', 'ai', '1264', '+.(...)... ....'],
      ['Angola', 'Ангола', 'ao', '244', '+...(...)... ...'],
      ['Andorra', 'Андорра', 'ad', '376', '+... ... ...'],
      ['Antigua and Barbuda', 'Антигуа и Барбуда', 'ag', '1268', '+.(...)... ....'],
      ['Argentina', 'Аргентина', 'ar', '54', '+.. (..) ........'],
      ['Armenia', 'Армения', 'am', '374', '+... .. ... ...'],
      ['Aruba', 'Аруба', 'aw', '297', '+... ... ....'],
      ['Afghanistan', 'Афганистан', 'af', '93', '+.. .. ... ....'],
      ['Bahamas', 'Багамские Острова', 'bs', '1242', '+.(...)... ....'],
      ['Bangladesh', 'Бангладеш', 'bd', '880', '+... .. ... ...'],
      ['Barbados', 'Барбадос', 'bb', '1246', '+.(...)... ....'],
      ['Bahrain', 'Бахрейн', 'bh', '973', '+... .... ....'],
      ['Belarus', 'Беларусь (Белоруссия)', 'by', '375', '+... (..) ... .. ..'],
      ['Belize', 'Белиз', 'bz', '501', '+... ... ....'],
      ['Belgium', 'Бельгия', 'be', '32', '+.. ... .. .. ..'],
      ['Benin', 'Бенин', 'bj', '229', '+... .. .. ....'],
      ['Bermuda', 'Бермудские острова', 'bm', '1441', '+.(...)... ....'],
      ['Bulgaria', 'Болгария', 'bg', '359', '+...(...)... ...'],
      ['Bolivia', 'Боливия', 'bo', '591', '+... . ... ....'],
      ['Bosnia and Herzegovina', 'Босния и Герцеговина', 'ba', '387', '+... .. .....'],
      ['Botswana', 'Ботсвана', 'bw', '267', '+... .. ... ...'],
      ['Brazil', 'Бразилия', 'br', '55', '+.. (..) .........'],
      ['British Virgin Islands', 'Британские Виргинские острова', 'vg', '1284', '+.(...)... ....'],
      ['U.S. Virgin Islands', 'Британские Виргинские острова', 'vi', '1340', '+.(...)... ....'],
      ['Brunei', 'Бруней', 'bn', '673', '+... ... ....'],
      ['Burkina Faso', 'Буркина Фасо', 'bf', '226', '+... .. .. ....'],
      ['Burundi', 'Бурунди', 'bi', '257', '+... .. .. ....'],
      ['Bhutan', 'Бутан', 'bt', '975', '+... .. ... ...'],
      ['Vanuatu', 'Вануату ', 'vu', '678', '+... .. .....'],
      ['Vatican City', 'Ватикан', 'va', '39', '+.. .. .... ....'],
      ['United Kingdom', 'Великобритания', 'gb', '44', '+.. .... ......'],
      ['Hungary', 'Венгрия', 'hu', '36', '+..(...)... ...'],
      ['Venezuela', 'Венесуэла', 've', '58', '+..(...)... ....'],
      ['Vietnam', 'Вьетнам', 'vn', '84', '+.. .. .... ...'],
      ['Gabon', 'Габон', 'ga', '241', '+... . .. .. ..'],
      ['Haiti', 'Гаити', 'ht', '509', '+... ....-....'],
      ['Guyana', 'Гайана', 'gy', '592', '+... ... ....'],
      ['Gambia', 'Гамбия', 'gm', '220', '+...(...).. ..'],
      ['Ghana', 'Гана', 'gh', '233', '+...(...)... ...'],
      ['Guadeloupe', 'Гваделупа', 'gp', '590', ''],
      ['Guatemala', 'Гватемала', 'gt', '502', '+... .... ....'],
      ['Guinea', 'Гвинея', 'gn', '224', '+... .. ... ...'],
      ['Guinea-Bissau', 'Гвинея-Бисау', 'gw', '245', '+... . ......'],
      ['Germany', 'Германия', 'de', '49', '+.. .... ........'],
      ['Gibraltar', 'Гибралтар', 'gi', '350', '+... ... .....'],
      ['Honduras', 'Гондурас', 'hn', '504', '+... .... ....'],
      ['Hong Kong', 'Гонконг', 'hk', '852', '+... .... ....'],
      ['Grenada', 'Гренада', 'gd', '1473', '+.(...)... ....'],
      ['Greenland', 'Гренландия', 'gl', '299', '+... .. .. ..'],
      ['Greece', 'Греция', 'gr', '30', '+..(...)... ....'],
      ['Georgia', 'Грузия', 'ge', '995', '+... (...) ... ...'],
      ['Guam', 'Гуам', 'gu', '1671', '+.(...)... ....'],
      ['Denmark', 'Дания', 'dk', '45', '+.. .. .. .. ..'],
      ['Djibouti', 'Джибути', 'dj', '253', '+... .. .. .. ..'],
      ['Dominica', 'Доминика', 'dm', '1767', '+.(...)... ....'],
      ['Dominican Republic', 'Доминиканская Республика', 'do', '1', ''],
      ['Egypt', 'Египет', 'eg', '20', '+..(...)... ....'],
      ['Zambia', 'Замбия', 'zm', '260', '+... .. ... ....'],
      ['Zimbabwe', 'Зимбабве', 'zw', '263', '+... . ......'],
      ['Israel', 'Израиль ', 'il', '972', '+... ... ... ....'],
      ['India', 'Индия', 'in', '91', '+.. ..... .....'],
      ['Indonesia', 'Индонезия ', 'id', '62', '+..(...)... ....'],
      ['Jordan', 'Иордания', 'jo', '962', '+... . .... ....'],
      ['Iraq', 'Ирак', 'iq', '964', '+...(...)... ....'],
      ['Iran', 'Иран', 'ir', '98', '+..(...)... ....'],
      ['Ireland', 'Ирландия', 'ie', '353', '+... .. .......'],
      ['Iceland', 'Исландия', 'is', '354', '+... ... ....'],
      ['Spain', 'Испания', 'es', '34', '+.. ... ... ...'],
      ['Italy', 'Италия', 'it', '39', '+.. ... .......'],
      ['Yemen', 'Йемен ', 'ye', '967', '+... ... ... ...'],
      ['Cape Verde', 'Кабо-Верде', 'cv', '238', '+...(...).. ..'],
      ['Kazakhstan', 'Казахстан', 'kz', '7', '+. ... ... .. ..'],
      ['Cayman Islands', 'Каймановы острова', 'ky', '1345', '+.(...)... ....'],
      ['Cambodia', 'Камбоджа', 'kh', '855', '+... .. ... ...'],
      ['Cameroon', 'Камерун', 'cm', '237', '+... .... ....'],
      ['Canada', 'Канада', 'ca', '1', '+. (...) ...-....'],
      ['Caribbean Netherlands', 'Карибские Нидерланды', 'bq', '599', ''],
      ['Qatar', 'Катар', 'qa', '974', '+... .... ....'],
      ['Kenya', 'Кения', 'ke', '254', '+... ... ......'],
      ['Cyprus', 'Кипр', 'cy', '357', '+... .. ......'],
      ['Kyrgyzstan', 'Киргизия', 'kg', '996', '+... (...) ... ...'],
      ['Kiribati', 'Кирибати', 'ki', '686', '+... .. ...'],
      ['China', 'Китай', 'cn', '86', '+.. ..-.........'],
      ['Colombia', 'Колумбия', 'co', '57', '+..(...)... ....'],
      ['Comoros', 'Коморы', 'km', '269', '+... .. .....'],
      ['Congo', 'Конго', 'cd', '243', '+... .. .. .....'],
      ['Congo', 'Конго', 'cg', '242', '+... .. .. .....'],
      ['North Korea', 'Корейская НДР', 'kp', '850', '+... ... ...'],
      ['Costa Rica', 'Коста-Рика', 'cr', '506', '+... .... ....'],
      ['Côte d’Ivoire', 'Кот-д’Ивуар', 'ci', '225', '+... .. ... ...'],
      ['Cuba', 'Куба', 'cu', '53', '+.. . ... ....'],
      ['Kuwait', 'Кувейт', 'kw', '965', '+... .... ....'],
      ['Curaçao', 'Кюрасао', 'cw', '599', ''],
      ['Laos', 'Лаос', 'la', '856', '+...(....)... ...'],
      ['Latvia', 'Латвия', 'lv', '371', '+... .. ... ...'],
      ['Lesotho', 'Лесото', 'ls', '266', '+... . ... ....'],
      ['Liberia', 'Либерия', 'lr', '231', '+... .. ... ...'],
      ['Lebanon', 'Ливан ', 'lb', '961', '+... .. ... ...'],
      ['Libya', 'Ливия', 'ly', '218', '+... .. ... ...'],
      ['Lithuania', 'Литва', 'lt', '370', '+...(...).. ...'],
      ['Liechtenstein', 'Лихтенштейн', 'li', '423', '+...(...)... ....'],
      ['Luxembourg', 'Люксембург', 'lu', '352', '+...(...)... ...'],
      ['Mauritius', 'Маврикий', 'mu', '230', '+... ... ....'],
      ['Mauritania', 'Мавритания', 'mr', '222', '+... .. .. ....'],
      ['Madagascar', 'Мадагаскар', 'mg', '261', '+... .. .. .....'],
      ['Macau', 'Макао', 'mo', '853', '+... .... ....'],
      ['Malawi', 'Малави', 'mw', '265', '+... . ... ...'],
      ['Malaysia', 'Малайзия ', 'my', '60', '+.. .. .... ....'],
      ['Mali', 'Мали', 'ml', '223', '+... .. .. ....'],
      ['Maldives', 'Мальдивские острова', 'mv', '960', '+... ... ....'],
      ['Malta', 'Мальта', 'mt', '356', '+... .... ....'],
      ['Morocco', 'Марокко', 'ma', '212', '+... .. .... ...'],
      ['Martinique', 'Мартиника', 'mq', '596', '+...(...).. .. ..'],
      ['Marshall Islands', 'Маршалловы Острова', 'mh', '692', '+... ... ....'],
      ['Mexico', 'Мексика', 'mx', '52', '+..(...)... ....'],
      ['Mozambique', 'Мозамбик', 'mz', '258', '+... .. ... ...'],
      ['Moldova', 'Молдова', 'md', '373', '+... (..) .. .. ..'],
      ['Monaco', 'Монако', 'mc', '377', '+...(...)... ...'],
      ['Mongolia', 'Монголия', 'mn', '976', '+... .. .. ....'],
      ['Montserrat', 'Монтсеррат', 'ms', '1664', '+.(...)... ....'],
      ['Myanmar', 'Мьянма', 'mm', '95', ''],
      ['Namibia', 'Намибия', 'na', '264', '+... .. ... ....'],
      ['Nauru', 'Науру', 'nr', '674', '+... ... ....'],
      ['Nepal', 'Непал', 'np', '977', '+... .. ... ...'],
      ['Niger', 'Нигер', 'ne', '227', '+... .. .. ....'],
      ['Nigeria', 'Нигерия', 'ng', '234', '+...(...)... ....'],
      ['Netherlands', 'Нидерланды', 'nl', '31', '+.. .. ........'],
      ['Nicaragua', 'Никарагуа', 'ni', '505', '+... .... ....'],
      ['Niue', 'Ниуэ', 'nu', '683', '+... ....'],
      ['New Zealand', 'Новая Зеландия', 'nz', '64', '+.. ... ... ....'],
      ['New Caledonia', 'Новая Каледония', 'nc', '687', '+... .. ....'],
      ['Norway', 'Норвегия', 'no', '47', '+.. ... .. ...'],
      ['Norfolk Island', 'Норфолк (остров)', 'nf', '672', '+... ... ...'],
      ['United Arab Emirates', 'Объединенные Арабские Эмираты', 'ae', '971', '+... .. ... ....'],
      ['Oman', 'Оман', 'om', '968', '+... .. ... ...'],
      ['Saint Helena', 'Остров Святой Елены', 'sh', '290', '+... ....'],
      ['Cook Islands', 'Острова Кука', 'ck', '682', '+... .. ...'],
      ['Pakistan', 'Пакистан', 'pk', '92', '+.. ...-.......'],
      ['Palau', 'Палау', 'pw', '680', '+... ... ....'],
      ['Palestine', 'Палестина', 'ps', '970', '+... .. ... ....'],
      ['Panama', 'Панама', 'pa', '507', '+... ... ....'],
      ['Papua New Guinea', 'Папуа-Новая Гвинея', 'pg', '675', '+...(...).. ...'],
      ['Paraguay', 'Парагвай', 'py', '595', '+...(...)... ...'],
      ['Peru', 'Перу', 'pe', '51', '+..(...)... ...'],
      ['Poland', 'Польша', 'pl', '48', '+.. ... ... ...'],
      ['Portugal', 'Португалия', 'pt', '351', '+... .. ... ....'],
      ['Puerto Rico', 'Пуэрто-Рико', 'pr', '1', ''],
      ['Macedonia', 'Респ. Македония', 'mk', '389', '+... .. ... ...'],
      ['Réunion', 'Реюньон', 're', '262', '+... ..... ...'],
      ['Russia', 'Россия', 'ru', '7', '+. (...) ... .. ..'],
      ['Rwanda', 'Руанда', 'rw', '250', '+...(...)... ...'],
      ['Romania', 'Румыния', 'ro', '40', '+.. .. ... ....'],
      ['United States', 'США', 'us', '1', '+. (...) ...-....'],
      ['El Salvador', 'Сальвадор', 'sv', '503', '+... ....-....'],
      ['Samoa', 'Самоа', 'ws', '685', '+... .. ....'],
      ['San Marino', 'Сан-Марино', 'sm', '378', '+... .... ......'],
      ['Saudi Arabia', 'Саудовская Аравия ', 'sa', '966', '+... . .... ....'],
      ['Swaziland', 'Свазиленд', 'sz', '268', '+... .. .. ....'],
      ['Northern Mariana Islands', 'Северные Марианские острова Сайпан', 'mp', '1670', '+.(...)... ....'],
      ['Seychelles', 'Сейшелы', 'sc', '248', '+... . ... ...'],
      ['Saint Barthélemy', 'Сен-Бартелеми', 'bl', '590', '+... .. ....'],
      ['Saint Martin', 'Сен-Мартен', 'mf', '590', ''],
      ['Saint Pierre and Miquelon', 'Сен-Пьер и Микелон', 'pm', '508', '+... .. ....'],
      ['Senegal', 'Сенегал', 'sn', '221', '+... .. ... ....'],
      ['Saint Vincent and the Grenadines', 'Сент-Винсент и Гренадины', 'vc', '1784', '+.(...)... ....'],
      ['Saint Kitts and Nevis', 'Сент-Китс и Невис', 'kn', '1869', '+.(...)... ....'],
      ['Saint Lucia', 'Сент-Люсия', 'lc', '1758', '+.(...)... ....'],
      ['Serbia', 'Сербия', 'rs', '381', '+... .. ... ....'],
      ['Singapore', 'Сингапур', 'sg', '65', '+.. ....-....'],
      ['Sint Maarten', 'Синт-Маартен', 'sx', '1721', '+.(...)... ....'],
      ['Syria', 'Сирия', 'sy', '963', '+... .. .... ...'],
      ['Slovakia', 'Словакия', 'sk', '421', '+...(...)... ...'],
      ['Slovenia', 'Словения', 'si', '386', '+... .. ... ...'],
      ['Solomon Islands', 'Соломоновы Острова ', 'sb', '677', '+... ... ....'],
      ['Somalia', 'Сомали', 'so', '252', '+... .. ... ...'],
      ['Sudan', 'Судан', 'sd', '249', '+... .. ... ....'],
      ['Suriname', 'Суринам', 'sr', '597', '+... ... ....'],
      ['Sierra Leone', 'Сьерра-Леоне', 'sl', '232', '+... .. ......'],
      ['Tajikistan', 'Таджикистан', 'tj', '992', '+... .. ... ....'],
      ['Thailand', 'Таиланд ', 'th', '66', '+.. .. ... ....'],
      ['Taiwan', 'Тайвань', 'tw', '886', '+... . .... ....'],
      ['Tanzania', 'Танзания', 'tz', '255', '+... .. ... ....'],
      ['Togo', 'Того', 'tg', '228', '+... .. ... ...'],
      ['Tokelau', 'Токелау', 'tk', '690', '+... ....'],
      ['Tonga', 'Тонга', 'to', '676', '+... .....'],
      ['Trinidad and Tobago', 'Тринидад и Тобаго', 'tt', '1868', '+.(...)... ....'],
      ['Tuvalu', 'Тувалу ', 'tv', '688', '+... ......'],
      ['Tunisia', 'Тунис', 'tn', '216', '+... .. ... ...'],
      ['Turkmenistan', 'Туркменистан', 'tm', '993', '+... . ... ....'],
      ['Turkey', 'Турция', 'tr', '90', '+.. ... ... .. ..'],
      ['Turks and Caicos Islands', 'Тёркс и Кайкос', 'tc', '1649', '+.(...)... ....'],
      ['Uganda', 'Уганда', 'ug', '256', '+...(...)... ...'],
      ['Uzbekistan', 'Узбекистан', 'uz', '998', '+... .. ... ....'],
      ['Ukraine', 'Украина', 'ua', '380', '+... (..) ... .. ..'],
      ['Wallis and Futuna', 'Уоллис и Футуна', 'wf', '681', '+... .. ....'],
      ['Uruguay', 'Уругвай', 'uy', '598', '+... . ... .. ..'],
      ['Micronesia', 'Ф.Ш. Микронезии', 'fm', '691', '+... ... ...'],
      ['Faroe Islands', 'Фарерские острова', 'fo', '298', '+... ... ...'],
      ['Fiji', 'Фиджи', 'fj', '679', '+... .. .....'],
      ['Philippines', 'Филиппины', 'ph', '63', '+.. .... .......'],
      ['Finland', 'Финляндия', 'fi', '358', '+... .. ... .. ..'],
      ['Falkland Islands', 'Фолклендские острова', 'fk', '500', '+... .....'],
      ['French Guiana', 'Фр. Гвиана', 'gf', '594', '+...-.....-....'],
      ['France', 'Франция', 'fr', '33', '+.. . .. .. .. ..'],
      ['French Polynesia', 'Французская Полинезия (Таити)', 'pf', '689', '+... .. .. ..'],
      ['Croatia', 'Хорватия', 'hr', '385', '+... .. ... ...'],
      ['Central African Republic', 'Центральноафриканская Республика', 'cf', '236', '+... .. .. ....'],
      ['Chad', 'Чад', 'td', '235', '+... .. .. .. ..'],
      ['Montenegro', 'Черногория', 'me', '382', '+... .. ... ...'],
      ['Czech Republic', 'Чехия', 'cz', '420', '+...(...)... ...'],
      ['Chile', 'Чили', 'cl', '56', '+.. . .... ....'],
      ['Switzerland', 'Швейцария', 'ch', '41', '+.. .. ... .. ..'],
      ['Sweden', 'Швеция', 'se', '46', '+.. (...) ...-...'],
      ['Sri Lanka', 'Шри-Ланка', 'lk', '94', '+.. .. ... ....'],
      ['Ecuador', 'Эквадор', 'ec', '593', '+... .. ... ....'],
      ['Equatorial Guinea', 'Экваториальная Гвинея', 'gq', '240', '+... .. ... ....'],
      ['Eritrea', 'Эритрея', 'er', '291', '+... . ... ...'],
      ['Estonia', 'Эстония ', 'ee', '372', '+... .... ......'],
      ['Ethiopia', 'Эфиопия', 'et', '251', '+... .. ... ....'],
      ['South Korea', 'Южная Корея', 'kr', '82', '+.. ... .... ....'],
      ['South Africa', 'Южно-Африканская Респ.', 'za', '27', '+.. .. ... ....'],
      ['South Sudan', 'Южный Судан', 'ss', '211', '+... .. ... ....'],
      ['Jamaica', 'Ямайка', 'jm', '1876', '+.(...)... ....'],
      ['Japan', 'Япония ', 'jp', '81', '+.. .. .... ....'],
    ],
    favoriteFootballerOptions: [],
    teams: [],
    cities: []
	},
	mutations: {
    setFavoriteFootballerOptions(state, value) {
      state.favoriteFootballerOptions = value
    },
    setTeams(state, value) {
      state.teams = value
    },
    setCities(state, value) {
      state.cities = value
    }
	}
}
