import axios from 'axios'

export default {
	namespaced: true,
	state: {
        promocodes: [],

    },
	mutations: {
		seQPromocodes(state, value) {
            state.promocodes = value
        },
	},
    actions: {
        getPromocodes({commit}) {
            return new Promise((resolve, reject) => {
                axios({
                method: 'get',
                url: '/api/loyalty/promocodes ',
                })
                .then(res => {
                    resolve(res)
                    commit('seQPromocodes', res.data.data)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
    }
}
