<template>
    <Cover
        :h1="$translation.tickets.h1"
        :h3="subtitle"
        :isTicketsOrSeasonTicketsPurchased="isTicketsOrSeasonTicketsPurchased"
        :class="{
            'cover--event-cards': isEventCardsVisible,
            'cover--gastrobar':   vipStatus === 'gastrobar',
            'cover--platinum':    vipStatus === 'platinum',
            'cover--gold':        vipStatus === 'gold',
            'cover--vvip':        vipStatus === 'vvip', 
            'cover--vip':         vipStatus === 'vip', 
        }"
    >
        <!-- NEAREST MATCH or EVENT -->
        <template v-if="nearestMatchOrEvent" v-slot:top>
            <!-- top match -->
            <MatchInfo
                v-if="tickets"
                class="cover__match"
                size="lg"
                :match="nearestMatchOrEvent"
                default-logo="ticket-white"
                tickets-header
                :vipStatus="vipStatus"
            />

            <!-- top event -->
            <div
                class="cover__actual-top cover__actual-top--broadcast"
                v-if="false"
            >
                <div class="cover__actual-img-wrap">
                    <div class="cover__actual-img"></div>
                </div>
                <div class="cover__actual-info">
                    <div class="cover__actual-info__type">
                        {{ nearestMatchOrEvent.name }}
                    </div>
                    <div
                        class="cover__actual-info__texts cover__actual-info__texts--broadcast"
                    >
                        <p class="cover__actual-info__text">
                            {{ nearestMatchOrEvent.date }}
                        </p>
                    </div>
                    </div>
            </div>

            <!-- bottom -->
            <div
                class="cover__actual-bottom"
                :class="{ 'cover__actual-bottom--broadcast': true }"
            >
                <!-- btns -->
                <div class="cover__actual-btns">
                    <a 
                        v-if="nearestMatchOrEvent.fanIdRequired" 
                        class="btn cover__actual-btn bordered" 
                        href="#"
                        target="_blank"
                    >госуслуги</a>
                    <a 
                        v-else 
                        class="btn cover__actual-btn bordered" 
                        :href="nearestMatchOrEvent.downloadUrl"
                        target="_blank"
                    >скачать</a>
                    <button 
                        v-if="nearestMatchOrderId"
                        class="btn cover__actual-btn"
                        @click="$emit('openPopup')"
                    >
                        улучшить места
                    </button>
                </div>
                <!-- before game -->
                <p class="cover__actual-left">
                    <span>{{ nearestMatchOrEvent.afterDays }}</span>
                    {{ daysWord }} до события
                </p>
            </div>
            <!-- note -->
            <p
                class="cover__actual-note"
                v-if="nearestMatchOrEvent.fanIdRequired"
            >
                Необходима карта болельщика
            </p>
        </template>

        <!-- Events cards -->
        <EventCards v-if="isEventCardsVisible" />

        <!-- <Banner
            v-if="!nearestMatchOrEvent"
            banner-name="tickets"
            class="cover__poster" 
        /> -->
    </Cover>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Banner from "@web/components/banner/Banner";
import Cover from "@web/components/cover/Cover";
import MatchInfo from "@web/components/tickets/matchInfo/MatchInfo.vue";
import EventCards from "@web/components/tickets/eventCards/EventCards.vue";
import { declOfNum } from "@web/functions/functions.js";

export default {
    components: {
        Banner,
        Cover,
        MatchInfo,
        EventCards,
    },
    props: {
        isFetchError: {
        type: Boolean,
        required: true
        },
        nearestMatchOrderId: {
                type: String,
                required: false
            }
    },
    emits: ["openPopup"],
    computed: {
        ...mapState({
            globalOffers: (store) => store.globalOffers.globalOffers,
        }),
        ...mapGetters({
            tickets: "tickets/tickets",
            nearestMatchOrEvent: "tickets/nearestMatchOrEvent",
            subscriptions: "tickets/subscriptions",
        }),
        subtitle() {
            if (this.nearestMatch && !this.isFetchErrorOrEvent) {
                return `${this.$translation.tickets.cover.youGoToMatch} ${
                    this.nearestMatchOrEvent.afterDays
                        ? `${this.$translation.tickets.cover.after} ${
                              this.nearestMatchOrEvent.afterDays
                          } ${this.declOfNum(
                              this.nearestMatchOrEvent.afterDays,
                              this.$translation.tickets.cover.days
                          )}`
                        : this.$translation.tickets.cover.today
                }`;
            } 
      if (this.isFetchError) {
        return this.$translation.tickets.cover.fetchError
      }
      else {
                return this.$translation.tickets.cover.noTickets;
            }
        },
        isEventCardsVisible() {
            return (
                this.globalOffers &&
                !this.tickets.length &&
                !this.subscriptions.length
            );
        },
        isTicketsOrSeasonTicketsPurchased() {
            return this.tickets.length || this.subscriptions.length;
        },
        daysWord() {
            switch (
                String(this.nearestMatchOrEvent.afterDays).slice(-1)
            ) {
                case "1":
                    return "день";

                case "2":
                case "3":
                case "4":
                    return "дня";

                default:
                    return "дней";
            }
        },
        vipStatus() {
            if (this.nearestMatchOrEvent?.consist[0]?.value?.restaurant === 'BetBoom GastroBar') return 'gastrobar'
            if (this.nearestMatchOrEvent?.consist[0]?.value?.restaurant === 'PLATINUM') return 'platinum'
            if (this.nearestMatchOrEvent?.consist[0]?.value?.restaurant === 'GOLD') return 'gold'
            if (this.nearestMatchOrEvent?.consist[0]?.value?.seat?.slice(0,4) === 'VVIP') return 'vvip'
            if (this.nearestMatchOrEvent?.consist[0]?.value?.seat?.slice(0,4) === 'VIP') return 'vip'

            return 'standard'
        },
    },
    methods: {
        declOfNum,
    },
};
</script>
