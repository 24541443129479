<template>
  <div class="input-wrap">
    <input 
      type="tel" 
      class="input input_phone"
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      ref="phoneInput" 
      :disabled="disabled"
      id="phoneInput"
    >
    <button
      @click.prevent="toggleSelect" 
      :disabled="disabled"
      type="button"
      class="input-wrap__flag-btn"
    >
      <span 
        class="input-wrap__flag"
        :class="isCountryCodeValid ? selectedCountry[2] : 'undefined'"
      ></span>
    </button>
    <ul 
      v-if="isSelectOpen"
      class="input-wrap__country-list"
      @click="selectCountry"
      v-click-outside="closeSelect"
      data-country-list
    >
        <li 
          v-for="(country, index) in countryOptions"
          :key="country[2]"
          :data-index="index"
          class="input-wrap__country"
      >
        <span 
          class="input-wrap__country-flag"
          :class="[country[2]]"
        ></span>
        <span class="input-wrap__country-name">{{ country[1] }}</span>
        <span class="input-wrap__country-code">+{{ country[3] }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Inputmask from 'inputmask'

import { checkArrayFilled } from '@com/functions/validation.js'
import { checkStringsNotEqual } from '@com/functions/validation.js'
/*
  countryOptions - массив с массивами. 
  Каждый массив - это страна. 
  В нём хранятся: 
    название страны на русском, 
    название страны на английском, 
    буквенный код страны, 
    телефонный код страны, 
    шаблон маски
  selectedCountry - текущая выбранная страна
*/

const COUNTRY_CODE = 2 // Индекс буквенного кода страны в массиве
const DIAL_CODE = 3 // Индекс телефонного кода страны в массиве
const MASK = 4 // Индекс шаблона маски страны в массиве

export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    countryCode: {
      type: String,
      required: false,
    }
  },
  emits: ['update:modelValue', 'changeCountry', 'changeMask'],
  data() {
    return {
      isSelectOpen: false,
    }
  },
  computed: {
    ...mapState({
      countryOptions: state => state.defaults.countryOptions,
    }),
    selectedCountry() {
      return this.countryOptions.find(country => country[COUNTRY_CODE] === this.countryCode) || []
    },
    mask() {
      return this.selectedCountry[MASK] || '' 
    },
    isCountryCodeValid() {
      return checkStringsNotEqual(this.countryCode, 'incorrect') && checkArrayFilled(this.selectedCountry)  
    }
  },
  methods: {
    selectCountry(event) {
      if (event.target.closest('.input-wrap__country')) {
        this.$emit('changeCountry', this.countryOptions[event.target.closest('.input-wrap__country').dataset.index][COUNTRY_CODE])
        this.toggleSelect()
      }
    },  
    setMask() {
      if (this.isCountryCodeValid) {
        const selectedDialCode = this.selectedCountry[DIAL_CODE]
        let selectedMask = this.mask

        for (let i = 0; i < selectedDialCode.length; i++) {
          selectedMask = selectedMask.replace('.',  selectedDialCode[i])
        }

        const im = new Inputmask(selectedMask)
        im.definitions['9'] = false
        im.definitions['.'] = '[0-9]'
        im.mask(this.$refs.phoneInput)

        this.$emit('changeMask', this.mask)
      }
    },
    toggleSelect() {
      this.isSelectOpen = !this.isSelectOpen
    },
    closeSelect(event) {
      if (!event.target.closest('.input-wrap__flag-btn')) {
        this.isSelectOpen = false
      }
    }
  },
  watch: {
    mask() {
      this.setMask()
    },
  },
  mounted() {
    this.setMask()
  }
}
</script>
