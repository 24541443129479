<template>
  <div class="orders-popup__total">
    <Item 
      :name="`${total.count} ${declOfNum(total.count, $translation.orders.popup.product)} ${$translation.orders.popup.forPrice}`"
      :value="`${total.sum} ₽`"
    />
    <Item 
      v-if="total.discount > 0"
      :name="$translation.orders.popup.discountsAndBonuses"
      :value="`- ${total.discount} ₽`"
    />
    <Item 
      v-if="total.returnProductDeduction > 0"
      :name="$translation.orders.popup.returns"
      :value="- `${total.returnProductDeduction} ₽`"
    />
    <Item 
      v-if="total.deliveryPrice > 0"
      :name="$translation.orders.popup.delivery"
      :value="`${total.deliveryPrice} ₽`"
    />
    <Item 
      class="orders-popup__total-row_large"
      :name="$translation.orders.popup.total"
      :value="`${total.totalPrice} ₽`"
    />
  </div>
</template>

<script>
import Item from '@web/components/orders/popup/components/total/components/Item'
import { declOfNum } from '@web/functions/functions.js'

export default {
  components: {
    Item
  },
  props: {
    total: {
      type: Object,
      required: true
    }
  },
  methods: {
    declOfNum,
  }
}
</script>
