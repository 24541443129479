<template>
  <Cover
    :h1="$translation.orders.h1"
    :h3="coverSubtitle"
  >
    <div class="cover__cards" v-if="orders.length > 0">
      <Card
        v-for="order in orders" 
        :key="order.id"
        :order="order"
        :is-order-pending="isOrderPending"
        @open-popup="$emit('openPopup', order.id)"
        @cancel-order="$emit('cancelOrder', order.id)"
      />
    </div>
    <Banner 
      v-else
      banner-name="orders"
      class="cover__poster"
    />
  </Cover>
</template>

<script>
import { mapState } from 'vuex'

import Card from '@web/components/orders/active/components/Card'
import Banner from '@web/components/banner/Banner'
import Cover from '@web/components/cover/Cover'

export default {
  components: {
    Card,
    Banner,
    Cover
  },
  props: {
    isOrderPending: {
      type: Boolean,
      required: true
    },
    isFetchError: {
      type: Boolean,
      required: true
    }
  },
  emits: ['openPopup', 'cancelOrder'],
  computed: {
    ...mapState({
      orders: state => state.activeOrders.orders
    }),
    coverSubtitle() {
      if (this.isFetchError) { 
        return this.$translation.orders.active.title.fetchError 
      }
      if (this.orders.length > 0) { 
        return this.$translation.orders.active.title.full 
      }
      else { 
        return this.$translation.orders.active.title.empty 
      }
    }
  }
}
</script>

