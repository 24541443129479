<template>
  <textarea
    class="textarea"
    :value="modelValue" 
    @input="$emit('update:modelValue', $event.target.value)"
  >
  </textarea>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue']
}
</script>
