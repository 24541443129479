<template>
    <div class="'orders-popup__header'">
        <button
            type="button"
            class="orders-popup__back"
            @click="$emit('closePopup')"
        >
            {{ $translation.orders.popup.toList }}
        </button>
        <div
            class="popup__title orders-popup__title"
            v-html="$translation.orders.popup.order + ' №' + id"
        ></div>
        <div>
            <button
                v-if="isNew()"
                type="button"
                class="btn btn_white orders-card__btn orders-popup__cancel-btn"
                :disabled="isOrderPending"
                @click="setCancel(true)"
            >
                {{ $translation.btn.cancel }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        status: {
            type: String,
            required: true,
        },
    },
    emits: ["closePopup", "cancelOrder"],
    computed: {
        ...mapGetters("activeOrders", ["cancel"]),
    },
    methods: {
        ...mapMutations({
            setArchiveOrders: "archiveOrders/setOrders",
            setCancel: "activeOrders/setCancel",
        }),
        ...mapActions({
            getActiveOrders: "activeOrders/getActiveOrders",
            getArchiveOrders: "archiveOrders/getArchiveOrders",
        }),
        isNew() {
            if (this.status === "Новый") {
                return true;
            } else if (this.status === "New") {
                return true;
            }
            return false;
        },
        cancelOrder() {
            this.$emit("cancelOrder");
            this.$emit("closePopup");
            this.getActiveOrders();
            this.getArchiveOrders({
                offset: 0,
                step: 10,
            }).then((res) => {
                this.setArchiveOrders(res);
            });
        },
    },
};
</script>
