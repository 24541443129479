<template>
  <section class="cover" :class="{'cover__pb112': false}">
    <div class="container">
      <div class="cover__top">
        <h1 class="h1 cover__h1" v-html="h1"></h1>
        <div class="h3 cover__h3" v-if="!isTicketsOrSeasonTicketsPurchased"> {{ h3 }} </div>
        <slot name="top"></slot>
      </div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    h1: {
      type: String,
      required: false
    },
    h3: {
      type: String,
      required: false
    },
    isTicketsOrSeasonTicketsPurchased: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>
