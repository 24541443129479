<template>
  <section class="settings__section settings__notifications">
    <h2 class="h2 settings__h2 settings__h2_flex">
      {{ $translation.profile.notifications.h2 }}
      <transition name="fade">
        <span 
          v-show="showMessage"
          class="settings__save-message"
          :class="{
            error: status === 'error',
            success: status === 'success'
          }"
        >
          {{ message }}
        </span>
      </transition>
    </h2>
    <form
      class="relative"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="settings__notifications">
        <div class="settings__row">
          <div class="settings__column settings__column_mrg-b-large">
            <Checkbox
              :text="$translation.profile.notifications.label.allowSms"
              v-model="allowSms"
              :disabled="saveInProgress || $store.state.email.isVip"
              id="smsNotifications" 
              name="smsNotifications"
            />
          </div>
          <div class="settings__column">
            <Checkbox
              :text="$translation.profile.notifications.label.allowEmail"
              v-model="allowEmail"
              :disabled="saveInProgress || $store.state.email.isVip"
              id="emailNotifications" 
              name="emailNotifications"
            />
          </div>
        </div>
      </div>
      <div class="settings__mailing" v-if="mailingCategories.length > 0">
        <h2 class="h2 settings__h2">{{ $translation.profile.notifications.h3 }}</h2>
        <div class="settings__row settings__row_limited">

          <div 
            v-for="cat in mailingCategories"
            :key="cat.id"
            class="settings__column settings__column_half"
          >
            <Checkbox
              :text="cat.title"
              v-model="cat.value"
              :disabled="saveInProgress || $store.state.email.isVip"
            />
          </div>
        </div>
      </div>
      <transition name="fade">
        <div 
          v-show="isChanged"
          class="settings__btns"
        >
          <button
            type="submit"
            class="btn btn_blue"
            :disabled="saveInProgress"
          >
            {{ $translation.btn.save }}
          </button>
          <button
            type="reset"
            class="btn btn_white"
            :disabled="saveInProgress"
          >
            {{ $translation.btn.cancel }}
          </button>
        </div>
      </transition>
      <Preloader
        v-show="saveInProgress"
        :text="$translation.preloader.save"
        class="preloader_form"
      />
    </form>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Checkbox from '@com/components/forms/Checkbox'
import Preloader from '@com/components/preloader/Preloader'

import { checkType } from '@com/functions/validation.js'

export default {
  components: {
    Checkbox,
    Preloader
  },
  data() {
    return {
      allowSms: false,
      allowEmail: false,
      mailingCategories: [],
      saveInProgress: false,
      status: '',
      message: '',
      showMessage: false
    }
  },
  computed: {
    ...mapState({
      allowSmsStore: state => state.notifications.allowSms,
      allowEmailStore: state => state.notifications.allowEmail,
      mailingCategoriesStore: state => state.notifications.mailingCategories,
    }),
    isChanged() {
      return this.allowSms !== this.allowSmsStore ||
             this.allowEmail !== this.allowEmailStore ||
             this.mailingCategories.find(category => category.value !== this.mailingCategoriesStore.find(cat => cat.id === category.id).value)
    }
  },
  methods: {
    ...mapActions({
      updateNotifications: 'notifications/updateNotifications',
      getProfileData: 'profile/getProfileData',
    }),
    reset() {
      this.allowSms = this.allowSmsStore
      this.allowEmail = this.allowEmailStore
      this.resetCategories()
    },
    resetCategories() {
      this.mailingCategories = this.mailingCategoriesStore.map(cat => Object.assign({}, cat))
    },
    onReset() {
      this.validation = false
      this.reset()
    },
    onSubmit() {
      if ((!this.saveInProgress) && this.isChanged) {
        this.saveInProgress = true

        this.updateNotifications({
          allowSms: this.allowSms,
          allowEmail: this.allowEmail,
          mailingCategories: this.mailingCategories
        })
          .then(res => {
            this.message = res.data.message || this.$translation.message.changeSuccess
            this.status = 'success'
            this.getProfileData()
          })
          .catch(error => {
            this.message = error.response.data.message || this.$translation.message.unknownError
            this.status = 'error'
          })
          .finally(()=> {
            const checkIsChanged = setInterval(() => {
              if (!this.isChanged) {
                this.reset()
                this.saveInProgress = false
                this.showMessage = true
                clearInterval(checkIsChanged)
              }
            }, 500);
            setTimeout(() => {
              this.showMessage = false
            }, 3000)
          })
      }
    }
  },
  mounted() {
    this.reset()
  }
}
</script>
