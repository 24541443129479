<template>
  <teleport to="#popup">
    <div class="loyalty-form-popup">
      <div class="loyalty-form-popup__title">
        {{ $translation.loyalty.common.faq.form.popup.title }}
      </div>
      <div 
        v-html="$translation.loyalty.common.faq.form.popup.text"
        class="loyalty-form-popup__text"
      >
      </div>
      <button 
        @click="closePopup"
        class="btn btn_blue loyalty-form-popup__btn"
      >
        {{ $translation.loyalty.common.faq.form.popup.btn }}
      </button>
    </div>
  </teleport>
</template>

<script>
import popupMixin from '@com/mixins/popup'

export default {
  mixins: [popupMixin]
}
</script>
