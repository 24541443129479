<template>
    <div class="active-tickets__column active-tickets__column_large">
        <div class="ticket ticket_ticket">
            <div class="ticket__header ticket__header_ticket">
                <span class="ticket__header-circle">
                    <CircleLeft fill="#ffffff" />
                </span>
                <span class="ticket__header-circle">
                    <CircleRight fill="#ffffff" />
                </span>
                {{ $translation.tickets.tickets.title }}
            </div>
            <div class="ticket__body ticket__body_ticket">
                <div class="ticket__table">
                    <div class="ticket__table-row">
                        <div class="ticket__table-column ticket__table-column--date">
                            {{ $translation.tickets.date }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--time">
                            {{ $translation.tickets.time }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--actions"></div>
                    </div>
                    <div
                        v-for="ticket in tickets"
                        :key="ticket.id"
                        class="ticket__table-row"
                    >
                        <div class="ticket__table-column ticket__table-column--date">
                            {{ date.slice(0, -6) }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--time">
                            {{ date.slice(-5) }}
                        </div>
                        <div class="ticket__table-column ticket__table-column--actions">
                            <div class="ticket__table-actions">
                                <span
                                    v-if="ticket.isChild"
                                    class="ticket__table-children ticket__table-children_ticket"
                                >
                                    {{ $translation.tickets.children }}
                                </span>
                                <a
                                    v-if="ticket.downloadUrl"
                                    :href="ticket.downloadUrl"
                                    target="_blank"
                                    class="ticket__table-download"
                                >
                                    <span
                                        class="ticket__table-download-arrow"
                                    ></span>
                                    <span
                                        class="ticket__table-download-down"
                                    ></span>
                                </a>
                                <a
                                    v-if="ticket.walletUrl"
                                    :href="ticket.walletUrl"
                                    class="ticket__table-folder"
                                >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CircleLeft from "@web/components/tickets/circle/CircleLeft.vue";
import CircleRight from "@web/components/tickets/circle/CircleRight.vue";

export default {
    components: {
        CircleLeft,
        CircleRight,
    },
    props: {
        tickets: {
            type: Array,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
    },
};
</script>
