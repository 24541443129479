<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="footer__column">
        <h4 class="footer__title">
          {{ $translation.footer.contacts.title }}
        </h4>
        <ul class="footer__menu">
          <template
            v-for="item in $translation.footer.contacts.items"
            :key="item.title"
          >
            <li class="footer__menu-item footer__menu-item_title">
              {{ item.title }}
            </li>
            <li class="footer__menu-item">
              <a :href="`tel:${item.phone.link}`">
                {{ item.phone.name }}
              </a>
            </li>
            <li class="footer__menu-item">
              <a :href="`mailto:${item.mail.link}`" target="_blank">
                {{ item.mail.name }}
              </a>
            </li>
          </template>
        </ul>
      </div>
      <div class="footer__column" v-for="section in sections" :key="section.title">
        <h4 class="footer__title">
          {{section.title}}
        </h4>
        <ul class="footer__menu">
          <li
            v-for="item in section.items" 
            :key="item.link"
            class="footer__menu-item"
          >
              <a :href="item.link" target="_blank">{{item.name}}</a>
          </li>
        </ul>
      </div> 
      <div class="footer__column footer__column-app">
        <h4 class="footer__title">
          Скачай мобильное приложение ФК&nbsp;«Динамо» Москва
        </h4>
        <ul class="footer__column-app__list">
          <li>
            <a href="https://redirect.appmetrica.yandex.com/serve/100490034720825249" class="footer__column-app__link" target="_blank">
              <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3026 10.985L3.78066 0.609494L17.1679 8.16722L14.3026 10.985ZM1.03456 0C0.414776 0.318812 0 0.900176 0 1.65501V22.345C0 23.0998 0.414776 23.6812 1.03456 24L13.2681 11.9977L1.03456 0ZM21.3061 10.5771L18.4981 8.97832L15.3658 12.0023L18.4981 15.0264L21.3634 13.4276C22.2215 12.7572 22.2215 11.2475 21.3061 10.5771ZM3.78066 23.3952L17.1679 15.8375L14.3026 13.0197L3.78066 23.3952Z" fill="#fff"/>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://redirect.appmetrica.yandex.com/serve/100490034720825249" class="footer__column-app__link" target="_blank">
              <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3485 4.23525C16.3156 3.1144 16.9674 1.55327 16.789 0C15.3954 0.0530589 13.7094 0.890257 12.7101 2.00979C11.8127 3.0033 11.0294 4.59078 11.24 6.11356C12.7946 6.22895 14.3814 5.35742 15.3485 4.23525ZM18.8346 14.0938C18.8735 18.1089 22.5098 19.4446 22.55 19.4618C22.5205 19.556 21.9692 21.365 20.6346 23.2353C19.4797 24.8509 18.2819 26.46 16.3947 26.4945C14.541 26.5276 13.9441 25.4413 11.8235 25.4413C9.70417 25.4413 9.04156 26.4599 7.2871 26.5275C5.46559 26.5925 4.07732 24.7795 2.91439 23.1692C0.53488 19.8756 -1.28261 13.8617 1.1586 9.80281C2.37115 7.78793 4.53739 6.51005 6.89007 6.47821C8.67805 6.44505 10.3668 7.6311 11.46 7.6311C12.5531 7.6311 14.6054 6.20515 16.7622 6.41474C17.6649 6.45055 20.2 6.76349 21.827 9.04631C21.6956 9.12457 18.8024 10.7378 18.8346 14.0938Z" fill="#fff"/>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://redirect.appmetrica.yandex.com/serve/100490034720825249" class="footer__column-app__link" target="_blank">
              <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.333 7C12.4728 7 9.33301 3.85986 9.33301 0H10.3218C10.3218 3.31433 13.0187 6.0109 16.333 6.0109C19.6473 6.0109 22.3442 3.31433 22.3442 0H23.333C23.333 3.85986 20.1929 7 16.333 7Z" fill="#fff"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6536 17.3591H15.3397L14.4938 15.3918L13.6536 17.3591ZM13.2384 18.3506L12.7381 19.4952H11.5991L14.0212 14.0003H15.0055L17.4178 19.4952H16.2484L15.7546 18.3506H13.2384ZM31.5659 19.4912H32.6667V14H31.5659V19.4912ZM27.1846 17.1323H29.2137V16.1311H27.1846V15.0056H30.1302V14.004H26.0841V19.4949H30.2362V18.4933H27.1846V17.1323ZM22.8107 17.7811L21.5627 14H20.652L19.404 17.7811L18.1894 14.003H17.0019L18.9186 19.4983H19.8418L21.0918 15.8887L22.3419 19.4983H23.2732L25.1848 14.003H24.028L22.8107 17.7811ZM9.91872 17.1475C9.91872 18.0413 9.47482 18.519 8.66871 18.519C7.85822 18.519 7.41195 18.0275 7.41195 17.1093V14.0037H6.29629V17.1475C6.29629 18.6939 7.15574 19.5807 8.65352 19.5807C10.1662 19.5807 11.0334 18.677 11.0334 17.1016V14.0003H9.91872V17.1475ZM3.63458 14.0003H4.74956V19.4986H3.63458V17.2656H1.11566V19.4986H0V14.0003H1.11566V16.2182H3.63458V14.0003Z" fill="#fff"/>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://redirect.appmetrica.yandex.com/serve/388720413338846572" class="footer__column-app__link" target="_blank">
              <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.189 22.8142L25.0376 22.0132C24.6581 21.9024 24.3902 21.5551 24.3767 21.1514L23.9834 9.35101C23.8696 7.78921 22.73 6.54692 21.5392 6.18115C21.4723 6.16057 21.4009 6.18827 21.3613 6.24677C21.321 6.30634 21.3367 6.38864 21.3927 6.43275C21.6869 6.66445 22.4962 7.42021 22.4962 8.72381L22.4939 24.1525C22.4939 25.6422 21.1101 26.7348 19.6829 26.3721L16.4828 25.5588C16.128 25.4326 15.8814 25.0964 15.8685 24.7085L15.4753 12.9074C15.3615 11.3456 14.2219 10.1034 13.0311 9.73749C12.9642 9.71705 12.8928 9.74462 12.8532 9.80312C12.813 9.86269 12.8286 9.94499 12.8846 9.98923C13.1787 10.2208 13.9881 10.9766 13.9881 12.2802L13.9858 25.1407L13.9876 25.141V27.7103C13.9876 29.2 12.6037 30.2928 11.1765 29.93L2.13768 27.6329C0.881033 27.3135 0 26.1699 0 24.8582V9.40547C0 7.91575 1.38372 6.82313 2.81097 7.18581L8.50641 8.63331V5.84765C8.50641 4.35792 9.89012 3.26518 11.3174 3.62799L17.0124 5.07535V2.28969C17.0124 0.799964 18.3961 -0.292786 19.8235 0.0700295L28.8623 2.36714C30.119 2.68652 31 3.83011 31 5.14178V20.5945C31 22.0842 29.6163 23.1769 28.189 22.8142Z" fill="#fff"/>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="footer__rights">
        © 1923—{{currentYear}} {{ $translation.footer.footballСlub }} <br> 
        {{ $translation.footer.copyright }}
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    ...mapState({
      sections: state => state.footer.sections,
    }),
  },
  methods: {
    ...mapActions({
      getFooterChapterList: 'footer/getFooterChapterList'
    }),
  },
  mounted() {
    this.getFooterChapterList()
  }
}
</script>
