export default class ScrollLoader {
  constructor(config) {
    this.section = config.section
    this.callback = config.callback

    this.checkPosition = this.checkPosition.bind(this)

    this.setListeners()
  }

  setListeners() {
    window.addEventListener('scroll', this.checkPosition)
    window.addEventListener('resize', this.checkPosition)
  }

  checkPosition() {
    if (window.innerHeight >= this.section.getBoundingClientRect().top + this.section.offsetHeight) {
      this.callback()
    }
  }
}
