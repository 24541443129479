<template>
  <svg width="34" height="52" viewBox="0 0 34 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1801_11400)">
        <path d="M35 -1H0V53H35V-1Z" fill="white"></path>
        <g filter="url(#filter0_d_1801_11400)">
          <mask id="mask0_1801_11400" style="mask-type:alpha;" maskUnits="userSpaceOnUse" x="8" y="-64" width="300" height="432">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M28 -64C16.9543 -64 8 -55.0457 8 -44V1C21.8071 1 33 12.1929 33 26C33 39.8071 21.8071 51 8 51V348C8 359.046 16.9543 368 28 368H288C299.046 368 308 359.046 308 348V-44C308 -55.0457 299.046 -64 288 -64H28Z" fill="white"></path>
          </mask>
          <g mask="url(#mask0_1801_11400)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M28 -64C16.9543 -64 8 -55.0457 8 -44V1C21.8071 1 33 12.1929 33 26C33 39.8071 21.8071 51 8 51V348C8 359.046 16.9543 368 28 368H288C299.046 368 308 359.046 308 348V-44C308 -55.0457 299.046 -64 288 -64H28Z" :fill="fill"></path>
              <path d="M8 1H7V2H8V1ZM8 51V50H7V51H8ZM9 -44C9 -54.4934 17.5066 -63 28 -63V-65C16.402 -65 7 -55.598 7 -44H9ZM9 1V-44H7V1H9ZM8 2C21.2548 2 32 12.7452 32 26H34C34 11.6406 22.3594 0 8 0V2ZM32 26C32 39.2548 21.2548 50 8 50V52C22.3594 52 34 40.3594 34 26H32ZM9 348V51H7V348H9ZM28 367C17.5066 367 9 358.493 9 348H7C7 359.598 16.402 369 28 369V367ZM288 367H28V369H288V367ZM307 348C307 358.493 298.493 367 288 367V369C299.598 369 309 359.598 309 348H307ZM307 -44V348H309V-44H307ZM288 -63C298.493 -63 307 -54.4934 307 -44H309C309 -55.598 299.598 -65 288 -65V-63ZM28 -63H288V-65H28V-63Z" fill="#EEF0F1"></path>
          </g>
        </g>
    </g>
    <defs>
      <filter id="filter0_d_1801_11400" x="-2" y="-69" width="320" height="452" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
        <feOffset dy="5"></feOffset>
        <feGaussianBlur stdDeviation="5"></feGaussianBlur>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.34902 0 0 0 0 0.631373 0 0 0 0.1 0"></feColorMatrix>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1801_11400"></feBlend>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1801_11400" result="shape"></feBlend>
      </filter>
      <clipPath id="clip0_1801_11400">
        <rect width="34" height="52" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      types: String,
      required: false
    }
  }
}
</script>
