import axios from 'axios'
import { getDeviceId } from '@web/functions/functions.js'

export default {
	namespaced: true,
	state: {
		token: '',
		status: 'loading',
		redirectUrl: ''
	},
	mutations: {
		setToken(state, token) {
			state.token = token
			token ? axios.defaults.headers.common['Authorization'] = 'Bearer ' + token : delete axios.defaults.headers.common['Authorization']
			token ? localStorage.setItem('dynamo_elk_token', token) : localStorage.removeItem('dynamo_elk_token')
		},
		setStatus(state, status) {
			state.status = status
		},
		setRedirectUrl(state, redirectUrl) {
			state.redirectUrl = redirectUrl
		}
	},
	actions: {
		webLogin({commit}, {token}) {
			commit('setToken', token)
			commit('setStatus', 'success')
		},
		webLogout({commit}) {
			commit('setToken', '')
			commit('setStatus', 'error')
		},
		getUser({}, {token} ) {
			return axios({
				method: 'get',
				url: `/api/getCurrentUser`,
				headers: {
					Authorization: 'Bearer ' + token,
				}
			})
		},
		webAuth({}, {id, state}) {
			return axios.get(`/api/oauth/getToken`, {
				params: {
					id: id,
                    state: state
				},
				headers: {
					'device-id': getDeviceId()
				},
			})
		},
		webDeAuth() {
		return axios({
			method: 'get',
			url: `/api/logout`
			})
		},
		webManzanaDeAuth({}, {url}) {
			return axios({
				method: 'post',
				withCredentials: true,
				url: String(url),
				baseURL: '',
				headers: {
					Accept: 'application/json, text/plain, */*'
				},
				transformRequest: [function (data, headers) {
					if (headers.common && headers.common['Authorization']) {
						delete headers.common['Authorization'];
					}
					return data;
				}],
			})
		},
		authorizeRedirect({state}) {
			window.location.href = state.redirectUrl
		},
	}
}
