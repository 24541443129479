<template>
    <div class="loyalty__cards" ref="cardsContainer">
        <template v-for="(item, index) in items" :key="item.id">
            <Card
                :item="item"
                @open-popup="$emit('openPopup', item)"
                :is-partner="isPartner"
            />
            <div
                v-if="(index + 1) % onWhatCountAd === 0 && index + 1 <= 40"
                class="loyalty__banner"
            >
                <section class="banner banner--list">
                    <div class="banner__inner">
                        <AdFoxBannerClone
                            :bannerType="`${bannerType}1`"
                            :bannerCloneCount="index + 1"
                            :onWhatCountAd="onWhatCountAd"
                        />
                    </div>
                </section>
            </div>
        </template>
        <div class="loyalty__banner">
            <AdFoxBanner :bannerType="`${bannerType}2`" />
        </div>
    </div>
</template>

<script>
import ScrollLoader from "@web/classes/ScrollLoader";
import AdFoxBanner from "@web/components/adfox/AdFoxBanner";
import AdFoxBannerClone from "@web/components/adfox/AdFoxBannerClone";
import Card from "@web/components/loyalty/cards/components/Card.vue";

export default {
    components: {
        AdFoxBanner,
        AdFoxBannerClone,
        Card,
    },
    props: {
        items: {
            type: Array,
            required: false,
        },
        isPartner: {
            type: Boolean,
            required: false,
        },
        bannerType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            onWhatCountAd: 8,
        };
    },
    emits: ["scrollEnd", "openPopup"],
    methods: {
        changeOnWhatCountAd() {
            const mediaQuery = window.matchMedia("(max-width: 479px)");
            if (mediaQuery.matches) {
                this.onWhatCountAd = 3;
            } else {
                this.onWhatCountAd = 8;
            }
        },
    },
    mounted() {
        this.changeOnWhatCountAd();
        window.addEventListener("resize", this.changeOnWhatCountAd);
        new ScrollLoader({
            section: this.$refs.cardsContainer,
            callback: () => this.$emit("scrollEnd"),
        });
    },
    destroyed() {
        window.removeEventListener("resize", this.changeOnWhatCountAd);
    },
};
</script>
