<template>
  <div 
    class="loyalty-popup__text-widget"
    v-if="data.texts" 
    v-html="data.texts"
  >
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: false
    }
  }
}
</script>
