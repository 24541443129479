<template>
  <div class="orders-popup__footer">
    <div>
      {{ $translation.orders.popup.haveQuestions }}
    </div>
    <div>
      {{ $translation.orders.popup.phone }}  <a href="tel:+74956476553">+7 (495) 647-65-53</a> . {{ $translation.orders.popup.everyDay }}
    </div>
  </div>
</template>

<script>
</script>
