<template>
  <router-view/>
  <Popup />
  <Alert
    name="alert"
  />
</template>

<script>
import { computed } from 'vue'
import Popup from '@com/components/popup/Popup'
import Alert from '@com/components/popup/components/Alert'

export default {
  components: {
    Popup,
    Alert
  },
  provide() {
    return {
      windowWidth: computed(() => this.windowWidth)  
    }
  },
  data() {
    return {
      windowWidth: 0
    }
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth
    }
  },
  created() {
    this.getWindowWidth()
    window.addEventListener('resize', this.getWindowWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.getWindowWidth)
  }
}
</script>
