<template>
  <section class="settings__section">
    <h2 class="h2 settings__h2 settings__h2_flex">
      {{ $translation.profile.fanId.h2 }}
      <transition name="fade">
        <span
          v-show="showMessage"
          class="settings__save-message"
          :class="{
            error: status === 'error',
            success: status === 'success'
          }"
          v-html="message"
        >
        </span>
      </transition>
    </h2>
<!--    <div class="settings__text">-->
<!--      <a -->
<!--        :href="fanIdDate ? 'https://fcdm.ru/fanid/#perks' : 'https://fcdm.ru/fanid/'" -->
<!--        target="_blank" -->
<!--        class="underline"-->
<!--      >-->
<!--        {{ fanIdDate ? $translation.profile.fanId.present : $translation.profile.fanId.apply }}-->
<!--      </a>-->
<!--    </div>-->
    <form
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
      class="relative"
    >
      <div class="settings__row settings__row_no-mrg">
        <div class="settings__column">
          <a 
            v-if="userFromList"
            :href="`https://oauth.fcdm.ru/abik-fanid/${userFromList}`" 
            target="_blank" 
            class="btn btn_blue"
          >
            {{ $translation.profile.fanId.bindSubscriptions }}
          </a>
          <FormItem
            v-else
            :isError="!isValid && validation"
            :label="$translation.profile.fanId.label"
            :errorMessage="$translation.profile.fanId.error"
          >
            <NumberInput
              id="fanIdInput"
              v-model.trim="fanID"
              :disabled="saveInProgress || $store.state.email.isVip"
            />
          </FormItem>
        </div>
      </div>
      <transition name="fade">
        <div
          v-show="isChanged"
          class="settings__btns"
        >
          <button
            type="submit"
            class="btn btn_blue"
            :disabled="(!isValid && validation) || saveInProgress"
          >
            {{ $translation.btn.save }}
          </button>
          <button
            type="reset"
            class="btn btn_white"
            :disabled="saveInProgress"
          >
            {{ $translation.btn.cancel }}
          </button>
        </div>
      </transition>
      <Preloader
        v-show="saveInProgress"
        :text="$translation.preloader.save"
        class="preloader_form"
      />
    </form>
    <section class="banner banner--mt">
      <div class="banner__inner">
        <AdFoxBanner bannerType="profile1" shortBanner />
        <AdFoxBanner bannerType="profile2" shortBanner />
      </div>
    </section>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import NumberInput from '@com/components/forms/NumberInput'
import Preloader from '@com/components/preloader/Preloader'
import AdFoxBanner from '@web/components/adfox/AdFoxBanner'
import FormItem from '@com/components/forms/FormItem'
import { checkStringFilled } from '@com/functions/validation.js'

export default {
  components: {
    Preloader,
    NumberInput,
    AdFoxBanner,
    FormItem
  },
  data() {
    return {
      fanID: '',
      saveInProgress: false,
      validation: false,
      status: '',
      message: '',
      showMessage: false
    }
  },
  computed: {
    ...mapState({
      fanIDStore: state => state.fanID.fanID,
      fanIdDate: state => state.fanID.fanIdDate,
      userFromList: state => state.fanID.userFromList,
    }),
    isChanged() {
      return +this.fanID !== +this.fanIDStore
    },
    isValid() {
      return checkStringFilled(this.fanID)
    }
  },
  methods: {
    ...mapActions({
      updateFanID: 'fanID/updateFanID',
      getProfileData: 'profile/getProfileData',
    }),
    reset() {
      this.fanID = this.fanIDStore
    },
    onReset() {
      this.validation = false
      this.reset()
    },
    onSubmit() {
      if ((!this.saveInProgress) && this.isChanged) {
        if (this.isValid) {
          this.saveInProgress = true

          this.updateFanID({
            fanID: this.fanID
          })
            .then(res => {
              this.reset()
              this.message = res.data.message || this.$translation.message.changeSuccess
              this.status = 'success'
              this.getProfileData()
            })
            .catch(error => {
              this.message = error.response.status === 409 ? this.$translation.profile.fanId.exists : error.response.data.message || this.$translation.message.unknownError
              this.status = 'error'
            })
            .finally(()=> {
              this.saveInProgress = false
              this.validation = false
              this.showMessage = true
              setTimeout(() => this.showMessage = false, 3000)
            })

        } else {
          this.validation = true
        }
      }
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

