<template>
  <Preloader
    v-if="isPending"
    :text="$translation.preloader.loading"
    class="preloader_large"
  />
  <template v-else>
    <Header>
      <h1 class="loyalty__title">
        {{ $translation.loyalty.partners.title }}
      </h1>
    </Header>
    <Partners 
      :partners="partners"
      @load-more="onLoadMore"
    />
    <UpButton 
      section="[data-loyalty-header]"
      class="loyalty__up-btn"
    />
  </template>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Header from '@web/components/loyalty/Header.vue'
import Partners from '@web/components/loyalty/partners/Partners.vue'
import Preloader from '@com/components/preloader/Preloader'
import UpButton from '@com/components/forms/UpButton'

export default {
  components: {
    Preloader,
    Header,
    Partners,
    UpButton
  },
  data() {
    return {
      isPending: true,
      isLoading: false,
      page: 1
    }
  },
  computed: {
    ...mapState({
      partners: state => state.loyaltyPartners.partners,
      pageCount: state => state.loyaltyPartners.pageCount,
    }),
  },
  methods: {
    ...mapMutations({
      addPartners: 'loyaltyPartners/addPartners',
      setPartners: 'loyaltyPartners/setPartners',
    }),
    ...mapActions({
      getPartners: 'loyaltyPartners/getPartners'
    }),
    onLoadMore() {
      if (!this.isLoading && this.pageCount > this.page) {
        this.isLoading = true
       
        this.getPartners({
          page: this.page + 1
        })
          .then(res => {
            this.page++
            this.addPartners(res.data.data.partners)
          })
          .finally(() => {
            this.isLoading = false
          })
      }  
    }
  },
  created() {
    this.getPartners({
      page: this.page
    })
      .then(res => {
        this.setPartners(res.data.data.partners)
      })
      .finally(() => {
        this.isPending = false
      })
  }
}
</script>
