<template>
  <component
    v-for="widget in widgets"
    :key="widget.id"
    :is="widget.name"
    :data="widget.data"
  ></component>
</template>

<script>
import WidgetEditor from '@web/components/loyalty/popup/widgets/components/WidgetEditor.vue'
import WidgetImage from '@web/components/loyalty/popup/widgets/components/WidgetImage.vue'

export default {
  components: {
    widgetText: WidgetEditor,
    widgetList: WidgetEditor,
    widgetImage: WidgetImage
  },
  props: {
    widgets: {
      type: Array,
      required: false
    }
  },
}
</script>
