import axios from 'axios'

export default {
  namespaced: true,
  actions: {
    getBanner({}, {position}) {
      return axios({
        method: 'get',
        url: `/api/getBanners/${position}`
      })
    }
  }
}
