<template>
    <div class="popup popup_orders" @click="closePopupClickHandler">
        <div class="popup__body popup__body_orders">
            <div
                data-popup-wrapper
                class="popup__content popup__content_orders"
            >
                <div class="orders-popup">
                    <Header
                        :id="order.id"
                        :status="order.statusTitle"
                        @close-popup="$emit('closePopup')"
                        @cancel-order="$emit('cancelOrder', order.id)"
                    />
                    <Preloader
                        v-if="isOrderPending"
                        :text="$translation.preloader.loading"
                        class="preloader_large"
                    />
                    <template v-else>
                        <Data :order="order" />
                        <Composition v-if="order.list" :list="order.list" />
                        <Total v-if="order.total" :total="order.total" />
                        <Footer />
                    </template>
                </div>
                <button
                    class="popup__close popup__close_orders"
                    type="button"
                    @click="$emit('closePopup')"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Data from "@web/components/orders/popup/components/data/Data.vue";
import Composition from "@web/components/orders/popup/components/composition/Composition.vue";
import Total from "@web/components/orders/popup/components/total/Total.vue";
import Footer from "@web/components/orders/popup/components/footer/Footer.vue";
import Header from "@web/components/orders/popup/components/header/Header.vue";
import Preloader from "@com/components/preloader/Preloader";

export default {
    components: {
        Preloader,
        Data,
        Composition,
        Total,
        Footer,
        Header,
    },
    emits: ["closePopup", "cancelOrder"],
    props: {
        isOrderPending: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters("activeOrders", ["cancel"]),
        ...mapState({
            order: (state) => state.orders.currentOrder,
        }),
    },
    methods: {
        closePopupClickHandler(event) {
            if (!event.target.closest("[data-popup-wrapper]")) {
                this.$emit("closePopup");
            }
        },
        cancelOrder() {
            this.$emit("cancelOrder", this.order.id);
            this.$emit("closePopup");
        },
    },
};
</script>
