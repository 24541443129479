<template>
  <div class="tickets-story">
    <div class="container">
      <!-- HEADING -->
      <Heading
        class="heading_tickets-story"
        :title="$translation.tickets.story.title" 
        :amount="`${itemsCount} ${declOfNum(itemsCount, $translation.tickets.story.order)}`"
      >
        <ul class="heading__sort">
          <li 
            v-for="option in typeOptions"
            :key="option.value"
            class="heading__sort-item"
            :class="{
              'active': option.value === type,
              'disabled': isPending
            }"
            @click="changeType(option.value)"
          >
            {{ option.name }}
          </li>
        </ul>
      </Heading>

      <!-- LIST -->
      <div class="tickets-story__items relative">
        <Preloader
          v-show="isPending"
          :text="$translation.preloader.loading"
          class="preloader_absolute preloader_middle preloader_pagination"
        />
        <Card
          v-for="item in items"
          :key="item.id" 
          :order="item"
          @cancel="refresh"
          :type="item.events[0].consist[0].type"
        />
      </div>
      <Pagination 
        modificator="pagination_mt"
        :step-mobile="stepMobile"
        :page-mobile="pageMobile"
        :step-desktop="stepDesktop"
        :page-desktop="pageDesktop"
        :disabled="isPending"
        :items-count="itemsCount"
        @change-step="changeStep"
        @change-page="changePage"
        @show-more="showMore"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Card from '@web/components/tickets/story/components/Card'
import Heading from '@web/components/heading/Heading'
import { declOfNum } from '@web/functions/functions.js'
import pagination from '@web/mixins/pagination'

export default {
  mixins: [pagination],
  components: {
    Card,
    Heading
  },
  data() {
    return {
      typeOptions: [
        {
          name: this.$translation.tickets.story.allOrders,
          value: ''
        },
        {
          name: this.$translation.tickets.tickets.title,
          value: 'TICKET'
        },
        {
          name: this.$translation.tickets.subscriptions.title,
          value: 'SEASON_TICKET'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.archiveTickets.tickets,
      itemsCount: state => state.archiveTickets.ticketsCount
    })
  },
  methods: {
    ...mapMutations({
      setItems: 'archiveTickets/setTickets'
    }),
    ...mapActions({
      getItems: 'archiveTickets/getArchiveTickets'
    }),
    declOfNum
  }
}
</script>
