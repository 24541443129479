<template>
  <Preloader 
    v-if="isPending"
    :text="$translation.preloader.loading"
    class="preloader_large"
  />
  <div 
    v-if="isPage"
    class="static" 
  >
    <div class="container">
      <div class="static__inner">
        <div class="static__content">
          <h1>{{ currentPage.name || '' }}</h1>
          <WidgetsRenderer 
            :widgets="currentPage.widget || []" 
          />
        </div>
        <section class="static__banner">
          <AdFoxBanner bannerType="staticPage" />
        </section>
      </div>
    </div>
  </div>
  <NotFound 
    v-if="isError"
  />
</template>

<script>
import { mapState } from 'vuex'
import { mapActions } from 'vuex'

import WidgetsRenderer from '@web/components/widgets/WidgetsRenderer'
import NotFound from '@web/components/notFound/NotFound'
import Preloader from '@com/components/preloader/Preloader'
import AdFoxBanner from '@web/components/adfox/AdFoxBanner'

export default {
  name: 'staticPage',
  components: {
    WidgetsRenderer,
    NotFound,
    Preloader,
    AdFoxBanner,
  },
  data() {
    return {
      currentPage: {},
      isPending: true,
      isPage: false,
      isError: false
    }
  },
  computed: {
    ...mapState({
      routes: state => state.pages.routes
    }),
    route() {
      return this.$route
    }
  },
  methods: {
    ...mapActions({
      getStaticPage: 'pages/getStaticPage',
      getRoutes: 'pages/getRoutes'
    }),
    loadPage() {
      this.isPending = true
      this.isPage = false
      this.isError = false

      if (this.routes.length) {
        this.fetchPage()
      } else {
        this.getRoutes()
          .finally(()=> {
            this.fetchPage()
          })
      }
    },
    fetchPage() {
      if (this.routes.find(route => route === this.$route.fullPath.replace(/\\|\//g,''))) {
        this.getStaticPage({
          staticPage: this.$route.fullPath.replace(/\\|\//g,'')
        })
          .then(res => {
            this.currentPage = res.data.data
            this.isPage = true
            this.isPending = false
          })
          .catch(() => {
            this.isError = true
            this.isPending = false
          })
      } else {
        this.isError = true
        this.isPending = false
      }
    }
  },
  watch: {
    route() {
      this.loadPage()
    }
  },
  mounted() {
    this.loadPage()
  }
}
</script>
