<template>
  <div class="resources">
    <div 
      v-for="item in resources"
      :key="item.name"
      class="resource resources__item"
      :class="`resource_${item.name}`"
    >
      <picture 
        class="resource__picture"
        :class="`resource__picture_${item.name}`"
      >
        <img 
          :src="item.img" 
          alt=""
        >
      </picture>
      <div 
        v-html="item.title"
        class="resource__title"
      >
      </div>
      <a 
        :href="item.btnLink" 
        target="_blank"
        class="btn btn_large btn_white resource__btn"
      >
        {{ item.btnText }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resources: [
        {
          name: 'shop',
          img: require('@web/assets/img/resources/shop.png'),
          title: this.$translation.loyalty.common.resources.shop.title,
          btnLink: 'https://shop.fcdm.ru/',
          btnText: this.$translation.loyalty.common.resources.shop.btn
        },
        {
          name: 'tickets',
          img: require('@web/assets/img/resources/tickets.png'),
          title: this.$translation.loyalty.common.resources.tickets.title,
          btnLink: 'https://tickets.fcdm.ru/',
          btnText: this.$translation.loyalty.common.resources.tickets.btn
        },
      ]
    }
  }
}
</script>
