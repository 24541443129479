<template>
    <div class="popup popup_orders" @click="closePopupClickHandler">
        <div class="popup__body popup__body_orders">
            <div
                data-popup-wrapper
                class="popup__content popup__content_orders"
            >
                <div class="orders-popup">
                    <div class="'orders-popup__header'">
                        <button
                            type="button"
                            class="orders-popup__back"
                            @click="setCancel(false)"
                        >
                            {{ $translation.orders.popup.toList }}
                        </button>
                        <div
                            class="popup__title orders-popup__title"
                            v-html="
                                $translation.tickets.story.popup.title.cancel
                                    .isNotCanceled
                            "
                        ></div>
                        <div class="orders-popup__header-cancel">
                            <button
                                type="button"
                                class="btn btn_white orders-card__btn orders-popup__cancel-btn"
                                :disabled="isOrderPending"
                                @click="cancelOrder()"
                            >
                                {{ $translation.tickets.story.cancel }}
                            </button>
                        </div>
                    </div>
                </div>
                <button
                    class="popup__close popup__close_orders"
                    type="button"
                    @click="setCancel(false)"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import Data from "@web/components/orders/popup/components/data/Data.vue";
import Composition from "@web/components/orders/popup/components/composition/Composition.vue";
import Total from "@web/components/orders/popup/components/total/Total.vue";
import Footer from "@web/components/orders/popup/components/footer/Footer.vue";
import Header from "@web/components/orders/popup/components/header/Header.vue";
import Preloader from "@com/components/preloader/Preloader";

export default {
    components: {
        Preloader,
        Data,
        Composition,
        Total,
        Footer,
        Header,
    },
    props: {
        isOrderPending: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["openPopup"],
    computed: {
        ...mapGetters("activeOrders", ["cancel"]),
        ...mapState({
            order: (state) => state.orders.currentOrder,
        }),
    },
    methods: {
        ...mapMutations({
            setArchiveOrders: "archiveOrders/setOrders",
            setCancel: "activeOrders/setCancel",
        }),
        ...mapActions({
            getActiveOrders: "activeOrders/getActiveOrders",
            getArchiveOrders: "archiveOrders/getArchiveOrders",
        }),
        closePopupClickHandler(event) {
            this.setCancel(false);
        },
        cancelOrder() {
            this.$emit("closePopup");
            this.setCancel(false);
            this.$emit("cancelOrder", this.order.id);
            this.getActiveOrders();
            this.getArchiveOrders({
                offset: 0,
                step: 10,
            }).then((res) => {
                this.setArchiveOrders(res);
            });

        },
    },
};
</script>
