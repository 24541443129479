import axios from 'axios'

export default {
	namespaced: true,
	state: {
		email: '',
		isVip: false,
		emailVerified: true
	},
	mutations: {
		setEmail(state, value) {
			state.email = value
		},
		setVip(state, value) {
			state.isVip = value
		},
		setEmailVerified(state, value) {
			state.emailVerified = value
		},
	},
	actions: {
		updateEmail({commit}, {email}) {
			return new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/api/profile/requestEmailAddressUpdate`,
					data: {
						emailAddress: email
					}
				})
					.then(res => {
						resolve(res)

						commit('setEmail', email)
						commit('setEmailVerified', false)
					})
					.catch( error => {
						reject(error)
					})
			})
		},
		updateVip({commit}, {email}) {
			let regex = new RegExp('v?vip_?(.*)@fcdynamo\.ru', 'i');
			if (regex.test(email)) {
				commit('setVip', true)
			}
		},
		confirmEmailAddress({}, {token}) {
			return axios({
				method: 'post',
				url: `/api/profile/confirmEmailAddress`,
				data: {
					token: token
				}
			})
		}
	}

}
